import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { BulkitPrivate } from '../../component/layouts/bulkit/index';
import { getToken } from '../../helpers/localStorage';

export const BulkitPrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            
            render={props =>
                getToken() ? (
                    <BulkitPrivate
                        Component={Component}
                        {...props}
                    />
                ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: {
                                    from: props.location
                                }
                            }}
                        />
                    )
            }
        />
    )
}

export default BulkitPrivateRoute;