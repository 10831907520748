import React from 'react';
import axios from 'axios';
import { API_URL, MEDIA_URL, config, MEDIA_LIB_URL } from './config';
import { getToken, getEmployeeID, getTARForUser, getEmpName, removeLMSLabels, getLMSLabels, setLMSLabels, getEmployeeLanguage } from './localStorage';
import { removeGroupAttributeFromModuleAttributes } from './formHelpers';
import { SocketIO } from './socketIO';
import BounceLoader from 'react-spinners/BounceLoader';
import io from 'socket.io-client';
import iziToast from 'izitoast';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
// import { labelEditLanguageModal } from '../component/imports/Label/translation';
import { globalActions } from '../redux/actions/globalVariables';
import { labelActions } from '../component/imports/action';
import { modifySystemSettingsItem } from '../redux/mixin/SystemSettings';
const $ = window.$;
export const texts = [
    "Oops, nothing found!", // 0
    "Select an option", // 1
    "Error", // 2
    "Yes", // 3
    "No", // 4
    "Confirmation", // 5
    "Field is required", // 6
    "Are you sure you want to remove the data?", // 7
    "Success", // 8
];
const allowedModules = [
    'scheduledtraining_scheduledtraining_list',
    'news_news_list',
    'lmscalendar_generic_list',
    'news_news_details',
    'cms_labels_details',
    'generic_system-setting_details'
], pageTitles = [
    'home' // 0
];

export const genericQuery = (config) => {
    let newCancelToken = axios.CancelToken,
        newSource = newCancelToken.source(),
        token;

    if (config.CancelToken) {
        config.CancelToken.cancel("Cancel request because another request will be made.");
    }

    token = config.Token || getToken();

    if (!token) {
        let module = config.Data.requestjson.Module;

        if (allowedModules.includes(module)) {
            config.Url = '/public/';
        }
    }

    axios({
        baseURL: config.BaseURL ? config.BaseURL : API_URL,
        cancelToken: newSource.token,
        data: config.Method !== "GET" ? config.Data.requestjson : null,
        params: config.Method === "GET" ? config.Data : null,
        headers: {
            token: token
        },
        method: config.Method,
        responseType: config.ResponseType ? config.ResponseType : 'json',
        url: config.Url,
        onUploadProgress: config.OnUploadProgress ? config.OnUploadProgress : null
    })
        .then(function (response) {
            config.ResponseSuccessCallback(response, config);
        })
        .catch(function (thrown) {
            config.ResponseFailCallback(thrown, config);
        });

    if (config.CancelToken) {
        config.CancelToken = newSource;
    }

    return config;
}

export const renderLoading = (params) => {
    return (
        <div className="sweet-loading">
            <BounceLoader
                sizeUnit={"px"}
                size={60}
                color={"#7F00FF"}
                loading={true}
            />
        </div>
    );
}

export const EditModeToastFn = (params) => {
    let title = params.title, message = params.message
    iziToast.show({
        theme: 'dark',
        timeout: 0,
        displayMode: "once",
        backgroundColor: "#282828",
        progressBarColor: "#F14D5F",
        messageColor: "white",
        icon: 'fa fa-edit',
        iconColor: "red",
        title: title,
        message: message,
        position: 'topCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
        buttons: [
            ['<button>Exit Edit Mode</button>', function (instance, toast) {
                params.ExitModeButton(instance, toast)
            }]
        ],
        onOpened: function (instance, toast) {
            params.onOpened(instance, toast)
        },
        onClosing: function (instance, toast) {
            params.onClosing(instance, toast)
        },
        onClosed: function (instance, toast, closedBy) {
            params.onClosed(instance, toast, closedBy)
        }
    });
}

export const ToastDestroyFn = () => {
    iziToast.destroy();
}

export const QuestionToastFn = (params) => {

    let title = params.title, message = params.message
    iziToast.question({
        backgroundColor: '#565c70',
        close: false,
        displayMode: 'once',
        id: 'toast-question',
        message,
        overlay: true,
        overlayClose: true,
        position: 'center',
        timeout: false,
        title: title || texts[5],
        theme: 'dark',
        zindex: 999,
        buttons: [
            ['<button><b>YES</b></button>', function (instance, toast) {
                params.YesButton(instance, toast)

                // instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');

            }, true],
            ['<button>NO</button>', function (instance, toast) {
                if (params.NoButton) {
                    params.NoButton(instance, toast)
                }

                // instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');

            }],
        ],
        onClosing: function (instance, toast, closedBy) {
            console.info('Closing | closedBy: ' + closedBy);
        },
        onClosed: function (instance, toast, closedBy) {
            console.info('Closed | closedBy: ' + closedBy);
        }
    });

    return params
}

export const iziToastInit = () => {
    iziToast.settings({
        close: false,
        displayMode: 0,
        id: "toast-error",
        position: 'topRight',
        pauseOnHover: true,
        timeout: 2000,
        transitionIn: 'fadeInLeft',
        transitionOut: 'fadeOutLeft',
        transitionInMobile: 'fadeInLeft',
        transitionOutMobile: 'fadeOutLeft',
        onOpening: function () {

        },
        onClosing: function () {

        }
    });
}

export const iziToastFn = (params) => {
    const mode = params.mode,
        title = params.title,
        message = params.message,
        timeout = params.timeout,
        titles = ['Info', 'Success', 'Warning', 'Error'],
        iconList = ['fa-info-circle', 'fa-check-circle', 'fa-exclamation-circle', 'fa-times-circle'],
        classList = ['toast-info', 'toast-success', 'toast-warning', 'toast-error'];

    let settings = {
        message,
        title: title || titles[mode],
        timeout: timeout != undefined ? timeout : 3000
    };
    console.log(settings, timeout)
    settings['icon'] = `fa ${iconList[mode]}`;
    settings['class'] = classList[mode];

    iziToast.show(settings);
}
export const iziToastDestroy = () => {
    iziToast.destroy()
}

export const iziToastGenericFn = (params) => {
    if (params.show) {
        iziToast.show(params, params.toastQuestion);
    }
}

export const iziToastConfirmation = (params) => {
    const title = params.title,
        message = params.message;

    iziToast.question({
        backgroundColor: '#565c70',
        close: false,
        displayMode: 'once',
        id: 'toast-question',
        message,
        overlay: true,
        overlayClose: true,
        position: 'center',
        timeout: false,
        title: title || texts[5],
        theme: 'dark',
        zindex: 2000,
        buttons: [
            [`<button class='button'><b>${texts[3]}</b></button>`, function (instance, toast) {
                if (params.confirmPos) {
                    params.confirmPos(instance, toast);
                }

                instance.hide({ transitionOut: 'fadeOutLeft' }, toast, 'button');
            }, true],
            [`<button class='button'>${texts[4]}</button>`, function (instance, toast) {
                if (params.confirmNeg) {
                    params.confirmNeg(instance, toast);
                }

                instance.hide({ transitionOut: 'fadeOutLeft' }, toast, 'button');
            }],
        ],
        onClosing: function (instance, toast, closedBy) {

        },
        onClosed: function (instance, toast, closedBy) {

        }
    });
}

/* ==========================================================================
   Chosen helpers
========================================================================== */
export const chosenInitialize = (params) => {
    const id = params.id;

    if ($(`#${id}`).data("chosen") == undefined) {
        $(`#${id}`).chosen({
            no_results_text: texts[0],
            allow_single_deselect: params.AllowSingleDeselect ? true : false,
            width: "100%"
        });
    }

    if (params.onChangeCallback) {
        chosenOnChange(params);
    }
}

export const chosenDestroy = (params) => {
    $(`#${params.id}`).chosen('destroy');
}

export const chosenSetData = (params) => {
    const id = params.id,
        data = params.data;

    if (data) {
        data.forEach(_data => {
            $(`#${id}`).append(`<option value="${_data.ID}">${_data.Value}</option>`);
        });
        $(`#${id}`).trigger('chosen:updated');
        $(`#${id}`).trigger('liszt:updated');
    }
}

export const chosenOnChange = (params) => {
    const id = params.id,
        onChangeCallback = params.onChangeCallback;

    $(`#${id}`).chosen().change((e, _params) => {
        onChangeCallback(e, _params);
    });
}
/* ==========================================================================
   Jq-tree helpers
========================================================================== */
export const JqTreeInitialize = (params) => {
    const id = params.id, data = params.data
    if (!$(`#${id}`).hasClass("jq3-hidden")) {
        $(`#${id}`).tree({
            data: data,
            closedIcon: $('<i class="fa fa-plus-square-o"></i>'),
            openedIcon: $('<i class="fa fa-minus-square-o"></i>'),
            autoOpen: params.autoOpen,
            onLoadFailed: () => {
                alert("Tree failed loading")
            },
            onLoading: (is_loading, node, $el) => {
                console.log(is_loading, node, $el)
            },
            selectable: params.selectable || false,
            onCreateLi: params.onCreateLi || null
        }, () => {
            console.log("initialized")
        });

        if (params.onNodeClick) {
            $(`#${id}`).on(
                'tree.click',
                function (event) {
                    // The clicked node is 'event.node'
                    // var node = event.node;
                    // alert(node.name);
                    params.onNodeClick(event.node)
                }
            );
        }

        if (params.onRefresh) {
            jQTreeOnRefresh({
                id,
                callback: params.onRefresh
            });
        }
    }
}, jQTreeDestroy = (params) => {
    $(`#${params.id}`).tree('destroy');
}, jQTreeGetNodeByID = (params) => {
    const { id, nodeID } = params;
    let val = null;

    val = $(`#${id}`).tree('getNodeById', nodeID);

    return val;
}, jQTreeLoadData = (params) => {
    const { id, data, node } = params;

    $(`#${id}`).tree('loadData', data, node);
}, jQTreeGetState = (params) => {
    const { id } = params;

    return $(`#${id}`).tree('getState');
}, jQTreeGetTree = (params) => {
    const { id } = params;

    return $(`#${id}`).tree('getTree');
}, jQTreeUpdateNode = (params) => {
    const { id, node, data } = params;

    $(`#${id}`).tree('updateNode', node, data);
}, jQTreeOnRefresh = (params) => {
    const { id, callback } = params;

    if (callback) {
        $(`#${id}`).on('tree.refresh', function () {
            callback();
        });
    }
}, jQTreeOpenOrCloseNode = (params) => {
    const { id, node, isOpen } = params;

    if (id && node) {
        $(`#${id}`).tree(isOpen ? 'openNode' : 'closeNode', node);
    }
}, jQTreeOpenOrCloseTree = (params) => {
    const { id } = params;

    if (id) {
        let tree = jQTreeGetTree(params);

        if (tree) {
            tree.iterate((node, level) => {
                if (Array.isArray(node.children)) {
                    jQTreeOpenOrCloseNode({ ...params, node });
                }

                return true;
            });
        }
    }
};

/* ==========================================================================
   Select2 helpers
========================================================================== */
export const select2Initialize = (params) => {
    const { id, placeholder } = params;

    if (!$(`#${id}`).hasClass("select2-hidden-accessible")) {
        $(`#${id}`).select2({
            ajax: params.ajax,
            tags: false,
            placeholder: placeholder ? placeholder : texts[1],
            data: params.data,
            delay: params.delay,
            allowClear: params.allowClear,
            multiple: params.multiple,
            minimumInputLength: params.minimumInputLength ? params.minimumInputLength : 0,
            containerCssClass: params.containerCssClass
        });

        if (params.onSelect2Select) {
            select2OnSelect(params);
        }

        if (params.onSelect2Selecting) {
            select2OnSelecting(params);
        }

        if (params.onSelect2Unselect) {
            select2OnUnselect(params);
        }
    }
}

export const select2IsSelect2 = (params) => {
    const id = params.id;

    return $(`#${id}`).hasClass("select2-hidden-accessible")
}

export const select2Destroy = (params) => {
    const id = params.id;

    try {
        $(`#${id}`).select2('destroy');
    } catch (Exception) {
        //
    }
}

export const select2TriggerChangeOptions = (params) => {
    const id = params.id;

    $(`#${id}`).trigger('change');
}

export const select2GetData = (params) => {
    const id = params.id;

    if ($(`#${id}`).hasClass("select2-hidden-accessible")) {
        return $(`#${id}`).select2('data');
    }
}

export const select2GetPreviousData = (params) => {
    const id = params.id;

    return $(`#${id}`).val();
}

export const select2SetData = (params) => {

    const { id, data, isRefresh, defaultSelected, hasEmptyValue } = params;

    if (data) {
        if (isRefresh) {
            $(`#${id}`).empty();

            if (hasEmptyValue) {
                let newOption = new Option("", "", false, false);
                $(`#${id}`).append(newOption);
            }
        }

        data.forEach(_data => {
            let optionValue = "",
                optionID;

            if (_data.Value) {
                optionValue = _data.Value;
            } else if (_data.text) {
                optionValue = _data.text;
            }

            if (_data.ID != null) {
                optionID = _data.ID;
            } else if (_data.id != null) {
                optionID = _data.id;
            }

            let newOption = new Option(optionValue, optionID, false, false);
            newOption.setAttribute('data-val', JSON.stringify(_data));

            $(`#${id}`).append(newOption).trigger('change');
        });

        if (defaultSelected) {
            select2SetSelectedData({
                id,
                value: defaultSelected
            });
        }
    }
}

export const select2SetDataV2 = (params) => {
    const id = params.id,
        data = params.data,
        isRefresh = params.isRefresh,
        defaultSelected = params.defaultSelected;

    if (data) {
        if (isRefresh) {
            $(`#${id}`).empty();
        }

        $(`#${id}`).select2({ data: data });

        if (defaultSelected) {
            select2SetSelectedData({
                id,
                value: defaultSelected
            });
        }
    }
}

export const select2OnSelect = (params) => {
    const id = params.id;

    $(`#${id}`).off('select2:select');
    $(`#${id}`).on('select2:select', (e) => {
        params.onSelect2Select(e, params.extraData);
    });
}

export const select2OnSelecting = (params) => {
    const id = params.id;

    $(`#${id}`).off('select2:selecting');
    $(`#${id}`).on('select2:selecting', (e) => {
        params.onSelect2Selecting(e);
    });
}

export const select2OnUnselect = (params) => {
    const id = params.id;

    $(`#${id}`).off('select2:unselect');
    $(`#${id}`).on('select2:unselect', (e) => {
        params.onSelect2Unselect(e);
    });
}

export const select2SetSelectedData = (params) => {
    const id = params.id,
        value = params.value; // data type of Int


    $(`#${id}`).val(value);
    $(`#${id}`).trigger('change');
}
export const select2SetSelectedDataMultiple = (params) => {
    const { id, data } = params;
    let selectedIDs = [];

    data.forEach(value => {
        if (value.ID != null) {
            selectedIDs.push(value.ID);
        } else if (value.id != null) {
            selectedIDs.push(value.id);
        }
    });

    $(`#${id}`).val(selectedIDs);
    $(`#${id}`).trigger('change');
}

export const select2TriggerEvent = (params) => {
    const id = params.id,
        type = params.type;
    let data = params.data;

    if (!data) {
        data = select2GetData({
            id
        });

        if (data) {
            data = data[0];
        }
    }

    $(`#${id}`).trigger({
        type: type,
        params: {
            data
        }
    });
}

export const IsSelect2Initialized = params => {
    let { id } = params;

    if (!$(`#${id}`).hasClass("select2-hidden-accessible")) {
        return false;
    } else {
        return true;
    }
}

/* ==========================================================================
   Form helpers
========================================================================== */

export const formElemGetter = (params) => {
    const formElementID = params.formElementID,
        selector = params.selector,
        formElement = document.getElementById(formElementID);
    let elems = [];

    if (formElement && selector) {
        elems = formElement.querySelectorAll(selector);
    }
    return elems;
}

export const formElemValueGetter = (params) => {
    let elem = params.elem,
        returnedData = {};

    returnedData['shouldProceed'] = true;

    if (elem) {
        const id = elem.id;
        let moduleAttribute,
            moduleTemplateAttribute;

        returnedData['dataset'] = elem.dataset;

        if (returnedData['dataset']) {
            moduleAttribute = returnedData['dataset'].ref;
            moduleTemplateAttribute = returnedData['dataset'].moduletemplateattribute;

            try {
                if (!moduleAttribute) {
                    moduleAttribute = '{}';
                }
                moduleAttribute = encodeToAtob(moduleAttribute);
                moduleAttribute = JSON.parse(moduleAttribute);
            } catch (Exception) {
                //
            }

            try {
                if (!moduleTemplateAttribute) {
                    moduleTemplateAttribute = '{}';
                }
                moduleTemplateAttribute = encodeToAtob(moduleTemplateAttribute);
                moduleTemplateAttribute = JSON.parse(moduleTemplateAttribute);

            } catch (Exception) {
                //
            }
        }

        if (moduleAttribute.DBObjectAttributeID) {
            if (moduleAttribute.IsSelectList) {
                if (select2IsSelect2({ id })) {
                    let select2Data = select2GetData({ id });
                    select2Data = select2Data[0];

                    if (select2Data) {
                        returnedData['value'] = select2Data.id;
                    }
                    returnedData['select2Data'] = select2Data;
                } else {
                    returnedData['value'] = elem.value;
                }
            } else if (moduleAttribute.IsHierarchyList) {
                let hierarchyListData = returnedData['dataset'].value;

                if (hierarchyListData) {
                    returnedData['value'] = JSON.parse(encodeToAtob(hierarchyListData));
                } else {
                    returnedData['value'] = null;
                }

            } else {
                let value = "";
                switch (moduleAttribute.AttribTypeID) {
                    case 102: // String
                    case 202: // BigInt
                    case 203: // Double
                    case 201: // Int
                    case 204: // Money
                    case 205: // Real
                        if (moduleTemplateAttribute.IsWYSIWYG) {
                            value = moduleTemplateAttribute.Value;

                            if (!value) {
                                if (moduleAttribute.Value) {
                                    value = moduleAttribute.Value;
                                }
                            }

                            if (value) {
                                value = encodeURI(value);
                            }

                            returnedData['value'] = value;
                        } else {
                            returnedData['value'] = elem.value;
                        }
                        break;
                    case 200: // Bit
                        returnedData['value'] = elem.checked ? "1" : "0";
                        break;
                    case 302: // Date
                        value = moduleTemplateAttribute.Value;

                        // if (value) {
                        //     if (!(value instanceof Date)) {
                        //         value = new Date(value);
                        //         value = `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()} 00:00:00.000`;
                        //     }
                        // }
                        if (value) {
                            if (!(value instanceof Date)) {
                                value = new Date(value);
                                value = convertToYMMDDHHmmssSSS(value);
                            }
                        }

                        returnedData['value'] = value;
                        break;
                    case 303: // Time
                        value = moduleTemplateAttribute.Value;

                        if (value) {
                            if (!(value instanceof Date)) {
                                value = new Date(value);
                                value = `${(value.toTimeString()).substr(0, 8)}.0000000`;
                            }
                        }

                        returnedData['value'] = value;
                        break;
                    case 301: // DateTime
                        value = moduleTemplateAttribute.Value;

                        if (value) {
                            if (!(value instanceof Date)) {
                                value = new Date(value);
                                value = convertToYMMDDHHmmssSSS(value);
                            }
                        }

                        returnedData['value'] = value;
                        break;
                    case 402: // Email
                        break;
                    case 401: // Image
                        if (moduleTemplateAttribute.IsMediaFile) {
                            returnedData['value'] = JSON.parse(encodeToAtob(elem.value));
                        }
                        break;
                    case 403: // URL
                        break;
                    case 405:
                        if (moduleTemplateAttribute.IsMediaFile) {
                            returnedData['value'] = JSON.parse(encodeToAtob(elem.value));
                        }
                        break;
                    default:
                        break;
                }
            }
        } else {
            switch (elem.tagName) {
                case "INPUT":
                    switch (elem.type) {
                        case "checkbox":
                            returnedData['value'] = elem.checked ? "1" : "0";
                            break;
                        default:
                            returnedData['value'] = elem.value;
                            break;
                    }
                    break;
                case "SELECT":
                    if (select2IsSelect2({ id })) {
                        let select2Data = select2GetData({ id });
                        select2Data = select2Data[0];

                        if (select2Data) {
                            returnedData['value'] = select2Data.id;
                        }
                        returnedData['select2Data'] = select2Data;
                    } else {
                        returnedData['value'] = elem.value;
                    }

                    break;
                case "TEXTAREA":
                    returnedData['value'] = elem.value;
                    break;
            }
        }

        if (returnedData['value']) {
            if (typeof returnedData['value'] === "string")
                returnedData['value'] = returnedData['value'].trim();
        }
        if (returnedData['dataset']) {
            if (returnedData['dataset'].ref) {
                let ref = returnedData['dataset'].ref,
                    moduleTemplateGroup = returnedData['dataset'].moduletemplategroup,
                    otherType = returnedData['dataset'].othertype;

                try {
                    if (!ref) {
                        ref = '{}';
                    }
                    ref = encodeToAtob(ref);
                    ref = JSON.parse(ref);

                    if (ref.IsMandatory) {
                        if (returnedData['value'] == "" || returnedData['value'] == null) {
                            returnedData['shouldProceed'] = false;
                            returnedData['shouldProceedReason'] = texts[6];
                        }
                    }
                } catch (Exception) {
                    //
                }
                try {
                    if (!otherType) {
                        otherType = '{}';
                    }
                    otherType = encodeToAtob(otherType);
                    otherType = JSON.parse(otherType);
                } catch (Exception) {
                    //
                }
                try {
                    if (!moduleTemplateGroup) {
                        moduleTemplateGroup = '{}';
                    }
                    moduleTemplateGroup = encodeToAtob(moduleTemplateGroup);
                    if (moduleTemplateGroup === "undefined") {
                        moduleTemplateGroup = '{}';
                    }
                    moduleTemplateGroup = JSON.parse(moduleTemplateGroup);
                } catch (Exception) {
                    //
                }

                returnedData['ref'] = ref;
                returnedData['moduleTemplateAttribute'] = moduleTemplateAttribute;
                returnedData['moduleTemplateGroup'] = moduleTemplateGroup;
                returnedData['otherType'] = otherType;
            }
        }
    }

    return returnedData;
}

export const formElemValueSetter = (params) => {
    let elem = params.elem,
        value = params.value;

    if (elem) {
        const id = elem.id;

        switch (elem.tagName) {
            case "INPUT":
                switch (elem.type) {
                    case "checkbox":
                        elem.checked = value == "1";
                        break;
                    default:
                        elem.value = value;
                        break;
                }
                break;
            case "SELECT":
                // if (select2IsSelect2({ id })) {
                //     let select2Data = select2GetData({ id });
                //     select2Data = select2Data[0];

                //     if (select2Data) {
                //         returnedData['value'] = select2Data.id;
                //     }
                //     returnedData['select2Data'] = select2Data;
                // } else {
                //     returnedData['value'] = elem.value;
                // }

                break;
            case "TEXTAREA":
                elem.value = value;
                break;
            default:
                break;
        }
    }
}

export const onUpdateByRID = (event) => {
    try {
        let targetElement = event.target,
            targetElementJSON = formElemValueGetter({ elem: targetElement }),
            ref,
            targetValue,
            refValue,
            shouldProceed = true;

        if (targetElementJSON) {
            ref = targetElementJSON.ref;
            targetValue = targetElementJSON.value;
            refValue = ref.Value;

            if (refValue != targetValue) {
                if (ref.IsMandatory && (targetValue == null || targetValue.length == 0)) {
                    shouldProceed = false;
                }
            } else {
                shouldProceed = false;
            }
        } else {
            shouldProceed = false;
        }

        let parentControlDiv = getParentControlDiv({
            targetElement
        });

        if (shouldProceed) {
            updateByRID({
                OldValue: refValue,
                NewValue: targetValue,
                RID: `${ref.RID}.${ref.AttributeName}`,
                parentControlDiv,
                ref,
                targetElement
            });
        }
    } catch (Exception) {
        console.log(Exception);
    }
}

const updateByRID = (params) => {
    let ref = params.ref,
        targetElement = params.targetElement,
        parentControlDiv = params.parentControlDiv,
        formData = new FormData(),
        config = {
            ExtraData: params,
            Data: {},
            Method: "PUT",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/update_by_rid/"
        };

    formData.append('NewValue', params.NewValue);
    formData.append('OldValue', params.OldValue);
    formData.append('RID', params.RID);
    formData.append('userID', getEmployeeID());

    config.Data.requestjson = formData;

    isUpdateByRIDProcessing({
        isProcessing: true,
        targetElement: parentControlDiv
    });
    genericQuery(config);

    function responseSuccessCallback(responseJson) {
        isUpdateByRIDProcessing({
            isProcessing: false,
            targetElement: parentControlDiv
        });

        try {
            let data = responseJson.data,
                status = data[0][0];


            if (status == "ok") {
                ref.Value = params.NewValue;

                targetElement.dataset.ref = JSON.stringify(ref);
            } else {
                formElemValueSetter({
                    elem: targetElement,
                    value: ref.Value
                });
            }
            console.log("responseSuccessCallback", responseJson);
        } catch (Exception) {
            console.log(Exception);
        }

    }

    function responseFailCallback(responseJson) {
        iziToastFn({
            mode: 3,
            title: texts[2],
            message: responseJson.message
        });

        isUpdateByRIDProcessing({
            isProcessing: false,
            targetElement: parentControlDiv
        });

        try {
            formElemValueSetter({
                elem: targetElement,
                value: ref.Value
            });
        } catch (Exception) {
            console.log(Exception);
        }
    }
}

export const onDeleteByRID = (params) => {
    iziToastConfirmation({
        message: texts[7],
        confirmPos: () => {
            deleteByRID(params);
        }
    });
}

export const deleteByRID = (params) => {
    try {
        const RID = params.RID,
            attributes = params.attributes;
        let config = {
            ExtraData: params,
            Data: {},
            Method: "DELETE",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/delete_by_rid/"
        },
            formData = new FormData();

        formData.append('RID', RID);
        config.Data.requestjson = formData;

        genericQuery(config);

        function responseSuccessCallback(responseJson) {
            try {
                let data = responseJson.data,
                    status = data.Status;

                if (status) {
                    let izitoastMode = 0,
                        izitoastTitle,
                        izitoastMessage = data.Message;

                    if (status.IsSuccess) {
                        izitoastMode = 1;
                        izitoastTitle = texts[8];

                        if (params.moduleTemplateGroup) {
                            removeGroupAttributeFromModuleAttributes(params);
                        }
                    } else {
                        izitoastMode = 3;
                        izitoastTitle = texts[2];
                    }

                    iziToastFn({
                        mode: izitoastMode,
                        title: izitoastTitle,
                        message: izitoastMessage
                    });
                }
            } catch (Exception) {
                console.log(Exception);
            }
        }
        function responseFailCallback(responseJson) {
            iziToastFn({
                mode: 3,
                title: texts[2],
                message: responseJson.message
            });

        }
    } catch (Exception) {
        console.log(Exception);
    }
}

export const getParentControlDiv = (params) => {
    let targetElement = params.targetElement,
        returnedElement;

    if (targetElement) {
        returnedElement = targetElement.closest('.control');
    }

    return returnedElement;
}

export const isUpdateByRIDProcessing = (params) => {
    elementLoadingState({
        isLoading: params.isProcessing,
        targetElement: params.targetElement
    });
    elementDisabledState({
        isDisabled: params.isProcessing,
        targetElement: params.targetElement
    });
}

export const elementLoadingState = (params) => {
    let targetElement = params.targetElement;

    if (params.isLoading) {
        targetElement.classList.add('is-loading');
    } else {
        targetElement.classList.remove('is-loading');
    }
}

export const elementDisabledState = (params) => {
    let targetElement = params.targetElement;

    if (params.isDisabled) {
        targetElement.classList.add('is-disabled');
    } else {
        targetElement.classList.remove('is-disabled');
    }
}

/* ==========================================================================
   Modal helpers
========================================================================== */
export const openModal = (id) => {
    var modalID = id;
    $(`#${id}`).toggleClass('is-active');
    $(`#${id} .modal-background`).toggleClass('scaleInCircle');
    $(`#${id} .modal-content`).toggleClass('scaleIn');
    $(`#${id} .modal-close`).toggleClass('is-hidden');
    //Prevent sticky fixed nav and backtotop from overlapping modal
    $('#scrollnav, #backtotop').toggleClass('is-hidden');
    //Prevent body from scrolling when scrolling inside modal
    setTimeout(function () {
        if ($('.dashboard-wrapper').length) {
            $('body').addClass('is-fixed');
        }
    }, 700);
}
export const closeModal = (id) => {
    $(`#${id} .modal-background`).toggleClass('scaleInCircle');
    $(`#${id} .modal-content`).toggleClass('scaleIn');
    $(`#${id} .modal-close`).toggleClass('is-hidden');
    //Restore native body scroll
    if ($('.dashboard-wrapper').length) {
        $('body').removeClass('is-fixed');
    }
    setTimeout(function () {
        $(`#${id}`).removeClass('is-active');
        //Restore sticky nav and backktotop
        $('#scrollnav, #backtotop').toggleClass('is-hidden');
    }, 500);
}

/* ==========================================================================
   Calculation helpers
========================================================================== */
export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/* ==========================================================================
   Object helpers
========================================================================== */
export const findObject = (obj, targetProp, finalResults) => {
    function getObject(theObject) {
        let result = null;
        if (theObject instanceof Array) {
            for (let i = 0; i < theObject.length; i++) {
                getObject(theObject[i]);
            }
        }
        else {
            for (let prop in theObject) {
                if (theObject.hasOwnProperty(prop)) {
                    if (prop === targetProp) {
                        finalResults.push(theObject);
                    }
                    if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
                        getObject(theObject[prop]);
                    }
                }
            }
        }
    }
    getObject(obj);
}

export const findObjectFromFoundObject = (params) => {
    let DBObjectAttributeID = params.DBObjectAttributeID,
        objectArray = params.objectArray,
        returnedData = null;

    for (let x = 0; x < objectArray.length; ++x) {
        const attributes = objectArray[x].Attributes;

        if (returnedData) {
            break;
        }
        if (attributes) {
            for (let x2 = 0; x2 < attributes.length; ++x2) {
                const _attributes = attributes[x2];
                if (returnedData) {
                    break;
                }
                if (_attributes.DBObjectAttributeID == DBObjectAttributeID) {
                    returnedData = _attributes;
                }
            }
        }
    }

    return returnedData;
}

export const cloneObject = (object) => {
    return JSON.parse(JSON.stringify(object));
}

/* ==========================================================================
   Attribute helpers
========================================================================== */

export const searchAttributeByDBObjectAttributeID = (params) => {
    let attributes = params.attributes,
        id = params.id;

    return attributes.find(attribute => {
        return attribute.DBObjectAttributeID == id
    });
}

export const searchAttributeByDBObjectAttributeIDAndRID = (params) => {
    let attributes = params.attributes,
        id = params.id,
        RID = params.RID;

    return attributes.find(attribute => {
        return attribute.DBObjectAttributeID == id && attribute.RID == RID;
    });
}

/* ==========================================================================
   atob / btoa helpers
========================================================================== */
export const encodeToBtoa = (str) => {
    return window.btoa(unescape(encodeURIComponent(str)));
    // return window.btoa(str);
}

export const encodeToAtob = (str) => {
    return decodeURIComponent(escape(window.atob(str)));
    // return window.atob(str);
}

/* ==========================================================================
 language helpers
========================================================================== */

export const getFlag = (lang) => {
    switch (lang) {
        case "gb":
            return 'gb.svg'
        case "af":
            return 'af.svg'
        case "ja":
            return 'jp.svg'

        default:
            return 'united-states.svg'
    }
}


/* ==========================================================================
Fake Data Generator
========================================================================== */

// export const getItems = (count, offset = 0) =>
//     Array.from({ length: count }, (v, k) => k).map(k => ({
//         id: `item-${k + offset}`,
//         content: `item89345y7u893457893475893457893475893475893453uiehgjdfnbgkjcdnkjbndu ${k +
//             offset}`
//     }));

export const getItems = (count, offset = 0) =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k + offset}`,
        content: `item ${k + offset}`
    }));
/* ==========================================================================
a little function to help us with reordering the result
========================================================================== */

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/* ==========================================================================
Moves an item from one list to another list.
========================================================================== */
export const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

/* ==========================================================================
 Array helpers
========================================================================== */

export const search = (nameKey, myArray) => {
    for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].id === nameKey) {
            return { dataFound: myArray[i], indexLocated: i };
        }
    }
}

export const getArrayValueByKey = (params) => {
    const { Key, Value, List } = params;

    return List?.find(item => {
        return item[Key] == Value;
    });
};

/* ==========================================================================
 WOWJS helpers
========================================================================== */
const wowDefaultIterator = .2;
/* ==========================================================================
 MomentJS helpers
========================================================================== */
export const isWeekday = (date) => {
    return ![0, 6].includes(date.weekday());
}

export const isWeekend = (date) => {
    return [0, 6].includes(date.weekday());
}

export const convertToDDMMY = (date, delimiter = '-') => {
    return moment(date).format(`DD${delimiter}MM${delimiter}Y`);
}

export const convertToDDMMYHHmm = (date, delimiter = '-') => {
    return moment(date).format(`DD${delimiter}MM${delimiter}Y HH:mm`);
}

export const convertToDDMMYHHmmssSSS = (date, delimiter = '-') => {
    return moment(date).format(`DD${delimiter}MM${delimiter}Y HH:mm:ss.SSS`);
}

export const convertToYMMDDHHmmssSSS = (date, delimiter = '-') => {
    return moment(date).format(`Y${delimiter}MM${delimiter}DD HH:mm:ss.SSS`);
}

export const convertToYDDMM = (date, delimiter = '-') => {
    return moment(date).format(`YYYY${delimiter}DD${delimiter}MM`);
}

export const convertToYMMDD = (date, delimiter = '-') => {
    return moment(date).format(`YYYY${delimiter}MM${delimiter}DD`);
}

export const defaultSCORMJSON = {
    cmi: {
        core: {
            _children: {},
            student_id: 'null',
            student_name: '',
            lesson_location: '',
            credit: '',
            lesson_status: 'not attempted',
            entry: '',
            score_children: '',
            score: {
                raw: 0,
                max: 0,
                min: 0
            },
            total_time: 0,
            lesson_mode: '',
            exit: '',
            session_time: 0
        },
        suspend_data: '',
        launch_data: '',
        comments: '',
        comments_from_lms: '',
        objectives: {
            _children: '',
            _count: '',
            n: {
                id: 0,
                score: {
                    _children: 0,
                    raw: 0,
                    max: 0,
                    min: 0
                },
                status: ''
            }
        },
        student_data: {
            _children: {},
            mastery_score: 0,
            max_time_allowed: 0,
            time_limit_action: ''
        },
        student_preference: {
            _children: {},
            audio: 0,
            language: '',
            speed: '',
            text: 0
        },
        interactions: {
            _children: {},
            _count: 0,
            n: {
                id: 0,
                objectives: {
                    _count: 0,
                    id: 0,

                },
                time: 0,
                type: 0,
                correct_responses: {
                    _count: 0,
                    n: {
                        pattern: '',

                    }
                },
                weighting: 0,
                student_response: '',
                result: '',
                latency: 0
            }
        }
    },
    error_code: 0,
    uni: {
        student: {
            language: ''
        }
    },
    errors: {
        '0': 'No error',
        '101': 'General Exception',
        '201': 'Invalid argument error',
        '202': 'Element cannot have children',
        '203': 'Element not an array. Cannot have count.',
        '301': 'Not initialized',
        '401': 'Not implemented error',
        '402': 'Invalid set value, element is a keyword',
        '403': 'Element is read only.',
        '404': 'Element is write only',
        '405': 'Incorrect Data Type'
    },
    LMSInitialize: function (params) {
        console.log("LMSInitialize", params);
        console.log("LMSInitialize", this);

        return "true";
    },
    LMSFinish: function (params) {
        console.log("LMSFinish", params);
        console.log("LMSFinish", JSON.stringify(this));

    },
    LMSGetValue: function (params) {
        console.log("LMSGetValue", params);
        console.log("LMSGetValue", this);

        if (params === null) return "";

        let keys = params.split("."),
            val = "";

        keys.forEach((_key, index) => {
            if (index === 0) {
                val = this[_key];
            } else {
                val = val[_key];
            }
        });

        return val;
    },
    LMSSetValue: function (element, value) {
        console.log("LMSSetValue", element, value);
        console.log("LMSSetValue", this);

        if (element === null) return false;

        let keys = element.split("."),
            objHolder = this;

        keys.forEach((_key, index) => {
            if (index + 1 === keys.length) {
                objHolder[_key] = value;
            } else {
                objHolder = objHolder[_key];
            }
        });

        return "true";
    },
    LMSCommit: function (params) {
        console.log("LMSCommit", params);
        console.log("LMSCommit", JSON.stringify(this));

        return "true";
    },
    LMSGetLastError: function () {
        return this.error_code;
    },
    LMSGetErrorString: function (errorCode) {
        console.log("LMSGetErrorString", errorCode);
        return this.errors[errorCode];
    },
    LMSGetDiagnostic: function (errorCode) {
        console.log("LMSGetDiagnostic", errorCode);
        return this.errors[errorCode];
    }
}

/* ==========================================================================
 Sys_AccessLevel helpers
========================================================================== */
export const socket = io.connect(config.SOCKET_URL, { transports: ['websocket'], secure: true });

/* ==========================================================================
 Sys_AccessLevel helpers
========================================================================== */
export const isLMSManager = () => {
    let TAR = getTARForUser();

    return (parseInt(TAR) & 2048) == 2048;
}
export const isLMSRegionalManager = () => {
    let TAR = getTARForUser();

    return (parseInt(TAR) & 4096) == 4096;
}
/* ==========================================================================
 DImages
========================================================================== */
export const defaultImageLocations = {
    training: `${process.env.PUBLIC_URL}/images/placeholders/training.svg`
};

/* ==========================================================================
 Route helpers
========================================================================== */
export const routePaths = [
    '/login', // 0
    '/logout', // 1
    '/home', // 2
    '/home1', // 3
    '/course-take', // 4
    '/profile', // 5
    '/catalog', // 6
    '/admin/news/create', // 7
    '/admin/news', // 8
    '/news', // 9
    '/course-take' // 10
]
export {
    getEmployeeID,
    getEmpName,
    SocketIO,
    wowDefaultIterator
};



/* ==========================================================================
 Update By RID
========================================================================== */

export const UpdateByRIDv2 = (params) => {
    let formData = new FormData(),
        { NewValue, OldValue, RID, Success, Failed, Error } = params

    formData.append("RID", RID);
    formData.append("NewValue", NewValue);
    formData.append("OldValue", OldValue);

    let config = {
        Data: { requestjson: formData },
        Method: "PUT",
        ResponseSuccessCallback: responseSuccessCallback,
        ResponseFailCallback: responseFailCallback,
        Url: "/update_by_rid/"
    };
    if (OldValue === NewValue) {
        console.log("Same Value")
        Error()
    } else {
        genericQuery(config);
    }

    function responseSuccessCallback(responseJson) {
        console.log("responseSuccessCallback", responseJson);
        let data = responseJson.data,
            status = data.Status;

        if (status.IsSuccess) {
            Success(responseJson)
        }
        else {
            Failed(responseJson)
        }
    }

    function responseFailCallback(responseJson) {
        console.log("responseFailCallback", responseJson);
        Error(responseJson)
    }
}

/* ==========================================================================
 Delete By RID
========================================================================== */

export const DeleteByRIDv2 = (params) => {

    let formData = new FormData(),
        { RID, Success, Failed, Error } = params
    formData.append("RID", RID)
    let config = {
        Data: { requestjson: formData },
        Method: "DELETE",
        ResponseSuccessCallback: responseSuccessCallback,
        ResponseFailCallback: responseFailCallback,
        Url: "/delete_by_rid/"
    };

    genericQuery(config)
    function responseSuccessCallback(responseJson) {
        console.log("responseSuccessCallback", responseJson);
        let data = responseJson.data,
            status = data.Status;

        if (status.IsSuccess) {
            Success(responseJson)
        }
        else {
            Failed(responseJson)
        }
    }

    function responseFailCallback(responseJson) {
        console.log("responseFailCallback", responseJson);
        Error(responseJson)
    }
}


/* ==========================================================================
 H5P helpers
========================================================================== */

export const H5PInitialize = params => {
    let { id, ModuleID, LocationReference } = params
    window.H5P.jQuery(function ($) {
        $(`#${id}`).h5p({
            contentID: ModuleID,
            frameJs: `${MEDIA_URL}/libraries/dist/js/h5p-standalone-frame.min.js`,
            frameCss: `${MEDIA_URL}/libraries/dist/styles/h5p.css`,
            h5pContent: `${MEDIA_URL}${LocationReference}`,
            displayOptions: {
                frame: false,
                copyright: false,
                embed: false,
                download: false,
                icon: false,
                export: false
            }
        });
    })

}

export const DEFAULT_RESPONSE = { 'Status': { 'IsSuccess': false, 'Message': "DEFAULT RESPONSE" } }
/* ==========================================================================
 Generate Excel Report
========================================================================== */
export const GenerateExcelExport = params => {
    let { requestjson, column_headers, Success, Failed, CatalogName, dbKeys } = params
    // column_headers = JSON.stringify(column_headers)
    let config = {
        BaseURL: MEDIA_LIB_URL,
        Data: {
            requestjson,
            column_headers: column_headers,
            dbKeys: dbKeys
        },
        ResponseType: 'blob',
        Method: "GET",
        ResponseSuccessCallback: responseSuccessCallback,
        ResponseFailCallback: responseFailCallback,
        Url: "/exports"
    };

    let response = DEFAULT_RESPONSE
    genericQuery(config);
    function responseSuccessCallback(responseJson) {
        console.log("responseSuccessCallback", responseJson);
        let data = responseJson.data

        let today = new Date();
        let month = today.getMonth();
        let day = today.getDate();
        let year = today.getFullYear();
        let exportDate = month + "-" + day + "-" + year
        saveAs(data, `${CatalogName}-export-${exportDate}.xlsx`)
        response['Status']['IsSuccess'] = true
        response['Status']['Message'] = "Export Successfully Created"

        if (Success) {
            Success()
        }
    }
    function responseFailCallback(responseJson) {
        console.log("responseFailCallback", responseJson);
        response['Status']['IsSuccess'] = false
        response['Status']['Message'] = responseJson.data
        if (Failed) {
            Failed()
        }
    }
}

/* ==========================================================================
 LABEL 
========================================================================== */

const searchLabel = (nameKey, myArray) => {
    // console.log(nameKey)
    for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].LabelID === nameKey) {
            return { dataFound: myArray[i], indexLocated: i };
        }
    }
}
const searchLanguage = (nameKey, myArray) => {
    // console.log(nameKey)
    for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].LanguageID === nameKey) {
            return { dataFound: myArray[i], indexLocated: i };
        }
    }
}
export async function GetLabels(params = { LabelIDs: [], LanguageID: null }) {
    let { LabelIDs, LanguageID, Finished } = params
    // iziToastFn({ mode: 2, message: "Site is being translated" })
    let promise = new Promise((resolve, reject) => {
        let requestjson = {
            Module: "cms_labels_details",
            Parameters: {
                LabelIDs,
                LanguageID
            }
        }, config = {
            Data: { requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };

        genericQuery(config);

        function responseSuccessCallback(responseJson) {
            console.log("responseSuccessCallback", responseJson);
            resolve(responseJson.data.Data.Labels)

        }
        function responseFailCallback(responseJson) {
            console.log("responseFailCallback", responseJson);
            resolve({})
        }
    });
    let Result = await promise
    if (!getLMSLabels()) {
        setLMSLabels({})
    }

    let lmsLabels = JSON.parse(getLMSLabels())
    Result.forEach(label => {
        lmsLabels[label.LabelID] = {
            ...label,
            Languages: label.Languages.map(language => {
                return {
                    ...language,
                    LabelID: label.LabelID,
                    LabelTypeID: label.LabelTypeID
                };
            })
        };
    });
    localStorage.setItem("labels-from-db", JSON.stringify(Result))
    setLMSLabels(lmsLabels)

    if (Finished) {
        Finished()
    }

}
let LABELS = [
    "Close", //0
]


export const GetLabel = (params = { LabelID, Format: 0, DefaultLabel: "" }, dataset) => {
    //GET THE LMS LABELS FROM LOCAL STORAGE

    let { LabelID, Format, DefaultLabel } = params
    let lmsLables = getLMSLabels(),
        lmsUserLang = getEmployeeLanguage(),
        Labels, ItemsOk = 0, UserLanguage

    if (lmsLables && lmsLables !== "null") {
        Labels = dataset && Object.keys(dataset).length > 0 ? dataset : JSON.parse(lmsLables)
    }

    if (lmsUserLang && lmsUserLang !== "null") {
        UserLanguage = JSON.parse(lmsUserLang)
    }
    if (lmsLables && lmsUserLang) {
        if (LabelID && Labels[LabelID]) {
            let Label = "", LabelRID = 0

            let TargetLanguage = searchLanguage(UserLanguage.LanguageID, Labels[LabelID].Languages),
                DefaultLanguage = searchLanguage(1, Labels[LabelID].Languages)
            if (TargetLanguage) {
                Label = TargetLanguage.dataFound.Label
                LabelRID = DefaultLanguage.dataFound.LabelRID
            } else {
                if (DefaultLanguage) {
                    Label = DefaultLanguage.dataFound.Label
                    LabelRID = DefaultLanguage.dataFound.LabelRID
                } else {
                    console.log("No Default Language", LabelID)
                    return DefaultLabel
                }
            }
            //FORMAT LABEL
            switch (Format) {
                case 0:
                    break;
                case 1:
                    break;
                case 2:
                    Label = Label.toUpperCase()
                    break;
                case 3:
                    Label = Label.toLowerCase()
                    break;
                case 4:
                    break;
                case 5:
                    break;
            }

            return Label
        } else {
            console.log("LabelID not found", LabelID, DefaultLabel)
        }
    } else {
        return DefaultLabel
    }
}, GetLabelv2 = (props = { LabelID, Format: 0, DefaultLabel: "" }) => {
    //GET THE LMS LABELS FROM LOCAL STORAGE
    let { LabelID, Format, DefaultLabel, style } = props.Label
    // console.log("LabelID", LabelID === -707 ? LabelID : "")


    let lmsLables = getLMSLabels(),
        lmsUserLang = getEmployeeLanguage(),
        Labels, returnData = <>{DefaultLabel}</>, UserLanguage


    const reduxStore = useSelector(state => ({
        globalVariables: state.globalVariables
    }))


    let dispatch = useDispatch(),
        reduxActions = {
            setSelectedLabel: (data) => dispatch(globalActions.setSelectedLabel(data)),
            modifyLabel: (data) => dispatch(labelActions.modifyLabel(data))
        }


    if (lmsLables && lmsLables !== "null") {
        Labels = JSON.parse(lmsLables)
    }


    if (lmsUserLang && lmsUserLang !== "null") {
        UserLanguage = JSON.parse(lmsUserLang)
    }

    if (lmsLables && lmsUserLang) {
        if (LabelID && Labels[LabelID]) {
            // reduxActions.modifyLabel({ LabelID, Data: Labels[LabelID] })
            let Label = "", LabelRID = 0, LabelLanguageID, LanguageName


            let TargetLanguage = searchLanguage(UserLanguage.LanguageID, Labels[LabelID].Languages),
                DefaultLanguage = searchLanguage(1, Labels[LabelID].Languages)
            if (TargetLanguage) {
                Label = TargetLanguage.dataFound.Label
                LabelRID = TargetLanguage.dataFound.LabelRID
                LabelLanguageID = TargetLanguage.dataFound.LanguageID
                LanguageName = TargetLanguage.dataFound.LanguageName
            } else {
                if (DefaultLanguage) {
                    Label = DefaultLanguage.dataFound.Label
                    LabelRID = DefaultLanguage.dataFound.LabelRID
                    LabelLanguageID = DefaultLanguage.dataFound.LanguageID
                    LanguageName = DefaultLanguage.dataFound.LanguageName
                } else {
                    console.log("No Default Language", LabelID)
                    returnData = <>{DefaultLabel}</>
                }
            }
            //FORMAT LABEL
            let formattedLabel = Label
            switch (Format) {
                case 0:
                    break;
                case 1:
                    break;
                case 2:
                    formattedLabel = Label.toUpperCase()
                    break;
                case 3:
                    formattedLabel = Label.toLowerCase()
                    break;
                case 4:
                    break;
                case 5:
                    break;
            }

            //RETURN THE STRING OR A HTML ELEMENT
            let isEditMode = reduxStore.globalVariables.isLabelEditMode

            if (isEditMode) {
                returnData = <>
                    <input className={`input is-small label-isEditing`} defaultValue={Label} onClick={(e) => {
                        e.stopPropagation()
                    }}
                        onBlur={(e) => {
                            let value = e.target.value
                            if (value != Label) {
                                onBlurTranslationEditAndAdd(e, { LabelID: LabelID, LanguageID: UserLanguage.LanguageID })
                            }
                        }}
                        style={style} />
                    <i className="fa fa-pencil label-edit color-red" aria-hidden="true"></i>
                </>
            } else {
                returnData = <span id={LabelID} className={`label-instance`} >
                    {formattedLabel}
                </span>
            }
        } else {
            console.log("LabelID not found", LabelID, DefaultLabel)
        }
    } else {
        // console.log(LabelID)
        returnData = <span id={LabelID}>{DefaultLabel}</span>
    }

    // if (LabelID == 54132)
    //     console.log(returnData)
    return returnData
}

const onBlurTranslationEditAndAdd = (e, params) => {
    e.preventDefault()
    let value = e.target.value,
        { LabelID, LanguageID } = params

    let requestjson = {
        Module: "cms_labels_add-language",
        Parameters: {
            LabelID,
            LanguageID,
            Label: value
        }
    },
        config = {
            Data: { requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };

    genericQuery(config);
    function responseSuccessCallback(responseJson) {
        console.log("responseSuccessCallback", responseJson);
        let data = responseJson.data, status = data.Status

        if (status.IsSuccess) {
            $(`#${LabelID}`).text(value)
            GetLabels({ LabelIDs: [LabelID] })
            iziToastFn({
                mode: 1,
                message: "Label Update Successful"
            })
        }


    }
    function responseFailCallback(responseJson) {
        console.log("responseFailCallback", responseJson);
    }

}

export const processLabel = (params = { LabelID, Format: 0, DefaultLabel: "", returnAsString: false }) => {
    let lmsLabels = getLMSLabels(),
        lmsUserLang = getEmployeeLanguage(),
        { LabelID, DefaultLabel, Format, returnAsString } = params

    // if (returnAsString){

    // } else {

    // }
    // console.log("shit")
    if (lmsLabels && lmsUserLang) {
        let Labels = JSON.parse(lmsLabels)

        if (LabelID && Labels[LabelID]) {
            let LabelTypeID = Labels[LabelID].LabelTypeID
            if (LabelID == 54132)
                console.log(Labels[LabelID].Languages[0].Label, LabelTypeID)


            switch (LabelTypeID) {
                case 10://Alerts
                case 19://Static Hover Text
                case 5:///Placeholder
                case 22://Normal text
                    return GetLabel(params)
                default:
                    return <GetLabelv2 Label={params} />


            }
        }
        else {
            return DefaultLabel
        }
    }
    else {
        return DefaultLabel
    }


}

/* ==========================================================================
SYSTEM SETTINGS
========================================================================== */

export const GetSystemSettings = () => {
    const dispatch = useDispatch();

    const callBack = (responseJSON) => {
        if (responseJSON?.status === 200) {
            const { data } = responseJSON;


            if (data.Status.IsSuccess) {
                const SystemSettings = data?.Data.SystemSetting;

                const id = SystemSettings.SysSettingID;
                const NewProps = {
                    [id]: {
                        ...SystemSettings
                    }
                }

                dispatch(modifySystemSettingsItem({ NewProps }));

            } else {
                iziToastFn({
                    mode: 3,
                    message: data.Status.Message
                });
            }
        }

    },
        config = {
            Data: { requestjson: { "Module": "generic_system-setting_details", "Parameters": { "SysSettingIDs": [43] } } },
            Method: "GET",
            ResponseSuccessCallback: callBack,
            ResponseFailCallback: callBack,
            Url: "/single_api/"
        }
    genericQuery(config);
    return (
        <>

        </>
    );



}



