/* eslint-disable default-case */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import parse from 'html-react-parser';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getEmployeeID, getToken } from '../../../helpers/localStorage';
import { iziToastFn, iziToastConfirmation, genericQuery, closeModal, openModal, routePaths, convertToYMMDD, defaultImageLocations, select2Initialize, select2SetData, select2Destroy, processLabel } from '../../../helpers/helper';
import { MediaLibraryImg, LabelGenerator } from '../../imports';

require('./index.scss');

const texts = [
    'Add new training on this date', // 0
    'Are you sure you want to unsubscribe from', // 1
    'You are now subscribed to the training', // 2
    'You are now unsubscribed from the training', // 3
    'Subscribe', // 4
    'Unsubscribe', // 5
    'Description', // 6
    'Category', // 7
    'Trainer', // 8
    'Location', // 9
    'to', // 10
    'Schedule', // 11
    'View', // 12
    'Select a location' // 13
],
    elementIDs = [
        'select-locationList' // 0
    ],
    labelIDs = [
        -661
    ];
class Calendar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date: new Date(),
            currentMonth: null,
            currentYear: null,
            loadingCourseCatalogID: [],
            selectedTrainingID: null,
            selectedTrainingPerDay: {
                date: '',
                list: []
            },
            showRenderCenterAnnouncement: true
        };
        this.daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        this.months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        this.dateFormat = 'dd/mm/yyyy';
        this.onClickNextMonth = this.onClickNextMonth.bind(this);
        this.onClickPreviousMonth = this.onClickPreviousMonth.bind(this);
        this.onClickNextYear = this.onClickNextYear.bind(this);
        this.onClickPreviousYear = this.onClickPreviousYear.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.LabelGenerator = new LabelGenerator(this);
        this.token = getToken();

        let LabelIds = [
            -1246,
            -1247,
            -1248,
            -1249,
            -1250,
            -1251,
            -1252,
            -1253,
            -1254,
            -1255,
            -1256,
            -1257,
            -1258,
            -1259,
        ]
        texts.map((text, index) => {
            text = processLabel({ LabelID: LabelIds[index], DefaultLabel: text })
            return text
        })
    }

    render() {
        const _this = this,
            state = _this.state,
            props = _this.props,
            loadingCourseCatalogID = state.loadingCourseCatalogID,
            { isHighlightSubscribedTraining, showSubscribeBtn } = props,
            currentMonth = props.currentMonth,
            currentYear = props.currentYear,
            currentDate = {
                day: new Date().getDate(),
                month: new Date().getMonth(),
                year: new Date().getFullYear()
            },
            showCountsPerDay = props.showCountsPerDay,
            token = getToken();
        let firstDayOfCurrentMonth = new Date(currentYear, currentMonth, 1).getDay(),
            lastDateOfCurrentMonth = new Date(currentYear, currentMonth + 1, 0).getDate(),
            lastDateOfLastMonth = currentMonth === 0 ? new Date(currentYear - 1, 12, 0).getDate() : new Date(currentYear, currentMonth, 0).getDate(),
            renderCalendar,
            renderCalendarDateRow = [],
            renderMonthYear,
            renderDaysOfWeek,
            previousMonthDates,
            dm;

        // Start month and year display
        renderMonthYear = (
            <>
                <span>{`${_this.months[currentMonth]} ${currentYear}`}</span>
            </>
        );
        // End month and year display

        // Start days display
        renderDaysOfWeek = (
            <thead>
                <tr>
                    {
                        _this.daysOfWeek.map((_daysOfWeek, index) => {
                            return (
                                <th className="calendar-header__aSwEkQ" key={`daysofweek-${index}`}>{_daysOfWeek}</th>
                            )
                        })
                    }
                </tr>
            </thead>
        );
        // End days display

        previousMonthDates = dm = 1;

        // Start dates display
        for (let lastDayOfTheWeek, week = 0, row = 0; row < 6; row++) {
            let _renderCalendarRow = [],
                key;

            for (let dayOfTheWeekCounter = 0; dayOfTheWeekCounter < 7; dayOfTheWeekCounter++) {
                let day,
                    cellValueClassName = '',
                    dateClassName,
                    renderCellValue,
                    renderTrainingContainer,
                    renderTraining = [],
                    getScheduleTrainingsParams = {},
                    scheduledTrainings,
                    renderCountsPerDay,
                    renderCountsPerDayClassName;

                lastDayOfTheWeek = week + dm - firstDayOfCurrentMonth;

                if (lastDayOfTheWeek < 1) {
                    cellValueClassName = 'cell-previousmonth__MWQxsq';
                    day = lastDateOfLastMonth - firstDayOfCurrentMonth + previousMonthDates++;

                    getScheduleTrainingsParams['month'] = 999;
                    getScheduleTrainingsParams['year'] = 999;
                } else if (lastDayOfTheWeek > lastDateOfCurrentMonth) {
                    cellValueClassName = 'cell-previousmonth__MWQxsq';
                    day = previousMonthDates++;

                    getScheduleTrainingsParams['month'] = 999;
                    getScheduleTrainingsParams['year'] = 999;
                } else {
                    day = lastDayOfTheWeek;
                    previousMonthDates = 1;

                    if (currentDate.day === day && currentDate.month === currentMonth && currentDate.year === currentYear) {
                        // dateClassName = 'b-badge rounded is-warning';
                        dateClassName = 'tag -is-primary';
                    }

                    getScheduleTrainingsParams['month'] = currentMonth;
                    getScheduleTrainingsParams['year'] = currentYear;
                }
                getScheduleTrainingsParams['date'] = day;
                scheduledTrainings = _this.getScheduleTrainings(getScheduleTrainingsParams);

                if (scheduledTrainings) {
                    scheduledTrainings.forEach((_scheduledTraining, index) => {
                        let renderSubscribeBtn,
                            dayItem;

                        if (showSubscribeBtn && token) {
                            let text = "",
                                className = "",
                                icon = "";

                            if (_scheduledTraining.EmployeeSubscribedLearningRID) {
                                className = 'btn-danger';
                                text = texts[5];
                                icon = 'fa-times';
                            } else {
                                className = 'btn-success';
                                text = texts[4];
                                icon = 'fa-check';
                            }

                            className = `button btn-lms-nolayout ${className}`;
                            icon = `fa ${icon}`;

                            renderSubscribeBtn = (
                                <div className="div__zaDtaK">
                                    <button className={className} onClick={_this.onClickSubscribeUnsubscribeBtn.bind(_this, _scheduledTraining)}><i className={icon}></i>{text}</button>
                                </div>
                            );
                        }

                        if (scheduledTrainings.length > 1) {
                            dayItem = (provided, snapshot) => (
                                <div className={`training__ueLUDK multiple__ezByAF unselectable${loadingCourseCatalogID.includes(_scheduledTraining.CourseCatalogID) ? ' is-disabled' : ''}`}
                                    ref={provided.innerRef}
                                    {...provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}>
                                    <p className="title__uVegWE" onClick={() => {
                                        _this.setState({ selectedTrainingID: _scheduledTraining.TrainingID }, () => {
                                            openModal('modal-trainingDetails');
                                        });
                                    }}>{_scheduledTraining.Title}</p>
                                    <div className="time__UdiDlc">
                                        <p>{`${_scheduledTraining.TimeStart} - ${_scheduledTraining.TimeEnd}`}</p>
                                    </div>
                                </div>
                            );
                        } else {
                            dayItem = (provided, snapshot) => (
                                <div className={`training__ueLUDK unselectable${loadingCourseCatalogID.includes(_scheduledTraining.CourseCatalogID) ? ' is-disabled' : ''}`}
                                    ref={provided.innerRef}
                                    {...provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}>
                                    <p className="title__uVegWE" onClick={() => {
                                        _this.setState({ selectedTrainingID: _scheduledTraining.TrainingID }, () => {
                                            openModal('modal-trainingDetails');
                                        });
                                    }}>{_scheduledTraining.Title}</p>
                                    <div className="time__UdiDlc">
                                        <span>{`${_scheduledTraining.TimeStart} - ${_scheduledTraining.TimeEnd}`}</span>
                                    </div>
                                    {renderSubscribeBtn}
                                </div>
                            );
                        }

                        if (dayItem) {
                            renderTraining.push(
                                <Draggable
                                    draggableId={`${_scheduledTraining.TSDRID}`}
                                    index={index}
                                    key={_scheduledTraining.TSDRID}
                                    isDragDisabled={true}>
                                    {dayItem}
                                </Draggable>
                            )
                        }
                    });
                }

                cellValueClassName = `${cellValueClassName} cell-value__PBBxQA`.trim();

                key = `${Math.random() * 10 + 1}-${day}`;

                renderTrainingContainer = (
                    <div className="training-container__VKiRWf">
                        {renderTraining}
                    </div>
                );


                if (showCountsPerDay) {
                    renderCountsPerDay = renderTraining.length;

                    if (renderCountsPerDay < 2)
                        renderCountsPerDay = <span></span>;
                    else {
                        renderCountsPerDay = (
                            <button className="button is-primary is-outlined is-xsmall" onClick={_this.onClickDayItemsCount.bind(this, { day })}>
                                <i className="fa fa-eye pr-0"></i>
                            </button>
                        );
                    }
                }

                renderCellValue = (
                    <Droppable
                        key={key}
                        droppableId={`${day}`}>
                        {(provided, snapshot) => (
                            <td className={cellValueClassName}
                                ref={provided.innerRef}>
                                <div className="day">
                                    <span></span>
                                    <span className={dateClassName}>{day}</span>
                                    {renderCountsPerDay}
                                </div>
                                {renderTrainingContainer}
                                <div className="add-training__drEjKC btn-lms-nolayout" role="button" title={texts[0]}>
                                    {/* <i className="fa fa-plus-square"></i> */}
                                </div>
                            </td>
                        )}
                    </Droppable>
                )

                _renderCalendarRow.push(renderCellValue);

                if (week % 7 === 6 && lastDayOfTheWeek >= lastDateOfCurrentMonth) {
                    row = 10;
                }

                ++week;
            }

            _renderCalendarRow = (
                <tr key={key}>
                    {_renderCalendarRow}
                </tr>
            );

            renderCalendarDateRow.push(_renderCalendarRow);
        }

        renderCalendarDateRow = (
            <tbody>
                {renderCalendarDateRow}
            </tbody>
        );
        // End dates display

        // Start calendar display
        renderCalendar = (
            <DragDropContext >
                <table className="calendar__g6GnsY">
                    {renderDaysOfWeek}
                    {renderCalendarDateRow}
                </table>
            </DragDropContext>
        )
        // End calendar display

        return (
            <>
                <div className="main-calendar__ngmcZS">
                    <section id="divcalendartable">
                        {renderCalendar}
                        {this.renderCenterAnnouncement()}
                    </section>
                </div>
                {_this.renderSelectedTraining()}
                {_this.renderSelectedTrainingPerDay()}
            </>
        );
    }

    renderSelectedTraining() {
        const _this = this,
            { state, token } = _this,
            { showSubscribeBtn } = this.props,
            { selectedTrainingID, loadingCourseCatalogID } = state;
        let { trainingListSchedules } = _this.props,
            renderSubscribeBtn,
            renderTitle,
            renderDescription,
            renderCourseCategory,
            renderTrainer,
            renderCompanyName,
            renderContactNumber,
            renderEmail,
            renderLocation,
            renderBARM,
            renderSchedules,
            renderCourseCatalogID,
            renderCoverImage;

        if (selectedTrainingID && trainingListSchedules) {
            for (let x = 0; x < trainingListSchedules.length; x++) {
                let trainingListSchedule = trainingListSchedules[x];

                if (trainingListSchedule.TrainingID == selectedTrainingID) {
                    renderCourseCatalogID = trainingListSchedule.CourseCatalogID;
                    renderTitle = trainingListSchedule.Title;
                    renderDescription = trainingListSchedule.Description;
                    renderCourseCategory = trainingListSchedule.CourseCategoryName;
                    renderTrainer = trainingListSchedule.Trainer;
                    if (!trainingListSchedule.TrainerIsEmployee) {
                        renderCompanyName = (<p>{trainingListSchedule.CompanyName}</p>);
                        renderContactNumber = (<p>{trainingListSchedule.ContactNumber}</p>);
                        renderEmail = (<p>{trainingListSchedule.Email}</p>);
                    }
                    renderLocation = trainingListSchedule.Location;
                    renderBARM = trainingListSchedule.BuildingAndRoomNumber;
                    if (trainingListSchedule.TrainingSchedules) {
                        renderSchedules = trainingListSchedule.TrainingSchedules.map(_item => {
                            return (
                                <tr key={`training-schedule-${_item.TrainingScheduleDayID}`}>
                                    <td className="pr-10"><i className="fa fa-calendar"></i></td>
                                    <td className="pr-30">{_item.DateScheduled}</td>
                                    <td className="pr-30">@</td>
                                    <td className="pr-10"><i className="fa fa-clock-o"></i></td>
                                    <td className="pr-10">{_item.TimeStart}</td>
                                    <td className="pr-10">{texts[10]}</td>
                                    <td>{_item.TimeEnd}</td>
                                </tr>
                            );
                        });
                    }

                    if (trainingListSchedule.CoverImageMediaFileDetails) {
                        renderCoverImage = (
                            <MediaLibraryImg
                                path={trainingListSchedule.CoverImageMediaFileDetails.LocationReference}
                                width={480}
                                height={480}
                            />
                        );
                    }

                    if (showSubscribeBtn && token) {
                        let text = "",
                            className = "",
                            icon = "";

                        if (parseInt(trainingListSchedule.EmployeeSubscribedLearningRID)) {
                            className = '-danger-btn-lms';
                            text = texts[5];
                            icon = 'fa-times';
                        } else {
                            className = '-success-btn-lms';
                            text = texts[4];
                            icon = 'fa-check';
                        }

                        className = `button is-small ${className}`;
                        icon = `fa ${icon}`;

                        if (loadingCourseCatalogID.includes(trainingListSchedule.CourseCatalogID)) {
                            className = `${className} is-disabled is-loading`;
                        }

                        renderSubscribeBtn = (
                            <button className={className} onClick={_this.onClickSubscribeUnsubscribeBtn.bind(_this, trainingListSchedule)}><i className={icon}></i>{text}</button>
                        );
                    }

                    break;
                }
            }
        }

        return (
            <div id="modal-trainingDetails" className="modal modal-sm -lms-modal-primary modal__QQwcZJ">
                <div className="modal-background is-translucent"></div>
                <div className="modal-card modal-content">
                    <div className="flex-card simple-shadow oflow-unset">
                        <header className="modal-card-head">
                            <p className="modal-card-title">{renderTitle}</p>
                            <button className="delete is-medium" aria-label="close" onClick={() => {
                                closeModal('modal-trainingDetails');
                            }}></button>
                        </header>
                        <div className="modal-card-image">
                            <figure className="image is-2by1">
                                {renderCoverImage}
                            </figure>
                        </div>
                        <section className="modal-card-body">

                            <div className="div__RrnJfe">
                                <span className="lms-h6 mb-5">{texts[6]}</span>
                                <p>{renderDescription}</p>
                            </div>
                            {
                                renderCourseCategory ? (
                                    <div className="div__RrnJfe">
                                        <span className="lms-h6 mb-5">{texts[7]}</span>
                                        <p>{renderCourseCategory}</p>
                                    </div>
                                ) : <></>
                            }
                            <div className="columns div__RrnJfe">
                                <div className="column is-6 pb-0">
                                    <span className="lms-h6 mb-5">{texts[8]}</span>
                                    <p>{renderTrainer}</p>
                                    {renderCompanyName}
                                    {renderContactNumber}
                                    {renderEmail}
                                </div>
                                <div className="column is-6 pb-0">
                                    <span className="lms-h6 mb-5">{texts[9]}</span>
                                    <p>{renderLocation}</p>
                                    <p>{renderBARM}</p>
                                </div>
                            </div>
                            {
                                renderSchedules ? (
                                    <div className="div__RrnJfe">
                                        <span className="lms-h6 mb-5">{texts[11]}</span>
                                        <table style={{ width: "100%" }}>
                                            <tbody>
                                                {renderSchedules}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : <></>
                            }
                        </section>
                        <section className="modal-card-foot">
                            <div>
                                {renderSubscribeBtn}
                                <Link to={`${routePaths[6]}/${renderCourseCatalogID}`} target="_blank" className="button -primary-btn-lms is-small is-bold NUKanx">{texts[12]}</Link>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }

    renderSelectedTrainingPerDay() {
        const _this = this,
            { token } = this,
            { selectedTrainingPerDay, loadingCourseCatalogID } = this.state,
            { showSubscribeBtn } = this.props,
            { date, list } = selectedTrainingPerDay;
        let renderList = [],
            renderImage;

        renderList = list.map((_item, index) => {
            let { Title, Description, Trainer, TimeStart, TimeEnd, Location, BuildingAndRoomNumber, CourseCategoryName,
                DT_Scheduled, CoverImageMediaFileDetails } = _item,
                renderSubscribeBtn;

            if (CoverImageMediaFileDetails) {
                renderImage = <MediaLibraryImg
                    path={CoverImageMediaFileDetails.LocationReference}
                    width={480}
                    height={480}
                />;
            } else {
                renderImage = (<img className="of-cover" src={defaultImageLocations}></img>);
            }

            if (showSubscribeBtn && token) {
                let text = "",
                    className = "",
                    icon = "";

                if (parseInt(_item.EmployeeSubscribedLearningRID)) {
                    className = '-danger-btn-lms';
                    text = texts[5];
                    icon = 'fa-times';
                } else {
                    className = '-success-btn-lms';
                    text = texts[4];
                    icon = 'fa-check';
                }

                className = `button is-small ${className}`;
                icon = `fa ${icon}`;

                if (loadingCourseCatalogID.includes(_item.CourseCatalogID)) {
                    className = `${className} is-disabled is-loading`;
                }

                renderSubscribeBtn = (
                    <button className={className} onClick={_this.onClickSubscribeUnsubscribeBtn.bind(_this, _item)}><i className={icon}></i>{text}</button>
                );
            }

            return (
                <div className="column is-3" key={`cert-${index}`}>
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-2by1">
                                {renderImage}
                            </figure>
                        </div>
                        <div className="card-content">
                            <div className="media">
                                <div className="media-content">
                                    {Title}
                                </div>
                            </div>
                            <div className="content">
                                <div className="div__NKyaGV">
                                    <span className="lms-h6 mb-5">{texts[6]}</span>
                                    <p>{Description}</p>
                                </div>
                                <div className="div__NKyaGV">
                                    <span className="lms-h6 mb-5">{texts[7]}</span>
                                    <p>{CourseCategoryName}</p>
                                </div>
                                <div className="columns div__NKyaGV">
                                    <div className="column is-6 pb-0">
                                        <span className="lms-h6 mb-5">{texts[8]}</span>
                                        <p>{Trainer}</p>
                                    </div>
                                    <div className="column is-6 pb-0">
                                        <span className="lms-h6 mb-5">{texts[9]}</span>
                                        <p>{Location}</p>
                                        {BuildingAndRoomNumber}
                                    </div>
                                </div>
                                <div className="div__NKyaGV">
                                    <span className="lms-h6 mb-5">{texts[11]}</span>
                                    <table style={{ width: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <td className="pr-10"><i className="fa fa-calendar"></i></td>
                                                <td className="pr-10">{DT_Scheduled}</td>
                                                <td className="pr-10">@</td>
                                                <td className="pr-10"><i className="fa fa-clock-o"></i></td>
                                                <td className="pr-10">{TimeStart}</td>
                                                <td className="pr-10">{texts[10]}</td>
                                                <td>{TimeEnd}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="card-foot f-end">
                            {renderSubscribeBtn}
                        </div>
                    </div>
                </div>
            );
        });

        if (renderList.length > 0) {
            renderList = (
                <div className="columns list__udFwdU">
                    {renderList}
                </div>
            );
        }

        return (
            <div id="modal-trainingsPerDay" className="modal modal-md -lms-modal-primary modal__QQwcZJ">
                <div className="modal-background is-translucent"></div>
                <div className="modal-card modal-content">
                    <div className="flex-card simple-shadow oflow-unset">
                        <header className="modal-card-head">
                            <p className="modal-card-title">{date}</p>
                            <button className="delete is-medium" aria-label="close" onClick={() => {
                                closeModal('modal-trainingsPerDay');
                            }}></button>
                        </header>
                        <section className="modal-card-body">
                            {renderList}
                        </section>
                    </div>
                </div>
            </div>
        );
    }

    renderCenterAnnouncement() {

        const { shouldRenderCenterAnnouncement, labelReducer, userSettingsReducer, SystemSettingsReducer } = this.props,
            { showRenderCenterAnnouncement } = this.state;


        const sysSettingKeys = SystemSettingsReducer[43];
        if (sysSettingKeys) {
            const sysValue = parseInt(sysSettingKeys.Value)

            if (sysValue == 1 && shouldRenderCenterAnnouncement && showRenderCenterAnnouncement) {
                const popoutText = this.LabelGenerator.nonStaticProcessLabel({
                    LabelID: labelIDs[0],
                    LabelReducer: labelReducer,
                    UserSettingsReducer: userSettingsReducer

                });

                return (
                    <div className="calendar_div__xwCgTs">
                        <button className="delete" aria-label="delete" onClick={() => {
                            this.setState({ showRenderCenterAnnouncement: false });
                        }}></button>
                        <div>{parse(popoutText?.LabelContent || "")}</div>
                    </div>
                )
            }

        }



    }

    onDragEnd(a, b, c, d, e) {
        console.log(a, b, c, d, e);
    }

    getScheduleTrainings(params) {
        const scheduledTrainingList = this.props.scheduledTrainingList;
        let date = params.date,
            month = params.month,
            year = params.year;

        if (scheduledTrainingList) {
            return scheduledTrainingList[`${date}_${month}_${year}`];
        }

        return [];
    }

    onClickNextMonth() {
        const props = this.props;
        let currentMonth = props.currentMonth,
            currentYear = props.currentYear;

        if (currentMonth === 11) {
            currentMonth = 0;
            currentYear += 1;
        } else {
            currentMonth += 1;
        }

        this.props.stateChanger({ currentMonth, currentYear });
    }

    onClickPreviousMonth() {
        const props = this.props;
        let currentMonth = props.currentMonth,
            currentYear = props.currentYear;

        if (currentMonth === 0) {
            currentMonth = 11;
            currentYear -= 1;
        } else {
            currentMonth -= 1;
        }

        this.props.stateChanger({ currentMonth, currentYear });
    }

    onClickNextYear() {
        const props = this.props;
        let currentYear = props.currentYear;

        currentYear += 1;

        this.props.stateChanger({ currentYear });
    }

    onClickPreviousYear() {
        const props = this.props;
        let currentYear = props.currentYear;

        currentYear -= 1;

        this.props.stateChanger({ currentYear });
    }

    onClickSubscribeUnsubscribeBtn(params, event) {
        if (parseInt(params.EmployeeSubscribedLearningRID)) {
            iziToastConfirmation({
                message: texts[1] + `${params.Title}?`,
                confirmPos: () => {
                    this.moduleQuery({
                        ...params,
                        type: 1
                    });
                }
            });

        } else {
            this.moduleQuery({
                ...params,
                type: 0
            });
        }
    }

    getTime(time) {
        let dt = new Date(Date.parse(`2020-01-13T${time}Z`)),
            hours = dt.getHours(),
            minutes = dt.getMinutes();

        if (hours === 0) {
            hours = '00';
        }
        if (minutes === 0) {
            minutes = '00';
        }

        return `${hours}:${minutes}`;
    }

    moduleQuery(params) {
        const _this = this,
            state = _this.state,
            { type, dateStart, courseCatalogID, CourseCatalogID } = params;
        let Parameters = {},
            requestjson = {},
            config = {
                Data: { requestjson },
                Method: "GET",
                ResponseSuccessCallback: responseSuccessCallback,
                ResponseFailCallback: responseFailCallback,
                Token: this.hrsToken,
                Url: "/single_api/"
            },
            newState = {},
            formData = new FormData();

        switch (type) {
            case 0: {
                requestjson.Module = "learning_employeesubscribedlearning_add";
                Parameters = {
                    CourseCatalogID: CourseCatalogID,
                    EmployeeID: getEmployeeID(),
                    GetCourseCatalogDetail: 1
                };
                _this.processScheduledTrainingOnClick({
                    type: 1,
                    courseCatalogID: CourseCatalogID
                });
                break;
            }
            case 1: {
                config['Method'] = "DELETE";
                config['Url'] = "delete_by_rid";

                formData.append('RID', params.EmployeeSubscribedLearningRID);
                config.Data.requestjson = formData;

                _this.processScheduledTrainingOnClick({
                    type: 1,
                    courseCatalogID: CourseCatalogID
                });
                break;
            }
            case 2: {
                requestjson.Module = "scheduledtraining_scheduledtraining_list";
                Parameters = {
                    IsPublicCalendar: 1,
                    DateStart: dateStart,
                    GetScheduledTrainingByMonth: 0
                }
                break;
            }
        }

        if (Object.keys(newState).length > 0) {
            _this.setState(newState, () => {
                processRequest();
            });
        } else {
            processRequest();
        }

        function processRequest() {
            requestjson.Parameters = Parameters;
            newState = {};
            config = genericQuery(config);
        }

        function responseSuccessCallback(responseJson) {
            let data = responseJson.data,
                status = data.Status;

            if (status.IsSuccess) {

                switch (type) {
                    case 0: {
                        let _data = data.Data.Data;
                        iziToastFn({
                            mode: 1,
                            message: texts[2]
                        });
                        _this.processScheduledTrainingList({
                            type,
                            oldDetails: params,
                            newDetails: _data.MainDetails
                        });
                        _this.processScheduledTrainingOnClick({
                            type: 0,
                            courseCatalogID: CourseCatalogID
                        });
                        break;
                    }
                    case 1: {
                        iziToastFn({
                            mode: 1,
                            message: texts[3]
                        });
                        _this.processScheduledTrainingList({
                            type,
                            oldDetails: params
                        });
                        _this.processScheduledTrainingOnClick({
                            type: 0,
                            courseCatalogID: CourseCatalogID
                        });
                        break;
                    }
                    case 2: {
                        const { selectedTrainingPerDay } = _this.state;
                        let _data = data.Data,
                            scheduledTrainingList = _data.ScheduledTrainingList;

                        scheduledTrainingList = scheduledTrainingList || [];
                        selectedTrainingPerDay.list = scheduledTrainingList;

                        newState['selectedTrainingPerDay'] = selectedTrainingPerDay;

                        break;
                    }
                }
            } else {
                iziToastFn({
                    mode: 3,
                    message: status.Message
                });
                _this.processScheduledTrainingOnClick({
                    type: 0,
                    courseCatalogID: CourseCatalogID
                });
            }

            _this.setState(newState);
        }

        function responseFailCallback(responseJson) {
            iziToastFn({
                mode: 3,
                message: responseJson.message
            });
            _this.processScheduledTrainingOnClick({
                type: 0,
                courseCatalogID: CourseCatalogID
            });
        }
    }

    processScheduledTrainingList(params) {
        const type = params.type,
            oldDetails = params.oldDetails,
            newDetails = params.newDetails;
        let { scheduledTrainingList, trainingListSchedules } = this.props,
            { selectedTrainingPerDay } = this.state,
            shouldUpdateScheduledTrainingList = false;

        if (scheduledTrainingList) {
            Object.keys(scheduledTrainingList).forEach(key => {
                let scheduledTrainingDay = scheduledTrainingList[key];

                if (scheduledTrainingDay && Array.isArray(scheduledTrainingDay)) {
                    scheduledTrainingDay.forEach((_scheduledTraining, index) => {
                        if (_scheduledTraining.CourseCatalogID == oldDetails.CourseCatalogID) {
                            switch (type) {
                                case 0:
                                    scheduledTrainingList[key][index]['EmployeeSubscribedLearningID'] = newDetails.EmployeeSubscribedLearningID;
                                    scheduledTrainingList[key][index]['EmployeeSubscribedLearningRID'] = newDetails.EmployeeSubscribedRID;
                                    shouldUpdateScheduledTrainingList = true;
                                    break;
                                case 1:
                                    scheduledTrainingList[key][index]['EmployeeSubscribedLearningID'] = 0;
                                    scheduledTrainingList[key][index]['EmployeeSubscribedLearningRID'] = 0;
                                    shouldUpdateScheduledTrainingList = true;
                                    break;
                            }
                        }
                    });
                }
            });
        }

        if (trainingListSchedules) {
            trainingListSchedules = trainingListSchedules?.map(trainingListSchedule => {
                if (trainingListSchedule.CourseCatalogID == oldDetails.CourseCatalogID) {
                    switch (type) {
                        case 0:
                            trainingListSchedule = {
                                ...trainingListSchedule,
                                EmployeeSubscribedLearningID: newDetails.EmployeeSubscribedLearningID,
                                EmployeeSubscribedLearningRID: newDetails.EmployeeSubscribedRID
                            };
                            break;
                        case 1:
                            trainingListSchedule = {
                                ...trainingListSchedule,
                                EmployeeSubscribedLearningID: 0,
                                EmployeeSubscribedLearningRID: 0
                            };
                            break;
                    }
                }

                return trainingListSchedule;
            });
        }

        if (selectedTrainingPerDay) {
            selectedTrainingPerDay.list = selectedTrainingPerDay.list?.map(_selectedTrainingPerDay => {
                if (_selectedTrainingPerDay.CourseCatalogID == oldDetails.CourseCatalogID) {
                    switch (type) {
                        case 0:
                            _selectedTrainingPerDay = {
                                ..._selectedTrainingPerDay,
                                EmployeeSubscribedLearningID: newDetails.EmployeeSubscribedLearningID,
                                EmployeeSubscribedLearningRID: newDetails.EmployeeSubscribedRID
                            };
                            break;
                        case 1:
                            _selectedTrainingPerDay = {
                                ..._selectedTrainingPerDay,
                                EmployeeSubscribedLearningID: 0,
                                EmployeeSubscribedLearningRID: 0
                            };
                            break;
                    }
                }

                return _selectedTrainingPerDay;
            });
        }

        if (shouldUpdateScheduledTrainingList) {
            if (this.props.stateChanger) {
                if (selectedTrainingPerDay) {
                    this.setState({ selectedTrainingPerDay }, () => {
                        this.props.stateChanger({
                            scheduledTrainingList,
                            trainingListSchedules
                        });
                    });
                }
            }
        }
    }

    processScheduledTrainingOnClick(params) {
        const type = params.type,
            courseCatalogID = params.courseCatalogID;
        let { loadingCourseCatalogID } = this.state;

        switch (type) {
            case 0:
                const index = loadingCourseCatalogID.findIndex((_loadingCourseCatalogID) => {
                    return _loadingCourseCatalogID == courseCatalogID;
                });

                if (index >= 0) {
                    loadingCourseCatalogID.splice(index, 1);
                }
                break;
            case 1:
                if (!loadingCourseCatalogID.includes(courseCatalogID)) {
                    loadingCourseCatalogID.push(courseCatalogID);
                }
                break;
        }

        this.setState({ loadingCourseCatalogID });
    }

    onClickDayItemsCount(params, event) {
        const { props } = this,
            { currentMonth, currentYear } = props,
            { selectedTrainingPerDay } = this.state,
            selectedDate = convertToYMMDD(new Date(`${currentYear}-${(currentMonth + 1)}-${params.day}`));

        selectedTrainingPerDay.date = selectedDate;
        selectedTrainingPerDay.list = [];

        this.setState({
            selectedTrainingPerDay
        }, () => {
            openModal('modal-trainingsPerDay');
            this.moduleQuery({
                type: 2,
                dateStart: selectedDate
            });
        });
    }

    componentDidMount() {
        this.LabelGenerator.get_labelsByID({
            LabelIDs: labelIDs
        });
    }

    static propTypes = {
        isHighlightSubscribedTraining: PropTypes.bool,
        showSubscribeBtn: PropTypes.bool
    }
    static defaultProps = {
        isHighlightSubscribedTraining: false,
        showSubscribeBtn: false
    }
}

const mapDispatchToProps = dispatch => ({

    ...LabelGenerator.getMapDispatchProps(dispatch)
}),
    mapStateToProps = state => ({
        SystemSettingsReducer: state.SystemSettingsReducer,
        ...LabelGenerator.getMapStateToProps(state)

    });

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);