import React from "react";
import { GetLabels, iziToastDestroy, iziToastFn, processLabel } from "../../../helpers/helper";
import { LabelTranlsationToggle } from "../Label/translation";
require("./index.scss")

class Settings extends React.Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return <i className="fa fa-cog is-drop">
            <SettingsContainer />
        </i>

    }
}

export default Settings;
const SettingsItems = [
    <LabelTranlsationToggle />
]

const SettingsContainer = () => {

    return <div className="dropContain">
        <div className="dropOut">
            <ul>
                {SettingsItems.map((item, index) => <li key={index}>{item}</li>)}
                {/* <li><button className="button -primary-btn-lms button-cta" onClick={() => {
                    iziToastFn({
                        mode: 2,
                        timeout: 0,
                        message: "Labels are being reloaded"
                    })
                    GetLabels({
                        Finished: () => {
                            iziToastDestroy()
                            iziToastFn({
                                mode: 1,
                                message: "Labels Reloaded"
                            })
                        }
                    })
                }}>Reload Labels</button></li> */}
            </ul>
        </div>
    </div>
}
