import React, { Component } from "react";
import { encodeToBtoa, genericQuery } from "../../../../../helpers/helper";

import { getEmployeeID } from "../../../../../helpers/localStorage";
import { MEDIA_URL } from "../../../../../helpers/config";
import MediaLibraryImg from "../../../../imports/MediaLibrary/index"
import { Redirect } from 'react-router-dom';
import '../index.scss';
import '../../index.scss';
import { LoadingBeatLoader_Absolute } from "../../../../imports";
// const $ = window.$;
let cancelToken = {};
export default class CourseCatalogDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CatalogDetail: {},
            SubDetail: [],
            SurveyDetail: {},
            CourseMatchDataDetail: {},
            AdvancedDetail: {},
            Settings: {
                IsOverviewLoading: true,
                isOverviewRefreshing: true,
                isCourseOutlineItemOpen: []
            },
            Survey: {
                SurveyDetail: [],
                isSurveyDone: true,
            },
            Progress: {
                ModulesCompleted: 4,
                ModulesCount: 5,
            }
        };
        this.GetCatalogOverview = this.GetCatalogOverview.bind(this)
        this.SubscribeToCatalog = this.SubscribeToCatalog.bind(this)
        this.UnsubscribeToCatalog = this.UnsubscribeToCatalog.bind(this)
    }
    componentWillMount() {
        this.GetCatalogOverview()
    }
    GetProgress() {
        const _this = this, state = _this.state

        let ModulesCompleted = state.Progress.ModulesCompleted, ModulesCount = state.Progress.ModulesCount, PercentEachItem = 100 / ModulesCount

        let progress = (PercentEachItem * ModulesCompleted).toFixed(2)
        return progress
    }
    render() {
        const _this = this, state = _this.state

        let CatalogDetail = Array.isArray(state.CatalogDetail) && state.CatalogDetail.length > 0 ? state.CatalogDetail[0] : [],
            SubDetail = Array.isArray(state.CatalogDetail) && state.CatalogDetail.length > 0 ? state.SubDetail[0] : [],
            // SurveyDetail = Array.isArray(state.SurveyDetail) && state.SurveyDetail.length > 0 ? state.SurveyDetail[0] : [],
            ChildComponent = state.AdvancedDetail && Array.isArray(state.AdvancedDetail.ChildComponent) && state.AdvancedDetail.ChildComponent.length > 0 ?
                state.AdvancedDetail.ChildComponent : [],
            ScheduleList = state.AdvancedDetail && Array.isArray(state.AdvancedDetail.ScheduleList) && state.AdvancedDetail.ScheduleList.length > 0 ?
                state.AdvancedDetail.ScheduleList : []

        if (CatalogDetail.HasSubscribed === 1) {
            let EmployeeSubscribedLearningID = CatalogDetail.EmployeeSubscribedLearningID
            return <Redirect to={'/course-take/' + EmployeeSubscribedLearningID} />
        } else if (CatalogDetail.HasSubscribed === 0) {
            let urlParams = encodeToBtoa(JSON.stringify({ CatalogListParameters: { SearchValue: SubDetail.Title, Filters: [] } }))
            return <Redirect to={`/catalog?${urlParams}`} />
        }


        let renderCourseOutline = (
            <div className="p-10 ">
                <div className="text-20 text-bold">Course Outline</div>
                {CatalogDetail.HasSubscribed == 1 ? state.Survey.isSurveyDone ? "" :
                    <div className="-no-survey ta-center">
                        You must do the Pre Survey in order to access the course outline.<br></br>
                        <button className={state.Settings.isOverviewRefreshing ? "button is-small -primary-btn-lms  is-loading" : "button is-small -primary-btn-lms "}>Open Survey</button>
                    </div> : ""}
                <div className={CatalogDetail.HasSubscribed == 1 ? state.Survey.isSurveyDone ? "multi-toggle-wrapper" : "multi-toggle-wrapper is-disabled" : "multi-toggle-wrapper"} >
                    {ChildComponent.map((item, index) =>
                        <div className="mt-item" key={index} onClick={() => {
                            const /* children = this.state.AdvancedDetail.ChildComponent[index], */ newState = ChildComponent

                            if (!item.IsOpened) {
                                newState[index] = { ...newState[index], IsOpen: true }
                                this.setState({ AdvancedDetail: { ChildComponent: newState } })
                            }
                        }}>
                            {item.IsOptional == 1 ? <span htmlFor={"mt-item-" + index} className="tag -oval" style={{ position: "absolute", right: "70px", top: "20px", }}>
                                Is Optional
                            </span> : ""}
                            <input id={"mt-item-" + index} className="mt-item-input" type="checkbox" name={"mt" + index} />
                            <div id={"mt-item-" + index} className="mt-item-icon">
                                <img
                                    src={`${process.env.PUBLIC_URL
                                        }/images/icons/${getModuleIcon(item.CourseCatalogTypeID === 1 ? item.Detail[0].TypeName : item.Detail.TypeName)}`}
                                    alt="Placeholder"
                                    className="mt-item-icon"
                                />
                            </div>

                            <label htmlFor={"mt-item-" + index} className="mt-item-label">

                                <span> {item.CourseCatalogTypeID === 1 ? item.Detail[0].Title : item.Detail.Title}</span>
                                <br></br>
                                <span>{item.CourseCatalogTypeID === 1 ? item.Detail[0].TypeName : item.Detail.TypeName}</span>
                            </label>

                            <div className="mt-item-content">
                                <span>{item.CourseCatalogTypeID === 1 ? item.Detail[0].Description : item.Detail.Description}</span>
                            </div>

                        </div>
                    )}
                </div>
            </div>
        )

        if (state.Settings.IsOverviewLoading) {
            return <LoadingBeatLoader_Absolute />
        } else {
            return <div id="user-catalog-detail">
            </div >
        }

    }
    SubscribeToCatalog() {
        const formData = new FormData()
        let _this = this
        let requestjson = {
            Module: "learning_employeesubscribedlearning_add",
            Parameters: {
                CourseCatalogID: this.state.CatalogDetail[0].CourseCatalogID,
                EmployeeID: getEmployeeID()
            }
        },
            config = {
                CancelToken: cancelToken.CourseCategoryList,
                Data: { requestjson: formData },
                Method: "POST",
                ResponseSuccessCallback: responseSuccessCallback,
                ResponseFailCallback: responseFailCallback,
                Url: "/single_api/"
            };

        formData.append("requestjson", JSON.stringify(requestjson))
        genericQuery(config);
        function responseSuccessCallback(responseJson) {
            console.log("responseSuccessCallback", responseJson);
            _this.GetCatalogOverview()


        }
        function responseFailCallback(responseJson) {
            console.log("responseFailCallback", responseJson);
        }
    }

    UnsubscribeToCatalog() {
        const formData = new FormData();
        let _this = this, state = _this.state

        let config = {
            CancelToken: cancelToken.CourseCategoryList,
            Data: { requestjson: formData },
            Method: "DELETE",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/delete_by_rid/"
        };
        formData.append("RID", state.CatalogDetail[0].EmployeeSubscribedRID)
        genericQuery(config);
        function responseSuccessCallback(responseJson) {
            console.log("responseSuccessCallback", responseJson);
            _this.GetCatalogOverview()
        }
        function responseFailCallback(responseJson) {
            console.log("responseFailCallback", responseJson);
        }
    }

    GetCatalogOverview() {
        const _this = this,
            state = _this.state;
        _this.setState({ Settings: { ...state.Settings, isOverviewRefreshing: true } })
        let _id = this.props.match.params.id,
            requestjson = {
                Module: "coursecatalog_coursecatalog_overview",
                Parameters: {
                    CourseCatalogID: parseInt(_id)
                }
            },
            config = {
                Data: { requestjson },
                Method: "GET",
                ResponseSuccessCallback: responseSuccessCallback,
                ResponseFailCallback: responseFailCallback,
                Url: "/single_api/"
            };

        genericQuery(config);
        function responseSuccessCallback(responseJson) {
            console.log("responseSuccessCallback GetCatalogOverview", responseJson);
            const _data = responseJson.data.Data;
            let newState = {}/* , _selectedAll = []; */
            newState["CatalogDetail"] =
                _data.CourseCatalogOverviewDetail[0].CourseCatalogMainDetail;
            newState["SubDetail"] = _data.CourseCatalogOverviewDetail[1].SubDetail;
            newState["SurveyDetail"] =
                _data.CourseCatalogOverviewDetail[3].SurveyDetail;
            newState["AdvancedDetail"] =
                _data.CourseCatalogOverviewDetail[2].AdvancedDetail[0];
            _this.setState(newState);

            _this.setState({
                Settings: {
                    ...state.Settings,
                    IsOverviewLoading: false,
                    isOverviewRefreshing: false,
                }
            })


        }
        function responseFailCallback(responseJson) {
            console.log("responseFailCallback", responseJson);
        }
    }


}



const getModuleIcon = type => {
    switch (type) {
        case "Training":
            return "training.svg";
        case "HYVA Course Builder":
            return "hyvabuilder.svg";
        case "Document":
            return "doc.svg";
        case "PDF":
            return "pdf.svg";
        case "Powerpoint":
            return "ppt.svg";
        case "Course":
            return "icon_course_child.svg"
        case "Scorm File":
            return "scorm.svg"
        case "URL":
            return "url.svg"
        default:
            return "file.svg";
    }
};

const getIcon = type => {
    switch (type) {
        case "HYVA Course Builder":
            return "hyvabuilder.svg";
        case "Document":
            return "doc.svg";
        case "PDF":
            return "pdf.svg";
        case "Powerpoint":
            return "ppt.svg";
        case "Course":
            return "course.svg"
        case "Scorm File":
            return "scorm.svg"
        case "URL":
            return "url.svg"
        case "Module":
            return "course.svg";
        case "Training":
            return "training.svg";
        default:
            return "course.svg";
    }
};