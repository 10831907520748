import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modifyUserSettingsItem } from '../../../redux/mixin/UserSettings';
import { socket, genericQuery, iziToastFn, convertToDDMMY, iziToastConfirmation, GetLabels, LMSLabelIDs, iziToastDestroy, processLabel } from '../../../helpers/helper';
import { getEmpName, getEmployeeID, setEmployeeLanguage } from '../../../helpers/localStorage';
import { FLAG_IMG_URL } from '../../../helpers/config';

const texts = [
    'Notifications', // 0
    'New Notification', // 1
    'Languages', // 2
    'You have changed your language. Would you like to refresh the page?' // 3
];
class HeaderNotification extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userNotification: [],
            params: {
                PageNumber: 1,
                PageSize: 10,
                SortField: 'UserNotificationID',
                SortDirection: 0
            },
            hasNewNotification: false,
            language: {
                languageList: [],
                selectedLanguage: null
            }
        }
        let LabelIds = [-1242, -1243, -1244, -1245]
        texts.map((text, index) => {
            text = processLabel({ LabelID: LabelIds[index], DefaultLabel: text })
            return text
        })
    }

    render() {
        const _this = this,
            { state } = _this,
            { userNotification } = state;
        let renderList,
            renderItem;

        if (userNotification.length > 0) {
            renderItem = userNotification.map(_userNotification => {
                return (<li className={`${!_userNotification.IsSeen ? 'is-unseen' : ''}`} key={`notif-${_userNotification.UserNotificationID}`}
                    onClick={_this.onClickNotification.bind(this, _userNotification)}>
                    <div className="notification-content">
                        <div className="notification-text">
                            <div className="text"><b>{_userNotification.NotificationTypeName}</b> {_userNotification.Message}</div>
                            {
                                _userNotification.CourseCatalogDetail ? (
                                    <div className="timestamp">{_userNotification.CourseCatalogDetail.Title}</div>
                                ) : null
                            }
                            <div className="timestamp">{_userNotification.DT_Created}</div>
                        </div>
                    </div>
                </li>);
            });
        } else {
            renderItem = (<div className="empty">
                <img src={`${process.env.PUBLIC_URL}/images/illustrations/icon_empty.svg`} alt="No Notifications" />
            </div>);
        }

        renderList = (
            <div className="notifications-body">
                <ul className="notifications-list">
                    {renderItem}
                </ul>
            </div>
        );

        return (
            <>
                <div className="lms-notification navbar-item drop-pop is-centered nav-icon">
                    <a className="nav-inner">
                        <i className="sl sl-icon-bell">
                            {/* <span className="new-circle gelatine"></span> */}
                        </i>
                    </a>
                    <div className="lms-notification drop-wrapper notifications-drop">
                        <div className="drop-inner has-arrow">
                            <div className="notifications-header has-text-centered">
                                <h3>{texts[0]}</h3>
                            </div>
                            {renderList}
                        </div>
                    </div>
                </div>
                {this.renderNavbarLanguageIcon()}
                {/* <button className="button btn-primary-lms" onClick={() => {
                    GetLabels({ LabelIDs: LMSLabelIDs })
                }}>Reload Labels</button> */}
            </>
        );
    }

    renderNavbarLanguageIcon() {
        const { language } = this.state,
            { selectedLanguage, languageList } = language;
        let renderLanguage,
            renderLanguageIcon,
            renderLanguageList = [],
            renderLanguageListCount = 0;

        if (selectedLanguage) {
            renderLanguageIcon = (
                <img className="lms-flag" src={`${FLAG_IMG_URL}/${selectedLanguage.FlagCode}.svg`} title={selectedLanguage.LanguageName} />
            );
        }

        if (languageList) {
            languageList.forEach((_languageList, index) => {
                if (!_languageList.IsUserLanguage) {
                    renderLanguageList.push(<li key={`language-list-${index}`} onClick={this.onClickLanguageItem.bind(this, _languageList)}>
                        <div className="language-content">
                            <div className="language-text">
                                <img className="lms-flag" src={`${FLAG_IMG_URL}/${_languageList.FlagCode}.svg`} title={_languageList.LanguageName} />
                                <span className="text"><b>{_languageList.LanguageName}</b></span>
                            </div>
                        </div>
                    </li>);
                }
            });

            renderLanguageListCount = renderLanguageList.length;

            if (renderLanguageListCount) {
                renderLanguageList = (
                    <div className="notifications-body">
                        <ul className="notifications-list">
                            {renderLanguageList}
                        </ul>
                    </div>
                );
            }
        }

        renderLanguage = (<div className="lms-notification navbar-item drop-pop is-centered nav-icon">
            <a className={`nav-inner${renderLanguageListCount ? '' : ' -cursor-default'}`}>
                {renderLanguageIcon}
            </a>
            {
                renderLanguageListCount ?
                    <div className="lms-notification drop-wrapper notifications-drop">
                        <div className="drop-inner has-arrow">
                            <div className="notifications-header has-text-centered">
                                <h3>{texts[2]}</h3>
                            </div>
                            {renderLanguageList}
                        </div>
                    </div> : <></>
            }
        </div>);

        return renderLanguage;
    }

    onClickNotification(notification, e) {
        if (notification.URL) {
            window.open(notification.URL);
        }

        if (!notification.IsSeen) {
            let formData = new FormData();

            formData.append('NewValue', 1);
            formData.append('OldValue', 0);
            formData.append('RID', `${notification.RID}.IsSeen`);
            formData.append('userID', getEmployeeID());

            this.moduleQuery({
                type: 2,
                formData,
                notification
            });
        }

        // console.log(notification, e);
    }

    onClickLanguageItem(language, e) {
        this.moduleQuery({
            type: 4,
            languageID: language.LanguageID
        });
    }

    moduleQuery(params) {
        const _this = this,
            state = _this.state,
            { type, count, formData, notification, languageID } = params;
        let Parameters = {},
            requestjson = {},
            config = {
                Data: { requestjson },
                Method: "GET",
                ResponseSuccessCallback: responseSuccessCallback,
                ResponseFailCallback: responseFailCallback,
                Url: "/single_api/"
            },
            newState = {};

        switch (type) {
            case 0: {
                let { params } = state;
                requestjson.Module = 'notification_usernotification_list';
                Parameters = params;
                break;
            }
            case 1: {
                let { params } = state;
                requestjson.Module = 'notification_usernotification_list';
                Parameters = {
                    ...params,
                    PageSize: 10,
                    PageNumber: 1
                };
                break;
            }
            case 2: {
                config.Url = '/update_by_rid/';
                config.Method = 'PUT';
                config.Data.requestjson = formData;
            }
            case 3: {
                requestjson.Module = 'generic_lmslanguage_list';
                Parameters = {
                    EmployeeID: getEmployeeID()
                };
                break;
            }
            case 4: {
                requestjson.Module = 'generic_lmslanguage_update';
                Parameters = {
                    EmployeeID: getEmployeeID(),
                    LanguageID: languageID
                };
            }
        }

        if (Object.keys(newState).length > 0) {
            _this.setState(newState, () => {
                processRequest();
            });
        } else {
            processRequest();
        }

        function processRequest() {
            requestjson.Parameters = Parameters;
            newState = {};
            config = genericQuery(config);
        }

        function responseSuccessCallback(responseJson) {
            let { state } = _this,
                data = responseJson.data,
                status = data.Status;
            newState = {};

            // console.log(responseJson);

            if (status.IsSuccess) {
                if ([2].includes(type)) {
                    switch (type) {
                        case 2: {
                            let { userNotification } = _this.state;

                            userNotification = userNotification.map(_userNotification => {
                                if (_userNotification.UserNotificationID == notification.UserNotificationID) {
                                    _userNotification['IsSeen'] = true;
                                }

                                return _userNotification;
                            });

                            newState['userNotification'] = userNotification;
                            break;
                        }
                    }
                } else {
                    if (data) {
                        let _data = data.Data,
                            userNotification = _data.UserNotification,
                            lmsLanguage = _data.LMSLanguage;

                        userNotification = userNotification || [];
                        lmsLanguage = lmsLanguage || [];

                        switch (type) {
                            case 0: {
                                newState['userNotification'] = userNotification;
                                break;
                            }
                            case 1: {
                                let stateUserNotification = state.userNotification,
                                    addedNotification = 0;

                                console.log("sulod");

                                userNotification.forEach(_userNotification => {
                                    let userNotificationIndex = stateUserNotification.findIndex(_stateUserNotification => {
                                        return _stateUserNotification.UserNotificationID == _userNotification.UserNotificationID;
                                    });

                                    if (userNotificationIndex == -1) {
                                        stateUserNotification.splice(0, 0, _userNotification);
                                        ++addedNotification;
                                    }
                                });

                                if (addedNotification > 0) {
                                    newState['userNotification'] = userNotification;
                                    iziToastFn({
                                        mode: 0,
                                        message: texts[1]
                                    });
                                }

                                break;
                            }
                            case 3: {
                                let { language } = state,
                                    selectedLanguage;

                                for (let counter = 0; counter < lmsLanguage.length; ++counter) {
                                    let _lmsLanguage = lmsLanguage[counter];

                                    if (_lmsLanguage && _lmsLanguage.IsUserLanguage) {
                                        selectedLanguage = _lmsLanguage;
                                        break;
                                    }
                                }

                                newState['language'] = {
                                    ...language,
                                    languageList: lmsLanguage,
                                    selectedLanguage
                                };

                                if (selectedLanguage) {
                                    _this.props.modifyUserSettingsItem({
                                        NewProps: {
                                            UserLanguage: selectedLanguage
                                        }
                                    });
                                }
                                break;
                            }
                            case 4: {
                                setEmployeeLanguage(data.Data.Language)
                                iziToastFn({
                                    mode: 2,
                                    title: "Please wait",
                                    message: "Site is being translated.",
                                    timeout: false
                                })
                                GetLabels({
                                    // LanguageID: 36,
                                    // LabelIDs: LMSLabelIDs,
                                    Finished: () => {
                                        iziToastDestroy()
                                        iziToastConfirmation({
                                            message: texts[3],
                                            confirmPos: () => {
                                                window.location.reload();
                                            },
                                            confirmNeg: () => {
                                                let { language } = state,
                                                    { languageList, selectedLanguage } = language,
                                                    _language = _data.Language;

                                                if (_language) {
                                                    languageList = languageList.map(_languageList => {
                                                        if (_languageList.LanguageID == _language.LanguageID) {
                                                            selectedLanguage = _languageList;
                                                            _languageList.IsUserLanguage = 1;
                                                        } else {
                                                            _languageList.IsUserLanguage = 0;
                                                        }
                                                        return _languageList;
                                                    });

                                                    newState['language'] = {
                                                        ...language,
                                                        languageList: lmsLanguage,
                                                        selectedLanguage
                                                    };

                                                    _this.setState(newState);
                                                }
                                            }
                                        });
                                    }
                                })

                                break;
                            }
                        }
                    }
                }
            } else {
                iziToastFn({
                    mode: 3,
                    message: status.Message
                });
            }

            _this.setState(newState);
        }

        function responseFailCallback(responseJson) {
            console.log("responseFailCallback", responseJson);

            if (responseJson.message !== "change request") {
                iziToastFn({
                    mode: 3,
                    message: responseJson.message
                });
            }

            switch (params.type) {
                case 0:
                    break;
            }
        }
    }

    componentDidMount() {
        socket.on('notify', (params) => {
            let count = 0,
                notifications = params;

            // if (notifications) {
            //     notifications.forEach(_notification => {
            //         const employeeID = getEmployeeID();
            //         let employees = _notification.Employees;

            //         if (employees) {
            //             employees.forEach(_employee => {
            //                 if (_employee.EmployeeID == employeeID)
            //                     ++count;
            //             });
            //         }
            //     });
            // }

            // if (count > 0) {
            this.moduleQuery({
                type: 1,
                count
            });
            // }
        });

        this.moduleQuery({
            type: 0
        });
        this.moduleQuery({
            type: 3
        });
    }
}

const mapDispatchToProps = dispatch => ({
    modifyUserSettingsItem: (data) => dispatch(modifyUserSettingsItem(data))
});

export default connect(null, mapDispatchToProps)(HeaderNotification);