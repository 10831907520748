import React from 'react';
import Dropzone from 'react-dropzone';
import { genericQuery, iziToastFn, encodeToBtoa, formatBytes, processLabel } from '../../../helpers/helper';
import { createPortal } from 'react-dom';
import PropTypes from "prop-types";
import { Carousel } from 'react-responsive-carousel';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { MEDIA_LIB_URL_GETTER, MEDIA_LIB_URL } from '../../../helpers/config';
import "react-responsive-carousel/lib/styles/carousel.min.css";
require('./index.scss');

const texts = [
    'Select files here', // 0
    "Upload or drag 'n' drop some file(s) here", // 1
    'Upload', // 2
    'Remove' // 3
]
export default class MediaLibraryImg extends React.Component {
    static propTypes = {
        path: PropTypes.string.isRequired,
        width: PropTypes.number,
        height: PropTypes.number,
        crop: PropTypes.bool,
        time: PropTypes.number,
        isPlayable: PropTypes.bool,
        type: PropTypes.string
    }

    render() {
        const params = {},
            { path, width, height, crop, time, isPlayable, type, style } = this.props;

        if (path)
            params['path'] = path;
        if (width)
            params['width'] = width;
        if (height)
            params['height'] = height;
        if (crop)
            params['crop'] = crop;
        if (time)
            params['time'] = time;

        let src = MEDIA_LIB_URL_GETTER,
            queryParams = new URLSearchParams(params);

        src = `${src}?${queryParams.toString()}`;

        if (path) {
            if (isPlayable && path.includes('/video/')) {
                return (
                    <video controls>
                        <source src={src} type={type}></source>
                    </video>
                );
            } else
                return (<img className="of-cover" src={src} style={style}></img>);
        }

        return null
    }
}

class MediaLibraryFormModule extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMediaFileListInitialized: false,
            mediaFileList: [],
            selectedMediaFiles: [],
            currentSelectedMediaFiles: [],
            isSelectionContainerActive: false,
            canSelectionContainerActive: true,
            selectedMedia: null,
            dropZoneParams: {
                isDropZoneLoading: true,
                isUploadBtnLoading: false,
                uploadListQueue: [],
                uploadProgressValue: 0,
                allowMultiple: false,
                acceptedFilesStr: ""
            }
        };

        this.onClickOpenModule = this.onClickOpenModule.bind(this);
        this.onClickCloseModule = this.onClickCloseModule.bind(this);
        this.onClickSelect = this.onClickSelect.bind(this);
        this.onClickCloseViewMedia = this.onClickCloseViewMedia.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onClickUpload = this.onClickUpload.bind(this);
        this.onCarouselListDragEnd = this.onCarouselListDragEnd.bind(this);
        this.onCarouselListDragStart = this.onCarouselListDragStart.bind(this);


        let LabelIds = [-1237, -1238, -1239]
        texts.map((text, index) => {
            text = processLabel({ LabelID: LabelIds[index], DefaultLabel: text })
            return text
        })
    }

    render() {
        const state = this.state,
            props = this.props,
            currentSelectedMediaFiles = state.currentSelectedMediaFiles,
            { RenderType } = this.props;
        let renderModule;

        switch (RenderType) {
            case 1: {
                renderModule = this.renderCarousel();
                break;
            }
            case 2: {
                renderModule = this.renderSingleImage();
                break;
            }
            default:
                renderModule = (<div tabIndex="0" className="lms_medialibrarymodule">
                    <div className="media-cont__qUcjEk" onClick={this.onClickOpenModule}>
                        <input id={this.props.id} type="text" data-module={props['data-module']}
                            data-ref={props['data-ref']} data-moduletemplateattribute={props['data-moduletemplateattribute']} value={encodeToBtoa(JSON.stringify(currentSelectedMediaFiles))} readOnly />
                        {this.renderCurrentSelectedMediaFiles()}
                    </div>
                    {this.renderMediaFileListModal()}
                    {this.renderFullScreen()}
                </div>);
                break;
        }

        return (
            <>
                {renderModule}
            </>
        );
    }

    renderSingleImage() {
        const { state, props } = this,
            { ID } = props,
            currentSelectedMediaFiles = state.currentSelectedMediaFiles;
        let renderModule;

        if (currentSelectedMediaFiles.length > 0) {
            renderModule = (<div className="singleimage_selectedimages__DqWHRN" onClick={this.onClickOpenModule}>
                <div className="columns is-vcentered">
                    {
                        currentSelectedMediaFiles.map((_item, index) => {
                            return (
                                <div className="column is-7"
                                    key={`img-${_item.MediaFileID}-${index}`}>
                                    <div className={`flex-card light-bordered hover-raised mb-0`}>
                                        <div className="card-image div__cxjFwG">
                                            <a className="delete is-small" title={texts[3]} onClick={(event) => {
                                                event.stopPropagation();
                                                this.onCarouselRemoveItem({
                                                    item: _item,
                                                    index
                                                });
                                            }}></a>
                                            <figure className="image is-1by1">
                                                <MediaLibraryImg
                                                    path={_item.Path}
                                                    width={480}
                                                    height={480}
                                                />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>);
        } else {
            renderModule = (<div className="default__gNzfcP" onClick={this.onClickOpenModule}>
                <p className="instruction">{texts[0]}</p>
            </div>);
        }

        renderModule = (
            <div id={ID} className="lms_medialibrary_singleimage">
                {renderModule}
                {this.renderMediaFileListModal()}
            </div>
        );

        return renderModule;
    }

    renderCarousel() {
        const { state, props } = this,
            { ID } = props,
            currentSelectedMediaFiles = state.currentSelectedMediaFiles,
            carouselProps = {
                showThumbs: false,
                showIndicators: false,
                infiniteLoop: true,
                swipeable: true,
                emulateTouch: true,
                style: {
                    width: "50%"
                }
            };
        let renderModule;

        if (currentSelectedMediaFiles.length > 0) {
            renderModule = (
                <>
                    <DragDropContext onDragEnd={this.onCarouselListDragEnd} onDragStart={this.onCarouselListDragStart}>
                        <div id={`${ID}__DrPrJt`} className="carousel_selectedimages__SKpYkL" onClick={this.onClickOpenModule}>
                            {
                                <Droppable droppableId={`${ID}__drop`} direction="horizontal">
                                    {(provided, snapshot) => (
                                        <div className="columns is-vcentered"
                                            ref={provided.innerRef}>
                                            <>
                                                {
                                                    currentSelectedMediaFiles.map((_item, index) => {
                                                        return (
                                                            <Draggable
                                                                draggableId={`${ID}__${_item.MediaFileID}`}
                                                                index={index}
                                                                key={_item.MediaFileID}>
                                                                {(provided, snapshot) => (
                                                                    <div className="column is-2"
                                                                        ref={provided.innerRef}
                                                                        key={`img-${_item.MediaFileID}-${index}`}
                                                                        {...provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}>
                                                                        <div className={`flex-card light-bordered hover-raised mb-0`}>
                                                                            <div className="card-image div__cxjFwG">
                                                                                <a className="delete is-small" title={texts[3]} onClick={(event) => {
                                                                                    event.stopPropagation();
                                                                                    this.onCarouselRemoveItem({
                                                                                        item: _item,
                                                                                        index
                                                                                    });
                                                                                }}></a>
                                                                                <figure className="image is-1by1">
                                                                                    <MediaLibraryImg
                                                                                        path={_item.Path}
                                                                                        width={480}
                                                                                        height={480}
                                                                                    />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    })
                                                }
                                            </>
                                        </div>
                                    )}
                                </Droppable>
                            }
                        </div>
                    </DragDropContext>
                    <div style={{ width: "75%", margin: "0 auto", border: "2px solid #e9e9e9", marginTop: "1rem" }}>
                        <Carousel {...carouselProps}>
                            {
                                currentSelectedMediaFiles.map(currentSelectedMediaFile => {
                                    return (
                                        <div key={`mediafile-${currentSelectedMediaFile.MediaFileID}`}>
                                            <MediaLibraryImg
                                                path={currentSelectedMediaFile.Path}
                                                width={480}
                                                height={480}
                                            />
                                            {/* <p className="legend">{currentSelectedMediaFile.MediaFileID}</p> */}
                                        </div>
                                    )
                                })
                            }
                        </Carousel>
                    </div>
                </>
            );
        } else {
            renderModule = (<div className="empty_carousel__MyNuQB" onClick={this.onClickOpenModule}>
                <p className="instruction">{texts[0]}</p>
            </div>);
        }

        renderModule = (
            <div id={ID} className="lms_medialibrary_carousel">
                {renderModule}
                {this.renderMediaFileListModal()}
            </div>
        );

        return renderModule;
    }

    renderMediaFileListModal() {
        const { isSelectionContainerActive } = this.state;

        return (createPortal(<div className={`fs__keKwSq${isSelectionContainerActive ? ' is-active' : ''}`}>
            <div className="fs-cont__FyJBHb">
                <div className="cont__nNBZgv">
                    <div className="header__xQhpRL">
                        <a className="delete is-small" onClick={this.onClickCloseModule}></a>
                    </div>
                    <div className="body__xQhpRL">
                        {this.renderMediaFileList()}
                    </div>
                    <div className="footer__xQhpRL">
                        <div className="buttons">
                            <button className="button -primary-btn-lms is-bold" onClick={this.onClickSelect}>Select</button>
                            <button className="button btn-lms-nolayout is-bold" onClick={this.onClickCloseModule}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>, document.getElementById("root")))
    }

    renderCurrentSelectedMediaFiles() {
        const _this = this,
            state = _this.state,
            { currentSelectedMediaFiles } = state;
        let renderMedia;

        if (currentSelectedMediaFiles.length === 0) {
            return (
                <p className="instruction">{texts[0]}</p>
            );
        } else {
            renderMedia = (
                <div className="columns is-vcentered is-multiline">
                    {
                        currentSelectedMediaFiles.map((_item, index) => {

                            return (
                                <div className="column is-3" key={`img-${_item.MediaFileID}-${index}`}>
                                    <div className={`flex-card light-bordered hover-raised mb-0`}>
                                        <div className="card-image">
                                            <figure className="image is-1by1">
                                                <MediaLibraryImg
                                                    path={_item.Path}
                                                    width={480}
                                                    height={480}
                                                />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            );
        }

        return renderMedia;
    }

    renderDropZone() {
        const _this = this,
            state = _this.state,
            { isDropZoneLoading,
                isUploadBtnLoading,
                uploadListQueue,
                uploadProgressValue,
                allowMultiple, acceptedFilesStr } = state.dropZoneParams;
        let renderThumbnail,
            renderUploadBtnOrProgess;

        if (uploadListQueue.length > 0) {
            renderThumbnail = (
                (
                    <aside className={`columns is-vcentered is-multiline dropzonel__hXutsZ${isUploadBtnLoading ? ' is-disabled' : ''}`}>
                        {
                            uploadListQueue.map((_item, index) => {
                                return (
                                    <div className="column is-3" key={`thmbnl-${index}`}>
                                        <div className="thumbnail-inner">
                                            <figure className="image is-24x24">
                                                <img src={`${process.env.PUBLIC_URL} /images/icons/image.svg`} alt="" />
                                            </figure>
                                            <div className="thumbnail-detail">
                                                <span className="thumbnail-title">{_item.name}</span>
                                                <span className="thumbnail-size">{formatBytes(_item.size)}</span>
                                            </div>
                                            <a className="delete is-small" onClick={() => {
                                                _this.onClickRemoveFromUploadList(index);
                                            }}></a>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </aside>
                )
            );

            renderUploadBtnOrProgess = (
                <>
                    {
                        isUploadBtnLoading ? <progress className="progress is-small lms-progress" value={uploadProgressValue} max="100">{`${uploadProgressValue}%`}</progress> :
                            <button className={`button -primary-btn-lms is-bold${isUploadBtnLoading ? ' is-loading is-disabled' : ''}`} onClick={this.onClickUpload}><i className="fa fa-play-circle"></i>{texts[2]}</button>
                    }
                    <hr />
                </>
            )
        }

        return (
            <div className="sec__SrUygb">
                <Dropzone onDrop={_this.onDrop}>
                    {({ getRootProps, getInputProps }) => {

                        return (
                            <div {...getRootProps({ className: `dropzone oflow-x-auto${isDropZoneLoading ? ' is-disabled' : ''}` })}>
                                <input {...getInputProps({
                                    multiple: allowMultiple,
                                    accept: acceptedFilesStr
                                })} />
                                <p className="instruction">{texts[1]}</p>
                            </div>
                        )
                    }}
                </Dropzone>
                {renderThumbnail}
                {renderUploadBtnOrProgess}
            </div>
        )
    }

    renderMediaFileList() {
        const _this = this,
            state = _this.state,
            mediaFileList = state.mediaFileList,
            selectedMediaFiles = state.selectedMediaFiles;
        let containerClass = 'sec__nAnwBJ',
            renderMedia,
            renderDropZone;

        renderDropZone = _this.renderDropZone();

        if (mediaFileList.length === 0) {
            containerClass = `${containerClass} is-empty`;
        } else {
            renderMedia = (
                <div className="columns is-vcentered is-multiline">
                    {
                        mediaFileList.map((_item, index) => {
                            const mediaFileIndex = selectedMediaFiles.findIndex(_selectedMediaFile => {
                                return _selectedMediaFile.MediaFileID === _item.MediaFileID;
                            });

                            return (
                                <div className="column is-2" key={`img-${_item.Name}-${index}`}>
                                    <div className={`flex-card light-bordered hover-raised${mediaFileIndex !== -1 ? ' lms-badge' : ''}`}
                                        onClick={_this.onClickMediaFile.bind(_this, _item)}>
                                        <div className="card-image card-img__NxVgmq">
                                            <figure className="image is-1by1">
                                                <MediaLibraryImg
                                                    path={_item.Path}
                                                    width={480}
                                                    height={480}
                                                />
                                            </figure>
                                            <div className="div__bjXSvT" title="View" onClick={_this.onClickViewMedia.bind(_this, index)}>
                                                <i className="fa fa-eye" />
                                            </div>
                                        </div>
                                        <div className="card-img-description has-text-left">
                                            <span title={_item.Name}>{_item.Name}</span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            )
        }

        return (
            <>
                {renderDropZone}
                <div className={containerClass}>
                    {renderMedia}
                </div>
            </>
        );
    }

    renderFullScreen() {
        const state = this.state,
            selectedMedia = state.selectedMedia;
        let containerClassName = 'fs__BxtDXp',
            renderImg;

        if (selectedMedia) {
            const mediaFile = selectedMedia.mediaFile;

            containerClassName = `${containerClassName} is-active`;
            renderImg = (
                <MediaLibraryImg
                    path={mediaFile.Path}
                    type={mediaFile.MimeType}
                    isPlayable={true}
                />
            );
        }

        return (
            createPortal(
                <div id="popout-cont" className={containerClassName} onClick={this.onClickCloseViewMedia}>
                    {renderImg}
                </div>, document.getElementById("root"))
        );
    }

    onCarouselListDragStart(dragResult, announce) {
        this.setState({ canSelectionContainerActive: false });
    }

    onCarouselListDragEnd(dropResult, announce) {
        const { destination, source } = dropResult;

        if (destination && source) {
            const destinationIndex = destination.index,
                sourceIndex = source.index;
            if (destinationIndex != sourceIndex) {
                let { currentSelectedMediaFiles } = this.state;
                currentSelectedMediaFiles = JSON.parse(JSON.stringify(currentSelectedMediaFiles));
                const movedElement = currentSelectedMediaFiles[sourceIndex];

                currentSelectedMediaFiles.splice(sourceIndex, 1);
                currentSelectedMediaFiles.splice(destinationIndex, 0, movedElement);

                // [currentSelectedMediaFiles[destinationIndex], currentSelectedMediaFiles[sourceIndex]] = [currentSelectedMediaFiles[sourceIndex], currentSelectedMediaFiles[destinationIndex]];

                this.setState({
                    currentSelectedMediaFiles,
                    isSelectionContainerActive: false,
                    canSelectionContainerActive: true
                }, () => {
                    if (this.props.onCarouselChange) {
                        this.props.onCarouselChange({
                            currentSelectedMediaFiles,
                            LabelParams: { ...this.props.LabelParams }
                        })
                    }
                });
            }
        } else {
            this.setState({
                isSelectionContainerActive: false,
                canSelectionContainerActive: true
            });
        }
    }

    onCarouselRemoveItem(params) {
        const { item, index } = params,
            { currentSelectedMediaFiles } = this.state;
        let newCurrentSelectedMediaFiles = [];

        newCurrentSelectedMediaFiles = currentSelectedMediaFiles.filter(currentSelectedMediaFile => {
            return currentSelectedMediaFile.MediaFileID !== item.MediaFileID;
        });

        this.setState({
            currentSelectedMediaFiles: newCurrentSelectedMediaFiles
        }, () => {
            if (this.props.onCarouselChange) {
                this.props.onCarouselChange({
                    currentSelectedMediaFiles: newCurrentSelectedMediaFiles,
                    LabelParams: { ...this.props.LabelParams }
                });
            }
        });
    }

    onClickViewMedia(index, event) {
        const state = this.state,
            mediaFileList = state.mediaFileList;

        if (mediaFileList && mediaFileList[index]) {
            this.setState({
                selectedMedia: {
                    mediaFile: mediaFileList[index],
                    index: index
                }
            });
        }
    }

    onClickCloseViewMedia(event) {
        event.stopPropagation();

        if (event.target.id === "popout-cont") {
            this.setState({ selectedMedia: null });
        }
    }


    onClickSelect() {
        const selectedMediaFiles = this.state.selectedMediaFiles;

        this.setState({
            currentSelectedMediaFiles: JSON.parse(JSON.stringify(selectedMediaFiles)),
            isSelectionContainerActive: false
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.currentSelectedMediaFiles);
            }
            if (this.props.onCarouselChange) {
                this.props.onCarouselChange({
                    currentSelectedMediaFiles: this.state.currentSelectedMediaFiles,
                    LabelParams: { ...this.props.LabelParams }
                });
            }
        });
    }

    onClickMediaFile(mediaFile, event) {
        const eventClassName = event.target.className;

        if (!(eventClassName == "fa fa-eye" || eventClassName == "div__bjXSvT")) {
            let selectedMediaFiles = this.state.selectedMediaFiles,
                mediaFileIndex = selectedMediaFiles.findIndex(_selectedMediaFile => {
                    return _selectedMediaFile.MediaFileID === mediaFile.MediaFileID;
                });

            if (mediaFileIndex === -1) {
                if (selectedMediaFiles.length < this.props.maxSelection) {
                    selectedMediaFiles.push(mediaFile);
                } else {
                    selectedMediaFiles.splice(0, 1);
                    selectedMediaFiles.push(mediaFile);
                }
            } else {
                selectedMediaFiles.splice(mediaFileIndex, 1);
            }

            this.setState({ selectedMediaFiles });
        }
    }

    onClickOpenModule(event) {
        event.stopPropagation();

        if (this.state.canSelectionContainerActive) {
            this.setState({
                isSelectionContainerActive: true
            }, () => {
                if (!this.state.isMediaFileListInitialized) {
                    this.moduleQuery({
                        type: 0
                    });
                    this.moduleQuery({
                        type: 1
                    });
                }
            });
        }
    }

    onClickCloseModule() {
        const currentSelectedMediaFiles = this.state.currentSelectedMediaFiles;

        this.setState({
            isSelectionContainerActive: false,
            selectedMediaFiles: JSON.parse(JSON.stringify(currentSelectedMediaFiles)),
            counter: this.state.counter ? ++this.state.counter : 1
        });
    }

    onDrop(acceptedFiles) {
        const state = this.state;
        let { dropZoneParams } = state,
            { uploadListQueue, acceptedFilesStr } = dropZoneParams,
            shouldUpdateState = false;

        if (acceptedFilesStr && acceptedFiles) {
            acceptedFiles.forEach(_acceptedFile => {
                let acceptedFileType = _acceptedFile.type;

                if (acceptedFileType.length > 0 && acceptedFilesStr.includes(acceptedFileType)) {
                    if (uploadListQueue.findIndex(_item => {
                        return _item.name === _acceptedFile.name;
                    }) === -1) {
                        uploadListQueue.push(_acceptedFile);
                        shouldUpdateState = true;
                    }
                }
            });
        }

        if (shouldUpdateState) {
            dropZoneParams = {
                ...dropZoneParams,
                uploadListQueue
            };
            this.setState(dropZoneParams);
        }
    }

    onClickUpload() {
        const _this = this,
            state = _this.state,
            { uploadListQueue } = state.dropZoneParams;
        let newState = {};

        if (uploadListQueue && uploadListQueue.length > 0) {
            _this.moduleQuery({
                type: 2,
                baseURL: MEDIA_LIB_URL
            });
        }
    }

    onClickRemoveFromUploadList(index) {
        let dropZoneParams = this.state,
            { uploadListQueue } = dropZoneParams;

        if (uploadListQueue) {
            uploadListQueue.splice(index, 1);
            dropZoneParams['uploadListQueue'] = uploadListQueue;

            this.setState(dropZoneParams);
        }
    }

    moduleQuery(params) {
        const _this = this,
            props = _this.props,
            state = _this.state,
            type = params.type;
        let Parameters = {},
            requestjson = {},
            config = {
                Data: { requestjson },
                Method: "GET",
                ResponseSuccessCallback: responseSuccessCallback,
                ResponseFailCallback: responseFailCallback,
                Url: "/single_api/"
            },
            newState = {},
            formData = new FormData();

        switch (type) {
            case 0: {
                requestjson.Module = props.module;
                Parameters = props.parameters;
                break;
            } case 1: {
                requestjson.Module = "medialibrary_page_initialization";
                Parameters = {};
                break;
            } case 2: {
                let { dropZoneParams } = state,
                    { uploadListQueue } = dropZoneParams;

                config['BaseURL'] = params.baseURL;
                config['Method'] = 'POST';
                config['Url'] = '/medialibrary';
                config['OnUploadProgress'] = (progressEvent) => {
                    let uploadProgressValue = (progressEvent.loaded / progressEvent.total) * 100,
                        { dropZoneParams } = _this.state;

                    if (uploadProgressValue >= 98) {
                        --uploadProgressValue;
                    }

                    dropZoneParams['uploadProgressValue'] = uploadProgressValue;

                    _this.setState(dropZoneParams);
                };

                uploadListQueue.forEach(_item => {
                    formData.append('Files', _item);
                })
                config.Data.requestjson = formData;

                dropZoneParams['isDropZoneLoading'] = true;
                dropZoneParams['isUploadBtnLoading'] = true;
                newState['dropZoneParams'] = dropZoneParams;
                break;
            }
        }

        if (Object.keys(newState).length > 0) {
            _this.setState(newState, () => {
                processRequest();
            });
        } else {
            processRequest();
        }

        function processRequest() {
            requestjson.Parameters = Parameters;
            newState = {};
            config = genericQuery(config);
        }

        function responseSuccessCallback(responseJson) {
            let data = responseJson.data,
                status = data.Status;
            newState = {};

            if (status.IsSuccess) {
                if (data) {
                    let _data = data.Data,
                        mediaFileList = _data.MediaFileList,
                        mediaDefinitionList = _data.MediaDefinitionList,
                        fileTypeList = _data.FileTypeList;

                    mediaFileList = mediaFileList ? mediaFileList : [];
                    mediaDefinitionList = mediaDefinitionList ? mediaDefinitionList : [];
                    fileTypeList = fileTypeList ? fileTypeList : [];

                    switch (type) {
                        case 0: {
                            newState['mediaFileList'] = mediaFileList;
                            newState['isMediaFileListInitialized'] = true;
                            break;
                        } case 1: {
                            let acceptedFiles = [],
                                acceptedFilesStr = "",
                                { dropZoneParams } = _this.state,
                                fileTypeID;

                            if (props.parameters && props.parameters.FileTypeID)
                                fileTypeID = props.parameters.FileTypeID;

                            acceptedFiles = mediaDefinitionList.map(_mediaDefinition => {
                                if (fileTypeID) {
                                    if (_mediaDefinition.FileTypeID == fileTypeID) {
                                        return _mediaDefinition.MimeType;
                                    }
                                } else
                                    return _mediaDefinition.MimeType;
                            });

                            if (acceptedFiles) {
                                acceptedFilesStr = acceptedFiles.toString();

                                dropZoneParams = {
                                    ...dropZoneParams,
                                    acceptedFilesStr: acceptedFilesStr,
                                    acceptedFilesList: acceptedFiles
                                };
                            }

                            dropZoneParams = {
                                ...dropZoneParams,
                                fileTypeList,
                                allowMultiple: _data.AllowMultiple,
                                isDropZoneLoading: false
                            };

                            newState['dropZoneParams'] = dropZoneParams;

                            break;
                        } case 2: {
                            let { dropZoneParams } = state,
                                _mediaFileList = state.mediaFileList;

                            _mediaFileList.splice(0, 0, ...mediaFileList);

                            dropZoneParams['isDropZoneLoading'] = false;
                            dropZoneParams['isUploadBtnLoading'] = false;
                            dropZoneParams['uploadProgressValue'] = 0;
                            dropZoneParams['uploadListQueue'] = [];

                            newState['dropZoneParams'] = dropZoneParams;
                            newState['mediaFileList'] = _mediaFileList;

                            break;
                        }
                    }
                }
            } else {
                iziToastFn({
                    mode: 3,
                    title: "Error",
                    message: status.Message
                });
            }

            _this.setState(newState);
        }

        function responseFailCallback(responseJson) {
            console.log("responseFailCallback", responseJson);

            if (responseJson.message !== "change request") {
                iziToastFn({
                    mode: 3,
                    title: "Error",
                    message: responseJson.message
                });
            }

            switch (params.type) {
                case 1:
                    break;
                case 2:
                    break;
                case 3:
                    break;
                case 4:
                    break;
            }
        }
    }

    componentDidMount() {
        let selectedMediaFiles = this.state.selectedMediaFiles,
            currentSelectedMediaFiles = this.state.currentSelectedMediaFiles,
            value = this.props.value;

        if (value) {
            if (Array.isArray(value)) {
                selectedMediaFiles = value;
            } else {
                selectedMediaFiles.push(value);
            }

            currentSelectedMediaFiles = JSON.parse(JSON.stringify(selectedMediaFiles));

            this.setState({ selectedMediaFiles, currentSelectedMediaFiles });
        }
    }

    static propTypes = {
        id: PropTypes.string,
        maxSelection: PropTypes.number,
        minSelection: PropTypes.number,
        module: PropTypes.string,
        parameters: PropTypes.object,
        'data-module': PropTypes.string,
        'data-moduletemplateattribute': PropTypes.string,
        'data-ref': PropTypes.string,
        onChange: PropTypes.func
    }

    static defaultProps = {
        id: 'medialibrary-module',
        maxSelection: 1,
        minSelection: 0,
        module: 'medialibrary_mediafile_getlist',
        parameters: {},
        'data-module': '',
        'data-moduletemplateattribute': '',
        'data-ref': '',
        onChange: null
    }
}

export { MediaLibraryFormModule }