import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { getToken } from "../helpers/localStorage";

import BulkitPrivateRoute from "./bulkit/privateRoute";
import BulkitPublicRoute from "./bulkit/publicRoute";
import BulkitNeutralRoute from "./bulkit/neutralRoute";
import BulkitIframeRoute from "./bulkit/iframeRoute";

import { LoadingBounceLoader_Absolute } from "../component/imports/";
import CourseCatalogDetail from "../component/pages/_coursecatalog/_catalog_list/user/detail";


const test = lazy(() => import('./test')),
    Home = lazy(() => import('../component/pages/_home/index')),
    ContentViewer = lazy(() => import('../component/pages/_viewer/index')),
    CourseDetail = lazy(() => import('../component/pages/_courses/detail')),
    Courses = lazy(() => import('../component/pages/_courses/list')),
    Enrollments = lazy(() => import('../component/pages/_enrollments/index')),
    H5PCreate = lazy(() => import('../component/pages/_h5p/create')),
    H5PList = lazy(() => import('../component/pages/_h5p/list')),
    H5PViewer = lazy(() => import('../component/pages/_h5p/viewer')),
    Login = lazy(() => import('../component/pages/_login/index')),
    Logout = lazy(() => import('../component/pages/_logout/index')),
    MediaLibrary = lazy(() => import('../component/pages/_media-library/index')),
    ModuleDetail = lazy(() => import('../component/pages/_modules/detail')),
    ModulesList = lazy(() => import('../component/pages/_modules/list')),
    ModulesViewer = lazy(() => import('../component/pages/_modules/viewer')),
    NewsList = lazy(() => import('../component/pages/_news/list')),
    PublicNewsList = lazy(() => import('../component/pages/_news/public_list')),
    NewsCreate = lazy(() => import('../component/pages/_news/create')),
    NewsDetail = lazy(() => import('../component/pages/_news/detail')),
    NewsView = lazy(() => import('../component/pages/_news/view')),
    NoMatch = lazy(() => import('../component/pages/_404/index')),
    Profile = lazy(() => import('../component/pages/_profile/index')),
    ScheduledTrainings = lazy(() => import('../component/pages/_training-scheduled/index')),
    ScheduledTrainingsCreate = lazy(() => import('../component/pages/_training-scheduled/create')),
    ScheduledTrainingDetails = lazy(() => import('../component/pages/_training-scheduled/details')),
    ScormViewer = lazy(() => import('../component/pages/_scorm/index')),
    SurveyCreate = lazy(() => import('../component/pages/_survey/create')),
    SurveyList = lazy(() => import('../component/pages/_survey/list')),
    Catalog_Admin = lazy(() => import('../component/pages/_coursecatalog/_catalog_list/admin/list')),
    Catalog_User = lazy(() => import('../component/pages/_coursecatalog/_catalog_list/user/list')),
    Catalog_Detail_Admin = lazy(() => import('../component/pages/_coursecatalog/_catalog_list/admin/detail')),
    CourseTake = lazy(() => import('../component/pages/_coursecatalog/_course-take/detail')),
    CourseTakeViewer = lazy(() => import('../component/pages/_coursecatalog/viewer/index')),
    CourseCategoryList = lazy(() => import('../component/pages/_course_categories/index')),
    LearningPath = lazy(() => import('../component/pages/_learning_paths/index')),
    LearningPathDetail = lazy(() => import('../component/pages/_learning_paths/detail')),
    CMS_Home = lazy(() => import('../component/pages/_cms/_home')),
    CMS_TrainingCalendar = lazy(() => import('../component/pages/_cms/_training_calendar')),
    LabelTranslation_List = lazy(() => import('../component/pages/_label_translation'));

function AppRouter() {
    return (
        <BrowserRouter>
            <Suspense fallback={<LoadingBounceLoader_Absolute />}>
                <Switch>
                    <BulkitNeutralRoute path="/login" exact component={Login} />
                    <BulkitNeutralRoute path="/logout" exact component={Logout} />

                    <BulkitPublicRoute path="/home" exact component={Home} />

                    <BulkitPublicRoute path="/profile" exact component={Profile} isLoginRequired={true} />

                    <BulkitPrivateRoute path="/admin/courses" exact component={Courses} />
                    <BulkitPrivateRoute path="/admin/courses/view/:id" exact component={Courses} />
                    <BulkitPrivateRoute path="/admin/courses/:id" exact component={CourseDetail} />

                    <BulkitPrivateRoute path="/admin/catalog" exact component={Catalog_Admin} />
                    <BulkitPublicRoute path="/catalog" exact component={Catalog_User} isLoginRequired={true} />
                    <BulkitPublicRoute path="/catalog/:id" exact component={CourseCatalogDetail} isLoginRequired={true} />

                    <BulkitPrivateRoute path="/admin/catalog/:id" exact component={Catalog_Detail_Admin} />

                    <BulkitPublicRoute path="/course-take/:id" exact component={CourseTake} isLoginRequired={true} />
                    <BulkitNeutralRoute path="/course-take/module-viewer/:id" exact component={CourseTakeViewer} />
                    <BulkitNeutralRoute path="/course-take/course-viewer/:id" exact component={CourseTake} />
                    <BulkitNeutralRoute path="/course-take/survey-viewer/:id" exact component={CourseTakeViewer} />
                    <BulkitNeutralRoute path="/test" exact component={test} />

                    <BulkitPrivateRoute path="/admin/learning_paths" exact component={LearningPath} />
                    <BulkitPrivateRoute path="/admin/learning_paths/:id" exact component={LearningPathDetail} />

                    <BulkitPrivateRoute path="/admin/enrollments" exact component={Enrollments} />

                    <BulkitPrivateRoute path="/admin/course-categories" exact component={CourseCategoryList} />

                    <BulkitPrivateRoute path="/admin/course_builder/create/" exact component={H5PCreate} />
                    <BulkitPrivateRoute path="/admin/course_builder/list/" exact component={H5PList} />
                    <BulkitPrivateRoute path="/admin/course_builder/viewer/" exact component={H5PViewer} />

                    <BulkitPrivateRoute path="/admin/media-library" exact component={MediaLibrary} />

                    <BulkitPrivateRoute path="/admin/modules" exact component={ModulesList} />
                    <BulkitPrivateRoute path="/admin/modules/viewer/:id" exact component={ModulesViewer} />
                    <BulkitPrivateRoute path="/admin/modules/:id" exact component={ModuleDetail} />

                    <BulkitPrivateRoute path="/admin/news" exact component={NewsList} isLoginRequired={true} />
                    <BulkitPrivateRoute path="/admin/news/create" exact component={NewsCreate} isLoginRequired={true} />
                    <BulkitPrivateRoute path="/admin/news/:id" exact component={NewsDetail} isLoginRequired={true} />
                    <BulkitPublicRoute path="/news" exact component={PublicNewsList} />
                    <BulkitPublicRoute path="/news/:id" exact component={NewsView} />

                    <BulkitPrivateRoute path="/admin/scheduled-trainings" exact component={ScheduledTrainings} />
                    <BulkitPrivateRoute path="/admin/scheduled-trainings/create" exact component={ScheduledTrainingsCreate} />
                    <BulkitPrivateRoute path="/admin/scheduled-trainings/:id" exact component={ScheduledTrainingDetails} />

                    <BulkitPrivateRoute path="/admin/scorm/" exact component={ScormViewer} />
                    <BulkitPrivateRoute path="/admin/content/viewer/" exact component={ContentViewer} />

                    <BulkitPrivateRoute path="/admin/survey/create/" exact component={SurveyCreate} />
                    <BulkitPrivateRoute path="/admin/survey/list/" exact component={SurveyList} />

                    <BulkitPrivateRoute path="/admin/cms/home" exact component={CMS_Home} isLoginRequired={true} />
                    <BulkitPrivateRoute path="/admin/cms/training_calendar" exact component={CMS_TrainingCalendar} isLoginRequired={true} />
                    <BulkitPrivateRoute path="/admin/cms/translate_label" exact component={LabelTranslation_List} isLoginRequired={true} />

                    <BulkitIframeRoute path="/iframe/profile" exact component={Profile} isTokenRequired={true} />

                    <Route path="/admin" exact
                        render={() =>
                            getToken() ? <Redirect to="/admin/dashboard" /> : <Redirect to="/home" />
                        }
                    />

                    <BulkitPublicRoute path="/" exact component={Home} />
                    <Route path="/404" component={NoMatch} />
                    <Route path="*" component={NoMatch} />
                </Switch>
            </Suspense>
        </BrowserRouter>
    );
}

export default AppRouter;
