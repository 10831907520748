export const env = process.env,
    config = {
        APP_URL: env.REACT_APP_URL,
        API_URL: env.REACT_APP_API_URL,
        API_MEDIA_URL: env.REACT_APP_API_MEDIA_URL,
        MEDIA_LIB_URL: env.REACT_APP_MEDIA_LIB_URL,
        MEDIA_URL: env.REACT_APP_MEDIA_URL,
        H5PBUILDER_URL: env.REACT_APP_H5PBUILDER_URL,
        H5P_URL: env.REACT_APP_H5P_URL,
        HRS_URL: env.REACT_APP_HRS_URL,
        SOCKET_URL: env.REACT_APP_SOCKET_URL,
        SINGLE_API_URL: env.REACT_APP_SINGLE_API_URL,
        SINGLE_API_URL1: env.REACT_APP_SINGLE_API_URL1
    },
    API_URL = config.API_URL,
    API_URL_MEDIA = config.API_MEDIA_URL,
    H5P_BUILDER_URL = config.H5PBUILDER_URL,
    MEDIA_URL = config.MEDIA_URL,
    LMS_URL = config.APP_URL,
    SITE_URL = config.APP_URL,
    H5P_URL = config.H5P_URL,
    MEDIA_LIB_URL = config.MEDIA_LIB_URL,
    MEDIA_LIB_URL_GETTER = `${MEDIA_LIB_URL}/medialibrary`,
    HRS_SITE = config.HRS_URL,
    FLAG_IMG_URL = `${config.APP_URL}/images/flags/4x3`;