import Axios from "axios"
import React, { PureComponent, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { connect, useDispatch, useSelector } from "react-redux"
import Select from 'react-select'
import { closeModal, genericQuery, GetLabel, GetLabels, openModal, select2GetData, select2Initialize, select2IsSelect2, select2SetSelectedData } from "../../../helpers/helper"
import { getEmployeeLanguage } from "../../../helpers/localStorage"
import { globalActions } from "../../../redux/actions/globalVariables"
import "./index.scss"
let LABELS = [
    "Close", //0
]

const $ = window.$;

const cancelToken = {}
export const LabelTranlsationToggle = (props) => {
    let reduxStore = useSelector(state => ({
        globalVariables: state.globalVariables
    }))

    const dispatch = useDispatch()

    return <div className="field">
        <input id="switch-toggle-editMode-labelTranslation" type="checkbox"
            name="switch-toggle-editMode-labelTranslation"
            className="switch is-small" defaultChecked={reduxStore.globalVariables.isLabelEditMode}
            onChange={(e) => {
                if ($('#switch-toggle-editMode-labelTranslation').is(":checked")) {
                    dispatch(globalActions.setLabelEditMode(true))
                } else {
                    dispatch(globalActions.setLabelEditMode(false))
                }
            }}
        />
        <label htmlFor="switch-toggle-editMode-labelTranslation">Enable Edit Mode to labels</label>
    </div>
}
class LabelLanguageEdit extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            Settings: {
                isLoading: true,
                isLanguageListLoading: true,
            },
            SelectedLanguageID: 0,
            LabelToEdit: {},
            data: {
                options: []
            },
        }

        this.LanguageID = this.props.globalVariables.SelectedLabel.LabelLanguageID
        this.LabelID = this.props.globalVariables.SelectedLabel.LabelID
        this.LanguageName = this.props.globalVariables.SelectedLabel.LanguageName
        this.Label = this.props.globalVariables.SelectedLabel.Label

        cancelToken.GetLabelToken = Axios.CancelToken.source()
        cancelToken.GetLanguageList = Axios.CancelToken.source()

        this.GetLabel = this.GetLabel.bind(this)
        this.GetLanguageList = this.GetLanguageList.bind(this)
        this.onBlurTranslationEditAndAdd = this.onBlurTranslationEditAndAdd.bind(this)
    }
    componentDidMount() {
        this.GetLanguageList()
        openModal("modal-LabelLanguageEdit")

    }
    handleChange = (event) => {
        const _this = this, state = _this.state,
            value = event.value
        this.setState({
            SelectedLanguageID: value,
            Settings: { ...state.Settings, isLoading: true }
        }, () => {
            _this.GetLabel({ LabelIDs: [_this.LabelID], LanguageID: value })
        })
    }

    GetLabel = (params = { LanguageID: null, LabelIDs: [] }) => {
        const _this = this, state = _this.state, _props = _this.props
        let { LanguageID, LabelIDs } = params
        // 
        let requestjson = {
            Module: "cms_labels_details",
            Parameters: {
                LabelIDs,
                LanguageID
            }
        },
            config = {
                CancelToken: cancelToken.GetLabelToken,
                Data: { requestjson },
                Method: "GET",
                ResponseSuccessCallback: responseSuccessCallback,
                ResponseFailCallback: responseFailCallback,
                Url: "/single_api/"
            };

        cancelToken.GetLabelToken.cancel("change request");

        genericQuery(config);
        cancelToken.GetLabelToken = config.CancelToken;
        function responseSuccessCallback(responseJson) {
            console.log("responseSuccessCallback", responseJson);
            // setSettings({ ...Settings, isLoading: false })
            let data = responseJson.data, _data = data.Data.Labels, status = data.Status

            if (status.IsSuccess) {
                _this.setState({ LabelToEdit: _data[0], Settings: { ...state.Settings, isLoading: false } }, () => {
                    let Label = "", language = searchLanguage(LanguageID, _data[0].Languages)

                    if (language) {
                        Label = language.dataFound.Label
                    } else {
                        Label = ""
                    }
                    $(`#translation-Edit`).val(Label)
                })
            }
        }
        function responseFailCallback(responseJson) {
            console.log("responseFailCallback", responseJson);

        }
    }
    GetLanguageList = () => {
        const _this = this, state = _this.state
        let requestjson = {
            Module: "generic_lmslanguage_list",
            Parameters: {}
        },
            config = {
                CancelToken: cancelToken.GetLanguageList,
                Data: { requestjson },
                Method: "GET",
                ResponseSuccessCallback: responseSuccessCallback,
                ResponseFailCallback: responseFailCallback,
                Url: "/single_api/"
            };

        cancelToken.GetLanguageList.cancel("change request");
        genericQuery(config);
        cancelToken.GetLanguageList = config.CancelToken;
        function responseSuccessCallback(responseJson) {
            console.log("responseSuccessCallback", responseJson);
            let data = responseJson.data.Data, _data = data.LMSLanguage, selectData = [], options = []


            $("#select-lmslanguage").val(_this.LanguageID).trigger("change")

            _this.setState({
                Settings: {
                    ...state.Settings,
                    isLanguageListLoading: false

                },
                SelectedLanguageID: _this.LanguageID,
                data: { ...state.data, options }
            }, () => {
                _this.GetLabel({ LabelIDs: [_this.LabelID], LanguageID: _this.LanguageID })
            })

        }
        function responseFailCallback(responseJson) {
            console.log("responseFailCallback", responseJson);

        }
    }
    onBlurTranslationEditAndAdd(e) {
        e.preventDefault()
        const _this = this
        let value = e.target.value

        let requestjson = {
            Module: "cms_labels_add-language",
            Parameters: {
                LabelID: this.LabelID,
                LanguageID: this.state.SelectedLanguageID,
                Label: value
            }
        },
            config = {
                CancelToken: cancelToken.CourseCategoryList,
                Data: { requestjson },
                Method: "GET",
                ResponseSuccessCallback: responseSuccessCallback,
                ResponseFailCallback: responseFailCallback,
                Url: "/single_api/"
            };

        genericQuery(config);
        function responseSuccessCallback(responseJson) {
            console.log("responseSuccessCallback", responseJson);
            let data = responseJson.data, status = data.Status

            if (status.IsSuccess) {
                if (requestjson.Parameters.LanguageID === JSON.parse(getEmployeeLanguage()).LanguageID) {
                    GetLabels({ LabelIDs: [_this.LabelID] })
                    $(`#${_this.LabelID}`).text(value)
                }
            }


        }
        function responseFailCallback(responseJson) {
            console.log("responseFailCallback", responseJson);
        }

    }


    render() {
        const _this = this, state = _this.state, _props = _this.props
        console.log("translation rerender")
        let { SelectedLanguageID, Settings, LabelToEdit } = state
        let labelToEdit = "", IsLabelExists, Label = ""

        if (Object.keys(LabelToEdit).length > 0) {
            let TargetLanguage = searchLanguage(SelectedLanguageID, LabelToEdit.Languages)

            if (TargetLanguage) {
                Label = TargetLanguage.dataFound.Label
                IsLabelExists = true
            } else {
                IsLabelExists = false
            }
        }

        function renderTranslation() {
            if (SelectedLanguageID) {
                if (IsLabelExists) {
                    return <React.Fragment>
                        <div className="control">
                            <label className="label">Edit Translation:</label>
                            <div className={`control is-small ${Settings.isLoading ? "is-loading is-disabled" : ""}`}>
                                <input id="translation-Edit" className={`input is-small`} defaultValue={Label} onBlur={_this.onBlurTranslationEditAndAdd} />
                            </div>
                        </div>
                    </React.Fragment>
                } else {
                    return <React.Fragment>
                        <div className="control">
                            <label className="label">Current Label Selected:</label>
                            <div className={`control is-small ${Settings.isLoading ? "is-loading is-disabled" : ""}`}>
                                <input className={`input is-small`} value={_this.Label} readOnly />
                            </div>
                        </div>
                        <div className={`control`}>
                            <label className="label">New Translation:</label>
                            <div className={`control is-small ${Settings.isLoading ? "is-loading is-disabled" : ""}`}>
                                <input className={`input is-small `} placeholder="New Translation" onBlur={_this.onBlurTranslationEditAndAdd} />
                            </div>
                        </div>
                    </React.Fragment>
                }
            }

        }
        return createPortal(<div id={`modal-LabelLanguageEdit`} className="modal modal-md -lms-modal-primary">
            <div className="modal-background is-translucent"></div>
            <div className="modal-content" id="sss">
                <div className="flex-card simple-shadow oflow-unset">
                    <header className="card-header">
                        <p className="card-header-title">
                            <span>{`Label Translation`}</span>
                            <button className={`button -red-btn-lms pos-absolute`} style={{ right: "5px" }}
                                onClick={(e) => {
                                    e.preventDefault()

                                    closeModal(`modal-LabelLanguageEdit`)

                                    cancelToken.GetLanguageList.cancel("cancel request");
                                    cancelToken.GetLabelToken.cancel("cancel request");


                                    setTimeout(() => {
                                        this.props.setSelectedLabel({})
                                        select2SetSelectedData({
                                            id: `select-lmslanguage`,
                                            data: null,
                                        })
                                        _this.setState({
                                            Settings: {
                                                isLoading: true
                                            },
                                            SelectedLanguageID: 0,
                                            LabelToEdit: {}
                                        })
                                    }, 1000)
                                }}>Close</button>
                        </p>
                    </header>
                    <div className="card-body" >
                        <div className="content">
                            <div id="lms-language-list" className="control w-p-100">
                                <label htmlFor="select-lmslanguage" className="label">Language:</label>
                                {/* <select id="select-lmslanguage"
                                    className={`input is-medium w-px-300 `}
                                    name="select-lmslanguage">
                                    <option></option>
                                </select> */}
                                <Select options={state.data.options}
                                    menuPortalTarget={document.getElementById("sss")}
                                    menuPosition={'portal'}
                                    defaultValue={{ label: this.LanguageName, value: this.LanguageID }}
                                    onChange={_this.handleChange}
                                    isLoading={state.Settings.isLanguageListLoading}
                                    styles={{
                                        menu: provided => ({
                                            ...provided,
                                            top: 145,
                                            width: "calc(100% - 60px)",
                                            left: 30,
                                            minWidth: "unset"
                                        })
                                    }}
                                />
                            </div>
                            {renderTranslation()}
                        </div>
                    </div>
                </div>
            </div>

        </div>, document.getElementById("root"))
    }


}
const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: isDisabled ? 'red' : "blue",
            color: '#FFF',
            cursor: isDisabled ? 'not-allowed' : 'default'
        }
    },
}

const searchLanguage = (nameKey, myArray) => {
    for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].LanguageID === nameKey) {
            return { dataFound: myArray[i], indexLocated: i };
        }
    }
}

const mapDispatchToProps = dispatch => ({
    setSelectedLabel: (data) => dispatch(globalActions.setSelectedLabel(data))


})

const mapStateToProps = state => ({
    globalVariables: state.globalVariables
})

export default connect(mapStateToProps, mapDispatchToProps)(LabelLanguageEdit)
