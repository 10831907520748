import React, { Component } from 'react';
import { socket, getEmployeeID } from './helper';

class SocketIO extends Component {
    constructor(props) {
        super(props);

        this.state = {
            operations: []
        };

        this.refreshSocket = this.refreshSocket.bind(this);
    }

    refreshSocket() {
        if (socket && getEmployeeID()) {
            socket.on('disconnect', function () {
                console.log('disconnected');

                socket.emit('subscribe', {
                    roomid: 1,
                    user: getEmployeeID()
                });
            }.bind(this));

            socket.emit('subscribe', {
                roomid: 1,
                user: getEmployeeID()
            });
        }
    }

    componentDidMount() {
        this.refreshSocket();
    }

    render() {
        return (
            <>
            </>
        );
    }
}

export {
    SocketIO
};