import { GetSystemSettings } from "../../helpers/helper";

const systemSettingsDefaultState = {
   
},
    SystemSettingsReducer = (state = systemSettingsDefaultState, action) => {
     
        switch (action.type) {
            case 'MODIFY_SYSTEMSETTINGS_ITEM': {
                const { NewProps } = action.payload;
                
                return {
                    ...state,
                    ...NewProps
                }
            }
            default:
                return state;
        }
    }

const modifySystemSettingsItem = (data) => dispatch => {
    dispatch({
        type: 'MODIFY_SYSTEMSETTINGS_ITEM',
        payload: data
    });
};

export {
    SystemSettingsReducer,
    modifySystemSettingsItem
};