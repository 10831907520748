import React from 'react';
import Pagination from 'react-js-pagination';
import PropTypes from "prop-types";
require('./index.scss');

export default class LMS_Pagination extends React.Component {
    static propTypes = {
        activePage: PropTypes.number,
        itemsCountPerPage: PropTypes.number,
        totalItemsCount: PropTypes.number,
        pageRangeDisplayed: PropTypes.number,
        onChange: PropTypes.func,
        appendInnerClass: PropTypes.string
    }

    render() {
        const { activePage, itemsCountPerPage, totalItemsCount, pageRangeDisplayed, onChange, appendInnerClass } = this.props,
            innerClass = 'lms-pagination';
            
        return (
            <div className="ta-center">
                <Pagination
                    activePage={activePage}
                    itemsCountPerPage={itemsCountPerPage}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={pageRangeDisplayed ? pageRangeDisplayed : 5}
                    onChange={onChange}
                    hideDisabled
                    innerClass={`${innerClass}${appendInnerClass ? ` ${appendInnerClass}` : ""}`}
                    disabledClass="is-disabled"
                />
            </div>
        )
    }
}