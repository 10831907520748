

export const setLabels = (data) => dispatch => {
    dispatch({
        type: 'GLOBAL-SET-LABELS',
        payload: data
    })
}, setSelectedLabel = (data) => dispatch => {
    dispatch({
        type: 'GLOBAL_LABEL-SET_SELECTED',
        payload: data
    })
}, setLabelEditMode = (data) => dispatch => {
    dispatch({
        type: 'GLOBAL_LABEL-SET_EDIT_MODE',
        payload: data
    })
}

const globalActions = {
    setLabels,
    setSelectedLabel,
    setLabelEditMode
};

export {
    globalActions
};