import React from 'react';
import { Link } from 'react-router-dom';
import { HeaderNotification } from '../../../../imports';

export default class Navbar extends React.Component {

    constructor() {
        super();

        this.state = {
            showMobileNav: false
        };

        this.onClickMobileDashboardTrigger = this.onClickMobileDashboardTrigger.bind(this);
    }

    render() {
        const { showMobileNav } = this.state;
        let mobileNavClassName = "lms_header__EfdYnS mobile-nav is-hidden-desktop",
            iconBoxClassName = "icon-box-toggle";

        if (showMobileNav) {
            mobileNavClassName = `${mobileNavClassName} is-active`;
            iconBoxClassName = `${iconBoxClassName} active`;
        }

        return (
            <nav className="navbar dashboard-nav has-shadow">
                <div className="container is-fluid">
                    <div className="navbar-brand">
                        <a id="mobile-dashboard-trigger" className="navbar-item hamburger-btn is-hidden-desktop" onClick={this.onClickMobileDashboardTrigger}>
                            <span className="menu-toggle">
                                <span className={iconBoxClassName}>
                                    <span className="rotate">
                                        <i className="icon-line-top"></i>
                                        <i className="icon-line-center"></i>
                                        <i className="icon-line-bottom"></i>
                                    </span>
                                </span>
                            </span>
                        </a>
                    </div>
                    <div className="navbar-menu">
                        <div className="navbar-end">
                            {<HeaderNotification />}
                        </div>
                    </div>
                </div>
            </nav>
        );
    }

    onClickMobileDashboardTrigger() {
        const { showMobileNav } = this.state;

        this.setState({ showMobileNav: !showMobileNav });
    }
}