import React from 'react';
import { getToken } from '../../helpers/localStorage';
import { Route, Redirect } from 'react-router-dom';
import { BulkitIframe } from '../../component/layouts/bulkit/index';

export const BulkitIframeRoute = ({ component: Component, ...rest }) => {
    let urlParams = new URLSearchParams(rest.location.search),
        hrsToken = urlParams.get('hrs_token'),
        returnData = (
            <Route
                {...rest}
                render={props =>
                    <BulkitIframe
                        Component={Component}
                        {...props}
                        IsIframe={true}
                    />
                }
            />
        );

    if (rest.isTokenRequired) {
        if (!hrsToken) {
            returnData = (
                <Redirect to="/404" />
            );
        }
    }

    return returnData;
}

export default BulkitIframeRoute;