import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { BulkitPublic } from '../../component/layouts/bulkit/index';
import { getToken } from '../../helpers/localStorage';
import { routePaths } from '../../helpers/helper';

export const BulkitPublicRoute = ({ component: Component, ...rest }) => {
    let pathName = window.location.pathname,
        returnData = (
            <Route
                {...rest}
                render={props =>
                    (
                        <BulkitPublic
                            Component={Component}
                            {...props}
                        />
                    )
                }
            />
        );

    if (rest.isLoginRequired) {
        if (!getToken()) {
            returnData = (
                <Redirect to='/' />
            )
        }
    }
    if (pathName === "/home" || pathName === "/") {
        let search = new URLSearchParams(rest.location.search);

        if (search) {
            if (search.get('isLogin') === "1") {
                let params = {};

                params['pathname'] = routePaths[getToken() ? 1 : 0];
                params['state'] = { isLogin: true };

                returnData = (
                    <Redirect to={params} />
                )
            }
        }
    }

    return returnData;
}

export default BulkitPublicRoute;