import React from 'react';
import { Link } from 'react-router-dom';
import { getToken, getMenu, getEmpName, getEmployeeID } from '../../../../helpers/localStorage';
import menuJson from '../../../../data/public-menu.json';
import { HeaderNotification } from '../../../imports';

export default class PublicNavbar extends React.Component {
    constructor() {
        super();

        this.state = {
            menuLocalStorage: [],
            employeeName: "",
            showMobileNav: false
        };

        this.onClickMobileDashboardTrigger = this.onClickMobileDashboardTrigger.bind(this);
    }

    render() {
        const { showMobileNav } = this.state;
        let renderMenu = this.renderMenu({}),
            mobileNavClassName = "lms_header__EfdYnS mobile-nav is-hidden-desktop",
            iconBoxClassName = "icon-box-toggle";

        if (showMobileNav) {
            mobileNavClassName = `${mobileNavClassName} is-active`;
            iconBoxClassName = `${iconBoxClassName} active`;
        }

        return (
            <header className="lms-header__PWKmnr">
                <nav className="navbar dashboard-nav navbar-wrapper navbar-light is-transparent is-fixed-top">
                    <div className="navbar-brand">
                        <Link className="navbar-item lms-logo__ATfWem" to="/home">
                            <img src={process.env.PUBLIC_URL + '/images/logos/hyva.svg'} alt="logo" />
                        </Link>

                        <a id="mobile-dashboard-trigger" className="navbar-item hamburger-btn is-hidden-desktop" onClick={this.onClickMobileDashboardTrigger}>
                            <span className="menu-toggle">
                                <span className={iconBoxClassName}>
                                    <span className="rotate">
                                        <i className="icon-line-top"></i>
                                        <i className="icon-line-center"></i>
                                        <i className="icon-line-bottom"></i>
                                    </span>
                                </span>
                            </span>
                        </a>
                    </div>
                    <div className="navbar-menu">
                        <div className="navbar-end">
                            {
                                getToken() ?
                                    <>
                                        {renderMenu}
                                        {<HeaderNotification />}
                                        <div className="navbar-item navbar-item__hKNZTA has-dropdown is-hoverable">
                                            <a className="navbar-link"><span><i className="fa fa-user icon__mkhcPy"></i>{this.state.employeeName}</span></a>
                                            <div className="navbar-dropdown">
                                                <Link className="navbar-item" to="/logout"><span>Logout</span></Link>
                                            </div>
                                        </div>

                                    </> : <Link to="/login" className="navbar-item navbar-item__hKNZTA">Login</Link>
                            }
                        </div>
                    </div>
                </nav>
                {this.renderChildMenu()}
            </header>
        );
    }

    renderMenu(params) {
        const _this = this,
            { type } = params;
        let itemsRender = [];

        if (menuJson) {
            menuJson.forEach((_menuJson, index) => {
                if (_menuJson.type) {
                    switch (_menuJson.type) {
                        case 1:
                            break;
                        default:
                    };

                } else {
                    let menuFromLocalStorage = _this.getItemFromMenuLocalStorage(_menuJson.applicationFunctionID);

                    if (menuFromLocalStorage) {

                        switch (type) {
                            case 1:
                                itemsRender.push(
                                    <Link to={_menuJson.link} className="mobile-item navbar-item__xTwkgD" key={`menu-${_menuJson.applicationFunctionID}`}>{menuFromLocalStorage.Name}</Link>
                                )
                                break;

                            default:
                                itemsRender.push(
                                    <Link to={_menuJson.link} className="navbar-item navbar-item__hKNZTA" key={`menu-${_menuJson.applicationFunctionID}`}>{menuFromLocalStorage.Name}</Link>
                                )
                                break;
                        }
                    }
                }
            });
        }

        return itemsRender;
    }

    renderChildMenu() {
        const { showMobileNav } = this.state;
        let renderMenu = this.renderMenu({ type: 1 }),
            mobileNavClassName = "lms_header__EfdYnS mobile-nav is-hidden-desktop",
            iconBoxClassName = "icon-box-toggle";

        if (showMobileNav) {
            mobileNavClassName = `${mobileNavClassName} is-active`;
            iconBoxClassName = `${iconBoxClassName} active`;
        }

        return (
            <div className={mobileNavClassName}>
                <div className="container is-fluid">
                    <div className="mobile-nav-inner">
                        {
                            getToken() ?
                                <>
                                    {renderMenu}
                                    <div className="mobile-item navbar-item__xTwkgD is-hoverable">
                                        <a className="navbar-link"><span><i className="fa fa-user icon__mkhcPy"></i>{this.state.employeeName}</span></a>
                                        <div className="navbar-dropdown">
                                            <Link className="navbar-item" to="/logout"><span>Logout</span></Link>
                                        </div>
                                    </div>
                                </> : <Link to="/login" className="navbar-item navbar-item__hKNZTA">Login</Link>
                        }
                    </div>
                </div>
            </div>
        );
    }

    onClickMobileDashboardTrigger() {
        const { showMobileNav } = this.state;

        this.setState({ showMobileNav: !showMobileNav });
    }

    getItemFromMenuLocalStorage(applicationFunctionID) {
        const menuLocalStorage = this.state.menuLocalStorage;

        if (menuLocalStorage) {
            return menuLocalStorage.find(_menuLocalStorage => {
                return _menuLocalStorage.ApplicationFunctionID === applicationFunctionID;
            });
        }

        return null;
    }

    componentDidMount() {
        let menuLocalStorage = getMenu(),
            employeeName = getEmpName(),
            employeeID = getEmployeeID();

        if (menuLocalStorage) {
            menuLocalStorage = JSON.parse(menuLocalStorage);
        }

        if (employeeID == "0") {
            employeeName = "Admin";
        }

        this.setState({
            menuLocalStorage,
            employeeName
        });
    }
}