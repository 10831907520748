const insertToFormTemplate = (data) => dispatch => {
    dispatch({
        type: 'INSERT_FORMTEMPLATE',
        payload: data
    })
},
    modifyFormTemplateLabel = (data) => dispatch => {
        dispatch({
            type: 'MODIFY_FORMTEMPLATE_LABEL',
            payload: data
        })
    },
    modifyFormTemplateModuleTemplateLabelByKey = (data) => dispatch => {
        dispatch({
            type: 'MODIFY_FORMTEMPLATE_MODULETEMPLATE_LABEL_BY_KEY',
            payload: data
        })
    },
    removeFormTemplateObject = (data) => dispatch => {
        dispatch({
            type: 'REMOVE_FORMTEMPLATE_OBJECT',
            payload: data
        })
    },
    modifyFormTemplateList = (data) => dispatch => {
        dispatch({
            type: 'MODIFY_FORMTEMPLATE_LIST',
            payload: data
        })
    },
    modifyFormTemplateItem = (data) => dispatch => {
        dispatch({
            type: 'MODIFY_FORMTEMPLATE_ITEM',
            payload: data
        })
    },
    insertToLabels = (data) => dispatch => {
        dispatch({
            type: 'INSERT_LABELS',
            payload: data
        })
    }, setLabels = (data) => dispatch => {
        dispatch({
            type: "LABELS-SET",
            payload: data
        })
    }, modifyLabel = (data) => dispatch => {
        dispatch({
            type: 'LABEL_MODIFY',
            payload: data
        })
    },
    labelActions = {
        insertToFormTemplate,
        modifyFormTemplateLabel,
        modifyFormTemplateModuleTemplateLabelByKey,
        modifyFormTemplateItem,
        removeFormTemplateObject,
        modifyFormTemplateList,
        insertToLabels,
        setLabels,
        modifyLabel,
        modifyFormTemplateItem,
        modifyFormTemplateList
    };

export {
    labelActions
};