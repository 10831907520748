import React from 'react';
import Navbar from './private/v1/navbar';
import Sidebar from './private/v1/sidebar';
import PublicNavbar from './public/navbar';
import { bulkitFunctions } from './private/v1/functions';
import { SocketIO, getEmployeeID } from '../../../helpers/helper';

require('../../../plugins/bulkit/assets/css/icons.min.css');
require('../../../plugins/bulkit/bulma/bulma.sass');
require('../../../plugins/bulkit/scss/dashboard.scss');
require('../../../plugins/bulkit/assets/css/datepicker/datepicker.css');

export class BulkitPrivate extends React.Component {
    constructor() {
        super();

        this.state = {
            showMobileNav: false
        };

        this.onClickMobileDashboardTrigger = this.onClickMobileDashboardTrigger.bind(this);
    }

    render() {
        const Component = this.props.Component,
            { showMobileNav } = this.state;

        return (
            <>
                <div className="izitoast-list">
                    <div className="iziToast" id="toast-error"></div>
                    <div className="iziToast" id="toast-question"></div>
                </div>
                <Navbar />
                <Sidebar {...{ ...this.props, showMobileNav }} />
                <div id="dashboard-wrapper">
                    <Component {...this.props} />
                </div>
                {
                    getEmployeeID() ?
                        <SocketIO /> : null
                }
            </>
        );
    }

    onClickMobileDashboardTrigger() {
        const { showMobileNav } = this.state;

        this.setState({ showMobileNav: !showMobileNav });
    }

    componentDidMount() {
        bulkitFunctions();
    }
}

export class BulkitPublic extends React.Component {
    render() {
        const Component = this.props.Component;

        return (
            <div id="dashboard-wrapper-public">
                <PublicNavbar />
                <div className="dashboard-wrapper__fsjZfA">
                    <Component {...this.props} />
                </div>
                {
                    getEmployeeID() ?
                        <SocketIO /> : null
                }
            </div>
        );
    }

    componentDidMount() {
        bulkitFunctions();
    }
}

export class BulkitNeutral extends React.Component {
    render() {
        const Component = this.props.Component;

        return (
            <div id="dashboard-wrapper-neutral">
                <Component {...this.props} />
            </div>
        );
    }

    componentDidMount() {
        bulkitFunctions();
    }
}

export class BulkitIframe extends React.Component {
    render() {
        const { Component } = this.props;

        return (
            <div id="dashboard-wrapper-iframe">
                <Component {...this.props} />
            </div>
        )
    }
}