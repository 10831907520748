import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import index from './reducers';
import { composeWithDevTools } from "redux-devtools-extension";

export default function configureStore(initialState = {}) {
    return createStore(
        index,
        initialState,
        composeWithDevTools(
            applyMiddleware(thunk),
        )
    )
}