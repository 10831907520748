import React from 'react';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import parse from 'html-react-parser';
import Select from 'react-select'
import { MediaLibraryFormModule } from '../index';
import { labelActions } from '../action';
import { MEDIA_LIB_URL, SITE_URL } from '../../../helpers/config';
import { encodeToBtoa, genericQuery, getArrayValueByKey, GetLabel, GetLabels, iziToastFn, openModal, processLabel } from '../../../helpers/helper';
import { useDispatch } from 'react-redux';
import { getLMSLabels } from '../../../helpers/localStorage';
import { createPortal } from 'react-dom';

const CMSURL = `${MEDIA_LIB_URL}`,
    getLabelValueParser = (params) => {
        const { LabelJSON } = params;
        let returnJSON = {};

        returnJSON.IsJSON = true;

        try {
            const labelJSON = JSON.parse(LabelJSON?.Label);

            returnJSON.Label = labelJSON;
            returnJSON.LabelContent = labelJSON.Content;
        } catch (Exception) {
            returnJSON.LabelContent = LabelJSON?.Label;
            returnJSON.IsJSON = false;
        }

        return returnJSON;
    },
    texts = [
        'Save', // 0
        'Cancel', // 1
        'Select a language', // 2
        'No options', // 3
        'Content Updated', // 4
    ],
    listKeys = [
        'Language'
    ],
    timeoutIDs = {};

class LabelFormGenerator {
    static labelActionFns = {};

    constructor(params) {
        this.labelActionFns = {
            insertToFormTemplate: params.props.insertToFormTemplate,
            modifyFormTemplateLabel: params.props.modifyFormTemplateLabel,
            modifyFormTemplateModuleTemplateLabelByKey: params.props.modifyFormTemplateModuleTemplateLabelByKey,
            removeFormTemplateObject: params.props.removeFormTemplateObject,
            modifyFormTemplateList: params.props.modifyFormTemplateList,
            modifyFormTemplateItem: params.props.modifyFormTemplateItem
        };
    };

    getTemplates = (params) => {
        const { templateJSON, templateHTML } = params,
            templateJSONConfig = {
                BaseURL: CMSURL,
                Data: {
                    Location: `lms-frontend/${templateJSON}`,
                    Timestamp: new Date().getTime()
                },
                Method: 'GET',
                Url: `/static_files/`,
                ResponseSuccessCallback: (responseJson) => {
                    if (responseJson.status == 200) {
                        templateJSONReturn = responseJson.data;

                        processTemplates();
                    }
                },
                ResponseFailCallback: (responseJson) => {
                    console.log(responseJson);
                }
            },
            templateHTMLConfig = {
                BaseURL: CMSURL,
                Data: {
                    Location: `lms-frontend/${templateHTML}`,
                    Timestamp: new Date().getTime()
                },
                Method: 'GET',
                Url: `/static_files/`,
                ResponseType: 'text/html',
                ResponseSuccessCallback: (responseJson) => {
                    if (responseJson.status == 200) {
                        templateHTMLReturn = responseJson.data;
                        templateHTMLReturn = templateHTMLReturn?.replace(/\r?\n|\r|\t/g, '')?.trim()?.replace(/\>(.*?)\</gi, '><');

                        processTemplates();
                    }
                },
                ResponseFailCallback: (responseJson) => {
                    console.log(responseJson);
                }
            },
            processTemplates = () => {
                if (templateJSONReturn && templateHTMLReturn) {
                    const requestjson = {
                        Module: 'cms_labels_details-list',
                        Parameters: {
                            LabelIDs: templateJSONReturn.LabelIDs
                        }
                    }, config = {
                        Data: { requestjson },
                        Method: "GET",
                        ResponseSuccessCallback: (responseJson) => {
                            const { data } = responseJson,
                                { Status } = data;

                            if (Status.IsSuccess) {
                                const { Data } = data,
                                    { StaticLabels } = Data,
                                    { Labels } = templateJSONReturn;
                                let newState = {},
                                    settings = {};

                                newState['formTemplate'] = {};
                                newState['formTemplate'][templateJSONReturn.Module] = {
                                    ModuleTemplate: templateJSONReturn,
                                    Labels: StaticLabels,
                                    TemplateHTML: templateHTMLReturn
                                };

                                for (let labelsIndex = 0; labelsIndex < Labels?.length; ++labelsIndex) {
                                    const label = Labels[labelsIndex],
                                        { Settings } = label;

                                    if (Settings?.LanguageSelector) {
                                        settings.LanguageSelector = true;
                                    }
                                }

                                this.labelActionFns.insertToFormTemplate(newState.formTemplate);

                                if (settings.LanguageSelector) {
                                    this.get_languageList({
                                        Module: templateJSONReturn.Module
                                    });
                                }
                            } else {
                                iziToastFn({
                                    mode: 3,
                                    message: Status.Message
                                });
                            }
                        },
                        ResponseFailCallback: (responseJson) => {
                            console.log(responseJson);
                        },
                        Url: "/single_api/"
                    };

                    genericQuery(config);
                }
            };
        let templateJSONReturn,
            templateHTMLReturn;

        genericQuery(templateJSONConfig);
        genericQuery(templateHTMLConfig);
    };

    /*
    * Returns the form generated
    */

    getFormGenerator = (params) => {
        const { SubmitButton, AppendElementAfter, FormTemplate } = params,
            { TemplateHTML, ModuleTemplate, Labels, List } = FormTemplate;
        let renderForm;

        try {
            if (TemplateHTML) {
                const traverseModuleTemplateHTML = (item) => {
                    const { props } = item;

                    if (props) {
                        const { id } = props;
                        let { children } = props;

                        if (id) {
                            item = this.processFormElement({
                                ID: id,
                                ModuleTemplate,
                                Labels,
                                List
                            });
                        } else {
                            if (children) {
                                if (Array.isArray(children)) {
                                    for (let childrenIndex = 0; childrenIndex < children.length; childrenIndex++) {
                                        children[childrenIndex] = traverseModuleTemplateHTML(children[childrenIndex]);
                                    }
                                } else {
                                    children = traverseModuleTemplateHTML(children);
                                }
                            }

                            item = React.cloneElement(item, {
                                children
                            });
                        }
                    }

                    return item;
                };

                renderForm = parse(TemplateHTML);
                renderForm = traverseModuleTemplateHTML(renderForm);
            }
        } catch (Exception) {
            console.log(Exception)
        }

        return renderForm;
    };

    /*
    * Processes the form elements
    */

    processFormElement = (params) => {
        const { ID, ModuleTemplate, Labels } = params,
            templateJSON = getArrayValueByKey({
                Key: 'ID',
                Value: ID,
                List: ModuleTemplate.Labels
            }),
            labelJSON = getArrayValueByKey({
                Key: 'LabelID',
                Value: ID,
                List: Labels
            });
        let generatedField;

        if (templateJSON && labelJSON) {
            const elementID = this.generate_elementID({
                Module: ModuleTemplate.Module,
                LabelJSON: labelJSON
            }),
                paramsJSON = {
                    ...params,
                    ElementID: elementID,
                    TemplateJSON: templateJSON,
                    LabelJSON: labelJSON
                };

            if (templateJSON.IsEditMode || templateJSON.BypassEditMode) {
                switch (labelJSON.LabelTypeID) {
                    case 14:
                        generatedField = this.generate_standard({ ...paramsJSON });
                        break;
                    case 15:
                        generatedField = this.generate_image(paramsJSON);
                        break;
                }
            } else {
                generatedField = LabelGenerator.processLabel({
                    ...params,
                    LabelJSON: labelJSON,
                    TemplateJSON: templateJSON,
                    OnClickEdit_standard: this.onClickEdit_standard
                });
            }
        }

        return generatedField;
    };

    processResponseCallbackForElement = (params) => {
        const { responseJSON, ModuleTemplate, TemplateJSON, LabelJSON } = params;
        let moduleTemplateLabelsKeyValue = [{
            Key: 'IsLoading',
            Value: false
        }];
        let modifyFormTemplateItemParams = {
            Module: ModuleTemplate.Module,
            ObjectHolder: {
                'ModuleTemplate.Labels': {
                    PrimaryKey: {
                        Key: 'ID',
                        Value: LabelJSON.LabelID
                    },
                    NewProps: {
                        IsLoading: false
                    }
                }
            }
        };

        if (responseJSON?.status === 200) {
            const { data } = responseJSON;


            if (data.Status.IsSuccess) {
                const { StaticLabels } = data?.Data;

                if (StaticLabels) {
                    const returnedLabelJSON = StaticLabels[0];

                    modifyFormTemplateItemParams.ObjectHolder['ModuleTemplate.Labels'].NewProps = {
                        ...modifyFormTemplateItemParams.ObjectHolder['ModuleTemplate.Labels'].NewProps,
                        IsEditMode: false
                    };
                    modifyFormTemplateItemParams.ObjectHolder['ModuleTemplate.Labels'].DeleteProps = ['Value'];
                    modifyFormTemplateItemParams.ObjectHolder['Labels'] = {
                        PrimaryKey: {
                            Key: 'LabelID',
                            Value: LabelJSON.LabelID
                        },
                        NewProps: {
                            ...returnedLabelJSON
                        }
                    };

                    iziToastFn({
                        mode: 1,
                        message: texts[4]
                    });
                } else {
                    modifyFormTemplateItemParams.ObjectHolder['Labels'] = {
                        PrimaryKey: {
                            Key: 'LabelID',
                            Value: LabelJSON.LabelID
                        },
                        NewProps: {
                            ...LabelJSON
                        }
                    };
                }
            } else {
                modifyFormTemplateItemParams.ObjectHolder['Labels'] = {
                    PrimaryKey: {
                        Key: 'LabelID',
                        Value: LabelJSON.LabelID
                    },
                    NewProps: {
                        ...LabelJSON
                    }
                };

                iziToastFn({
                    mode: 3,
                    message: data.Status.Message
                });
            }
        } else {
            iziToastFn({
                mode: 3,
                message: responseJSON.statusText
            });
        }

        this.labelActionFns.modifyFormTemplateItem(modifyFormTemplateItemParams);
    };

    processFormRemoveObject = (params) => {
        const { Keys } = params;

        if (Keys.length > 0) {
            this.labelActionFns.removeFormTemplateObject({
                Keys
            });
        }
    }

    /*
    * End processes the form elements
    */

    /*
    * Generators
    */

    generate_standard(params) {
        const { LabelJSON, TemplateJSON, ElementID } = params;
        let generatedField,
            labelValueParsed = getLabelValueParser({ LabelJSON }),
            fieldProps = {
                id: ElementID,
                'data-templatejson': encodeToBtoa(JSON.stringify(TemplateJSON)),
                'data-labeljson': encodeToBtoa(JSON.stringify(LabelJSON)),
                className: TemplateJSON.ElementClassName
            }, labelValue = labelValueParsed.LabelContent,
            { ParentClassName, ElementWrapperClassName } = TemplateJSON,
            formHeader = this.generate_formHeader(params);

        if (TemplateJSON.IsLoading) {
            ElementWrapperClassName = `${ElementWrapperClassName} is-loading is-disabled`;
        }

        switch (TemplateJSON.SubType) {
            case 2: {
                fieldProps = {
                    ...fieldProps,
                    rows: TemplateJSON.Rows,
                    onBlur: this.onBlur_textarea.bind(this, params),
                    onKeyUp: this.onKeyUp_standard.bind(this, params),
                    defaultValue: labelValue
                };

                generatedField = (<div className={ParentClassName}>
                    {formHeader.Label}
                    <div className={ElementWrapperClassName}>
                        <textarea {...fieldProps} />
                    </div>
                </div>);
                break;
            }
            case 3: {
                let showSaveBtn = false;

                fieldProps = {
                    ...fieldProps,
                    editor: ClassicEditor,
                    config: {
                        ckfinder: {
                            uploadUrl: `${MEDIA_LIB_URL}/news`,
                            headers: {
                                'X-CSRF-TOKEN': 'CSFR-Token',
                                Authorization: 'Bearer <JSON Web Token>'
                            }
                        },
                        toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'blockQuote', '|', 'undo', 'redo', 'smiley']
                    },
                    onChange: (event, editor) => {
                        this.onChange_WYSIWYG(params, editor);
                    },
                    data: TemplateJSON.Value != null ? TemplateJSON.Value : labelValue
                };

                if (LabelJSON.LabelRID) {
                    if (typeof TemplateJSON.Value == "string") {
                        if (TemplateJSON.Value.length && TemplateJSON.Value != labelValue) {
                            showSaveBtn = true;
                        }
                    }
                } else {
                    if (typeof TemplateJSON.Value == "string") {
                        if (TemplateJSON.Value.length && TemplateJSON.Value != labelValue) {
                            showSaveBtn = true;
                        }
                    } else {
                        showSaveBtn = true;
                    }
                }

                generatedField = (<div className={ParentClassName}>
                    {formHeader.Label}
                    <div className={ElementWrapperClassName}>
                        <CKEditor {...fieldProps} />
                        <div className="field ta-right" style={{ marginTop: "0.5rem" }}>
                            {
                                showSaveBtn ?
                                    <button className="button -primary-btn-lms" onClick={() => {
                                        this.onClick_saveWYSIWYG(params);
                                    }}>{texts[0]}</button>
                                    : <></>
                            }
                            <button className="button btn-lms-nolayout" onClick={() => {
                                this.onClick_cancelWYSIWYG(params);
                            }}>{texts[1]}</button>
                        </div>
                    </div>
                </div>);

                break;
            }
        }

        return generatedField;
    };

    generate_image = (params) => {
        const { LabelJSON, TemplateJSON, ElementID } = params,
            labelValueParsed = getLabelValueParser({ LabelJSON });
        let generatedField,
            fieldProps = {
                ID: ElementID
            },
            labelValue = labelValueParsed.LabelContent,
            { ParentClassName, ElementWrapperClassName } = TemplateJSON;

        if (TemplateJSON.IsLoading) {
            ElementWrapperClassName = `${ElementWrapperClassName} is-loading is-disabled`;
        }


        switch (TemplateJSON.SubType) {
            case 1: {
                fieldProps = {
                    ...fieldProps,
                    LabelParams: { ...params },
                    RenderType: 2,
                    maxSelection: TemplateJSON.Max,
                    value: labelValueParsed.IsJSON ? labelValue : [],
                    onCarouselChange: this.onCarouselChange
                };
                const LABEL = processLabel({ LabelID: TemplateJSON.LabelID, DefaultLabel: TemplateJSON.DefaultLabel })
                console.log("LABEL", LABEL)
                // let LABEL = TemplateJSON.DefaultLabel
                console.log("TemplateJSON", TemplateJSON)
                generatedField = (<div className={ParentClassName} key={`gen_img${ElementID}-${Math.random() * 10 + 1}`}>
                    <label htmlFor={ElementID}>{LABEL}</label>
                    <div className={ElementWrapperClassName}>
                        <MediaLibraryFormModule {...fieldProps} />
                    </div>
                </div>);
                break;
            }
            case 2: {
                fieldProps = {
                    ...fieldProps,
                    LabelParams: { ...params },
                    RenderType: 1,
                    maxSelection: TemplateJSON.Max,
                    value: labelValueParsed.IsJSON ? labelValue : [],
                    onCarouselChange: this.onCarouselChange
                };
                const LABEL = processLabel({ LabelID: TemplateJSON.LabelID, DefaultLabel: TemplateJSON.DefaultLabel })
                console.log(TemplateJSON, TemplateJSON)
                console.log("LABEL", LABEL)
                // let LABEL = TemplateJSON.DefaultLabel
                generatedField = (<div className={ParentClassName} key={`${ElementID}-${Math.random() * 10 + 1}`}>
                    <label htmlFor={ElementID}>{LABEL}</label>
                    <div className={ElementWrapperClassName}>
                        <MediaLibraryFormModule {...fieldProps} />
                    </div>
                </div>);
                break;
            }
        }

        return generatedField;
    };

    generate_formHeader = (params) => {
        const { LabelJSON, TemplateJSON, ElementID } = params;
        let renderFormHeader = {},
            generatedSettings = {};

        if (TemplateJSON.Settings) {
            generatedSettings = this.generate_settings(params);
        }
        console.log("TemplateJSON", TemplateJSON)
        let LABEL = processLabel({ LabelID: TemplateJSON.LabelID, DefaultLabel: TemplateJSON.DefaultLabel })
        if (generatedSettings && Object.keys(generatedSettings).length) {
            renderFormHeader.Label = (
                <div>
                    <div className={`setting__WRnPwW`}><label htmlFor={ElementID}>{LABEL}</label> {generatedSettings.Trigger}</div>
                    <div className={`setting__nwXBWg${TemplateJSON.Settings.IsActive ? ' active' : ''}`}>
                        <div>{generatedSettings.LanguageSelection}</div>
                    </div>
                </div>
            )
        } else {
            renderFormHeader.Label = (<label htmlFor={ElementID}>{LABEL}</label>);
        }

        return renderFormHeader
    }

    generate_settings = (params) => {
        const { TemplateJSON } = params,
            { Settings } = TemplateJSON;
        let renderSettings = {};

        if (Settings.LanguageSelector) {
            renderSettings.LanguageSelection = this.generate_settings_languageSelector(params);
        }

        if (Object.keys(renderSettings).length) {
            renderSettings.Trigger = (<span className={`dropdown_caret__KnrxKA${TemplateJSON.Settings.IsActive ? ' active' : ''}`} onClick={() => (this.onClickOpen_settings(params))}>
                <i className="fa fa-caret-down"></i>
            </span>);
        }

        return renderSettings;
    }

    generate_settings_languageSelector = (params) => {
        let { LabelJSON } = params;
        const { ElementID, ModuleTemplate, List, TemplateJSON } = params,
            onChange = (option, payload) => {
                if (payload.action === "select-option") {
                    this.moduleQuery({
                        ...params,
                        Type: 3,
                        LanguageID: option.value,
                        CallBack: moduleQueryCallBack
                    });
                }
            },
            moduleQueryCallBack = (responseJSON, requestParams) => {
                if (responseJSON?.status === 200) {
                    const { data } = responseJSON,
                        { LanguageID } = requestParams;

                    if (data.Status.IsSuccess) {
                        const { Labels } = data?.Data;
                        let label = Labels[0];

                        if (label) {
                            const { Languages } = label;
                            let selectedLanguage = Languages?.find(language => (language.LanguageID == LanguageID)),
                                englishLanguage = Languages?.find(language => (language.LanguageID == 1)),
                                modifyFormTemplateItemParams = {
                                    Module: ModuleTemplate.Module,
                                    ObjectHolder: {
                                        'ModuleTemplate.Labels': {
                                            PrimaryKey: {
                                                Key: 'ID',
                                                Value: LabelJSON.LabelID
                                            },
                                            NewProps: {
                                                IsValueNotEditable: true,
                                                IsLoading: false
                                            },
                                            DeleteProps: ['Value']
                                        }
                                    }
                                };

                            if (selectedLanguage) {
                                LabelJSON = {
                                    ...LabelJSON,
                                    Label: selectedLanguage.Label,
                                    LabelRID: selectedLanguage.LabelRID,
                                    LanguageID: selectedLanguage.LanguageID,
                                    Language: selectedLanguage.LanguageName
                                };

                                modifyFormTemplateItemParams.ObjectHolder['Labels'] = {
                                    PrimaryKey: {
                                        Key: 'LabelID',
                                        Value: LabelJSON.LabelID
                                    },
                                    NewProps: {
                                        ...LabelJSON
                                    }
                                }
                                modifyFormTemplateItemParams.ObjectHolder['ModuleTemplate.Labels'].NewProps = {
                                    ...modifyFormTemplateItemParams.ObjectHolder['ModuleTemplate.Labels'].NewProps,
                                    IsSaveButtonShowing: false,
                                    Settings: {
                                        ...TemplateJSON.Settings,
                                        LanguageSelector: {
                                            ...TemplateJSON.Settings.LanguageSelector,
                                            IsLoading: true
                                        }
                                    }
                                };
                            } else {
                                LabelJSON = {
                                    ...LabelJSON,
                                    Label: englishLanguage.Label,
                                    LabelRID: null,
                                    LanguageID
                                };

                                modifyFormTemplateItemParams.ObjectHolder['Labels'] = {
                                    PrimaryKey: {
                                        Key: 'LabelID',
                                        Value: LabelJSON.LabelID
                                    },
                                    NewProps: {
                                        Label: englishLanguage.Label,
                                        LabelRID: null,
                                        LanguageID
                                    }
                                }
                            }

                            modifyFormTemplateItemParams.ObjectHolder['ModuleTemplate.Labels'].NewProps = {
                                ...modifyFormTemplateItemParams.ObjectHolder['ModuleTemplate.Labels'].NewProps,
                                Settings: {
                                    ...TemplateJSON.Settings,
                                    LanguageSelector: {
                                        ...TemplateJSON.Settings.LanguageSelector,
                                        IsLoading: false
                                    }
                                }
                            };

                            this.labelActionFns.modifyFormTemplateItem(modifyFormTemplateItemParams);
                        }
                    } else {
                        iziToastFn({
                            mode: 3,
                            message: data.Status.Message
                        });
                    }
                }
            }, selectProps = {
                isSearchable: true,
                defaultValue: LabelJSON.LanguageID,
                placeholder: texts[2],
                onChange: onChange,
                noOptionsMessage: ({ inputValue: string }) => (texts[3])
            };

        if (List) {
            const listLanguage = List[listKeys[0]];

            if (listLanguage.IsLoading || TemplateJSON?.Settings?.LanguageSelector?.IsLoading) {
                selectProps.isDisabled = true;
                selectProps.isLoading = true;
            } else {
                selectProps.options = listLanguage.List;
                selectProps.value = listLanguage.List.find(language => (language.LanguageID == LabelJSON.LanguageID));
            }
        } else {
            selectProps.isDisabled = true;
        }

        return (
            <Select
                {...selectProps} />
        );
    }

    generate_elementID = (params) => {
        const { Module, LabelJSON } = params;
        let ID = `${Module}__${LabelJSON.LabelRID}`;

        return ID;
    };

    generate_labelContentJSON = (params) => {
        const { TemplateJSON, Content } = params;
        let contentJSON = {
            Subtype: TemplateJSON?.SubType,
            Content: Content
        };

        return {
            ContentJSON: contentJSON,
            ContentJSONStringified: JSON.stringify(contentJSON)
        };
    };

    /*
    * End Generators
    */

    /*
    * Events
    */

    onBlur_textarea = (params, event) => {
        const targetElement = event.target,
            { LabelJSON, TemplateJSON } = params,
            labelValueParsed = getLabelValueParser({ LabelJSON });

        if (targetElement) {
            let value = targetElement.value?.trim();

            if (value?.length > 0) {
                if (value != labelValueParsed.LabelContent) {
                    value = this.generate_labelContentJSON({
                        TemplateJSON,
                        Content: value
                    })?.ContentJSONStringified;

                    this.moduleQuery({
                        ...params,
                        Type: 1,
                        NewValue: value
                    });
                } else {
                    this.labelActionFns.modifyFormTemplateModuleTemplateLabelByKey({
                        ...params,
                        KeyValue: {
                            Key: 'IsEditMode',
                            Value: false
                        }
                    });
                }
            } else {
                targetElement.value = labelValueParsed.LabelContent;
            }
        }
    };

    onChange_WYSIWYG = (params, editor) => {
        const { LabelJSON, TemplateJSON } = params,
            labelValueParsed = getLabelValueParser({ LabelJSON });
        let value = editor.getData(),
            keyValue = [{
                Key: 'Value',
                Value: value
            }],
            modifyFormTemplateItemParams = {
                Module: params.ModuleTemplate.Module,
                ObjectHolder: {
                    'ModuleTemplate.Labels': {
                        PrimaryKey: {
                            Key: 'ID',
                            Value: TemplateJSON.ID
                        }
                    }
                },
                KeyValueSpecific: {
                    'Labels': {
                        PrimaryKey: {
                            Key: 'LabelID',
                            Value: LabelJSON.LabelID
                        },
                        Props: [
                            {
                                Key: 'LanguageID',
                                Value: LabelJSON.LanguageID
                            }
                        ]
                    }
                }
            };

        if (!TemplateJSON.IsValueNotEditable) {
            if (timeoutIDs['onChangeWYSIWYG']) {
                clearTimeout(timeoutIDs['onChangeWYSIWYG']);
            }

            timeoutIDs['onChangeWYSIWYG'] = setTimeout(() => {
                modifyFormTemplateItemParams.ObjectHolder['ModuleTemplate.Labels'].NewProps = {
                    IsSaveButtonShowing: (value.length > 0 && value != labelValueParsed.LabelContent),
                    Value: value
                };

                this.labelActionFns.modifyFormTemplateItem(modifyFormTemplateItemParams);
            }, 200);
        } else {
            if (timeoutIDs['onChangeWYSIWYG']) {
                clearTimeout(timeoutIDs['onChangeWYSIWYG']);
            }

            modifyFormTemplateItemParams.ObjectHolder['ModuleTemplate.Labels'].DeleteProps = ['IsValueNotEditable'];
            this.labelActionFns.modifyFormTemplateItem(modifyFormTemplateItemParams);
        }
    }

    onClick_saveWYSIWYG = (params) => {
        const { TemplateJSON, LabelJSON, ModuleTemplate } = params,
            moduleQueryCallBack = (responseJSON, requestParams) => {
                if (responseJSON?.status === 200) {
                    const { data } = responseJSON,
                        { LanguageID, ModuleTemplate } = requestParams;
                    let { LabelJSON } = requestParams;

                    if (data.Status.IsSuccess) {
                        const { Labels } = data?.Data;

                        if (Labels) {
                            const label = Labels[0],
                                { Languages } = label;
                            let selectedLanguage = Languages?.find(language => (language.LanguageID == LabelJSON.LanguageID)),
                                modifyFormTemplateItemParams = {
                                    Module: ModuleTemplate.Module,
                                    ObjectHolder: {
                                        'ModuleTemplate.Labels': {
                                            PrimaryKey: {
                                                Key: 'ID',
                                                Value: LabelJSON.LabelID
                                            },
                                            DeleteProps: ['Value']
                                        }
                                    }
                                };

                            if (selectedLanguage) {
                                LabelJSON = {
                                    ...LabelJSON,
                                    Label: selectedLanguage.Label,
                                    LabelRID: selectedLanguage.LabelRID,
                                    LanguageID: selectedLanguage.LanguageID,
                                    LanguageName: selectedLanguage.LanguageName
                                };

                                modifyFormTemplateItemParams.ObjectHolder['Labels'] = {
                                    PrimaryKey: {
                                        Key: 'LabelID',
                                        Value: LabelJSON.LabelID
                                    },
                                    NewProps: {
                                        ...LabelJSON
                                    }
                                }
                                modifyFormTemplateItemParams.ObjectHolder['ModuleTemplate.Labels'].NewProps = {
                                    IsSaveButtonShowing: false,
                                    IsLoading: false,
                                    IsEditMode: false
                                };

                                this.labelActionFns.modifyFormTemplateItem(modifyFormTemplateItemParams);
                                iziToastFn({
                                    mode: 1,
                                    message: texts[4]
                                });
                            }
                        }
                    } else {
                        iziToastFn({
                            mode: 3,
                            message: data.Status.Message
                        });
                    }
                }
            };
        let value = TemplateJSON.Value,
            proceedType = 1;

        if (!LabelJSON.LabelRID) {
            proceedType = 2;

            if (!value) {
                value = LabelJSON.Label;
            }
        }

        if (value) {
            value = this.generate_labelContentJSON({
                TemplateJSON,
                Content: value
            })?.ContentJSONStringified;

            switch (proceedType) {
                case 1: {
                    this.moduleQuery({
                        ...params,
                        Type: 1,
                        NewValue: value,
                        SuccessAdditionalKeyValue: [{
                            Key: 'IsSaveButtonShowing',
                            Value: false
                        }],
                        FailAdditionalKeyValue: [{
                            Key: 'IsSaveButtonShowing',
                            Value: false
                        }]
                    });
                    break;
                }
                case 2: {
                    this.moduleQuery({
                        TemplateJSON,
                        ModuleTemplate,
                        LabelJSON,
                        Type: 4,
                        Label: value,
                        CallBack: moduleQueryCallBack
                    });
                    break;
                }
            }
        }
    }

    onClick_cancelWYSIWYG = (params) => {
        this.labelActionFns.modifyFormTemplateItem({
            Module: params.ModuleTemplate.Module,
            ObjectHolder: {
                'ModuleTemplate.Labels': {
                    PrimaryKey: {
                        Key: 'ID',
                        Value: params.LabelJSON.LabelID
                    },
                    NewProps: {
                        IsSaveButtonShowing: false,
                        IsEditMode: false
                    },
                    DeleteProps: ['Value']
                }
            }
        });
    }

    onKeyUp_standard = (params, event) => {
        switch (event.which) {
            case 27:
                this.labelActionFns.modifyFormTemplateModuleTemplateLabelByKey({
                    ...params,
                    KeyValue: {
                        Key: 'IsEditMode',
                        Value: false
                    }
                });
                break;
        }
    }

    onClickEdit_standard = (params) => {
        this.labelActionFns.modifyFormTemplateModuleTemplateLabelByKey({
            ...params,
            KeyValue: {
                Key: 'IsEditMode',
                Value: true
            }
        });
    }

    onCarouselChange = (params) => {
        const { LabelParams, currentSelectedMediaFiles } = params,
            { LabelJSON, TemplateJSON } = LabelParams,
            labelValueParsed = getLabelValueParser({ LabelJSON });
        let newCurrentSelectedMediaFiles = currentSelectedMediaFiles?.map(currentSelectedMediaFile => {
            return {
                MediaFileID: currentSelectedMediaFile.MediaFileID,
                Path: currentSelectedMediaFile.Path
            };
        }), value = this.generate_labelContentJSON({
            TemplateJSON,
            Content: newCurrentSelectedMediaFiles
        })?.ContentJSONStringified;

        if (value != labelValueParsed.LabelContent) {
            this.moduleQuery({
                ...LabelParams,
                Type: 1,
                NewValue: value
            });
        }
    }

    onClickOpen_settings = (params) => {
        const { TemplateJSON } = params;
        let modifyFormTemplateItemParams = {
            Module: params.ModuleTemplate.Module,
            ObjectHolder: {
                'ModuleTemplate.Labels': {
                    PrimaryKey: {
                        Key: 'ID',
                        Value: TemplateJSON.ID
                    },
                    NewProps: {
                        Settings: {
                            ...TemplateJSON.Settings,
                            IsActive: !TemplateJSON.Settings.IsActive
                        }
                    }
                }
            }
        };

        this.labelActionFns.modifyFormTemplateItem(modifyFormTemplateItemParams);
    }

    /*
    * End Events
    */

    /*
    * Query Function Holder
    */

    moduleQuery = (params) => {
        const { Type, CallBack, TemplateJSON } = params,
            processRequest = () => {
                switch (Type) {
                    case 1: {
                        this.labelActionFns.modifyFormTemplateModuleTemplateLabelByKey({
                            ...params,
                            KeyValue: {
                                Key: 'IsLoading',
                                Value: true
                            }
                        });
                        break;
                    }
                    case 2: {
                        const { ListKey, Module } = params;
                        this.labelActionFns.modifyFormTemplateList({
                            Module,
                            KeyValue: {
                                Key: 'IsLoading',
                                Value: true
                            },
                            ListKey: ListKey
                        });
                        break;
                    }
                    case 3: {
                        let modifyFormTemplateItemParams = {
                            Module: params.ModuleTemplate.Module,
                            Callee: "Case 3"
                        };

                        modifyFormTemplateItemParams.ObjectHolder = {
                            'ModuleTemplate.Labels': {
                                PrimaryKey: {
                                    Key: 'ID',
                                    Value: TemplateJSON.ID
                                },
                                NewProps: {
                                    IsLoading: true,
                                    Settings: {
                                        ...TemplateJSON.Settings,
                                        LanguageSelector: {
                                            ...TemplateJSON.Settings.LanguageSelector,
                                            IsLoading: true
                                        }
                                    }
                                }
                            }
                        }

                        this.labelActionFns.modifyFormTemplateItem(modifyFormTemplateItemParams);
                        break;
                    }
                    case 4: {
                        let modifyFormTemplateItemParams = {
                            Module: params.ModuleTemplate.Module,
                            Callee: "Case 3"
                        };

                        modifyFormTemplateItemParams.ObjectHolder = {
                            'ModuleTemplate.Labels': {
                                PrimaryKey: {
                                    Key: 'ID',
                                    Value: TemplateJSON.ID
                                },
                                NewProps: {
                                    IsLoading: true
                                }
                            }
                        }

                        this.labelActionFns.modifyFormTemplateItem(modifyFormTemplateItemParams);
                        break;
                    }
                }

                config = genericQuery(config);
            };
        let requestjson = {},
            config = {
                Data: { requestjson },
                Method: "GET",
                ResponseSuccessCallback: (responseJSON) => {
                    switch (Type) {
                        case 1: {
                            this.processResponseCallbackForElement({
                                ...params,
                                responseJSON
                            });
                            break;
                        }
                        case 2:
                        case 3:
                        case 4: {
                            CallBack(responseJSON, params);
                            break;
                        }
                    }
                },
                ResponseFailCallback: (responseJSON) => {
                    console.log(responseJSON)
                    switch (Type) {
                        case 1: {
                            this.processResponseCallbackForElement({
                                ...params,
                                responseJSON
                            });
                            break;
                        }
                        case 2:
                        case 3:
                        case 4: {
                            CallBack(responseJSON, params);
                            break;
                        }
                    }
                },
                Url: "/single_api/"
            },
            newState = {},
            formData = new FormData();

        switch (Type) {
            case 1: {
                const { LabelJSON, NewValue } = params;

                config.Method = "POST";
                requestjson.Module = 'cms_labels_update';
                requestjson.Parameters = {
                    RID: LabelJSON.LabelRID,
                    OldValue: LabelJSON.Label,
                    NewValue: NewValue
                };

                formData.append('requestjson', JSON.stringify(requestjson));
                config.Data.requestjson = formData;
                break;
            }
            case 2: {
                requestjson.Module = 'generic_lmslanguage_list';
                break;
            }
            case 3: {
                const { LabelJSON, LanguageID } = params;

                requestjson.Module = 'cms_labels_language-details';
                requestjson.Parameters = {
                    LabelIDs: [LabelJSON.LabelID],
                    LanguageID
                };
                break;
            }
            case 4: {
                const { LabelJSON, Label } = params;

                config.Method = "POST";
                requestjson.Module = 'cms_labels_add-language';
                requestjson.Parameters = {
                    LabelID: LabelJSON.LabelID,
                    LanguageID: LabelJSON.LanguageID,
                    Label
                };
                formData.append('requestjson', JSON.stringify(requestjson));
                config.Data.requestjson = formData;
                break;
            }
        }

        processRequest();
    };

    get_languageList = (params) => {
        const { Module } = params,
            listKey = listKeys[0],
            callBack = (responseJSON) => {
                const KeyValue = [{
                    Key: 'IsLoading',
                    Value: false
                }];
                let isFail = false;

                if (responseJSON?.status === 200) {
                    const { data } = responseJSON;

                    if (data.Status.IsSuccess) {
                        const { LMSLanguage } = data?.Data;
                        KeyValue.push({
                            Key: 'List',
                            Value: LMSLanguage
                        });

                        this.labelActionFns.modifyFormTemplateList({
                            Module,
                            KeyValue: KeyValue,
                            ListKey: listKey
                        });
                    } else {
                        iziToastFn({
                            mode: 3,
                            message: data.Status.Message
                        });
                        isFail = true;
                    }
                } else {
                    isFail = true;
                }

                if (isFail) {
                    this.labelActionFns.modifyFormTemplateList({
                        Module,
                        KeyValue: KeyValue,
                        ListKey: listKey
                    });
                }
            };

        this.moduleQuery({
            Type: 2,
            Module,
            CallBack: callBack,
            ListKey: listKey
        });
    }

    /*
    * End Query Function Holder
    */

    /*
    * Get default mapStateToProps and mapDispatchToProps
    */

    static getMapDispatchProps = dispatch => ({
        insertToFormTemplate: (data) => dispatch(labelActions.insertToFormTemplate(data)),
        modifyFormTemplateLabel: (data) => dispatch(labelActions.modifyFormTemplateLabel(data)),
        modifyFormTemplateModuleTemplateLabelByKey: (data) => dispatch(labelActions.modifyFormTemplateModuleTemplateLabelByKey(data)),
        removeFormTemplateObject: (data) => dispatch(labelActions.removeFormTemplateObject(data)),
        modifyFormTemplateList: (data) => dispatch(labelActions.modifyFormTemplateList(data)),
        modifyFormTemplateItem: (data) => dispatch(labelActions.modifyFormTemplateItem(data))
    });

    static getMapStateToProps = state => ({
        formTemplateReducer: state.FormTemplateReducer
    });

    /*
    * End default mapStateToProps and mapDispatchToProps
    */
};

export default LabelFormGenerator;
export class LabelGenerator {
    static labelActionFns = {};
    static labelReducer = {};

    constructor(params) {
        this.labelActionFns = {
            insertToLabels: params?.props?.insertToLabels
        };
        this.labelReducer = params?.props?.labelReducer
    }

    /*
    * Processes
    */
    //here
    static processLabel = (params) => {
        const { LabelJSON, LabelOnly } = params;
        let generatedField;

        if (LabelJSON) {
            const labelID = this.generate_elementID({ LabelJSON }),
                labelValueParsed = getLabelValueParser({ LabelJSON }),
                paramsJSON = {
                    ...params,
                    LabelID: labelID,
                    LabelValueParsed: labelValueParsed
                };
            if (!LabelOnly) {
                // if (labelValueParsed.IsJSON) {
                switch (LabelJSON.LabelTypeID) {
                    case 14:
                        generatedField = this.generate_standard(paramsJSON);
                        break;
                    case 15:
                        break;
                }
                // } else {
                //     generatedField = this.generate_defaultLabel(paramsJSON);
                // }
            } else {
                generatedField = labelValueParsed;
            }
        }

        return generatedField;
    }

    nonStaticProcessLabel = (params) => {
        const { LabelID, LabelReducer, UserSettingsReducer } = params,
            { labels } = LabelReducer,
            labelHolderJSON = labels[LabelID];

        if (labelHolderJSON) {
            const { UserLanguage } = UserSettingsReducer || {},
                languages = labelHolderJSON.Languages;
            let labelJSON,
                generatedLabel;

            if (UserLanguage) {
                const selectedLanguage = languages?.find(language => (language.LanguageID == UserLanguage.LanguageID)),
                    englishLanguage = languages[0];

                labelJSON = selectedLanguage || englishLanguage;
            } else {
                labelJSON = languages[0];
            }

            generatedLabel = LabelGenerator.processLabel({
                LabelJSON: labelJSON,
                LabelOnly: true
            });

            return generatedLabel;
        }
    }

    /*
    * End processes
    */

    /*
    * Generators
    */

    static generate_defaultLabel = (params) => {
        const { LabelJSON, LabelID } = params;
        let generatedField;
        console.log("a")
        generatedField = (
            <span id={LabelID}>{LabelJSON?.Label}</span>
        )

        return generatedField;
    }

    static generate_standard = (params) => {
        const { LabelJSON, TemplateJSON, LabelID, LabelValueParsed } = params;
        let generatedField,
            fieldProps;

        switch (TemplateJSON.SubType) {
            case 2:
            case 3: {
                if (TemplateJSON) {
                    console.log(TemplateJSON)
                    const { NormalClasses } = TemplateJSON
                    fieldProps = {
                        ...fieldProps,
                        id: LabelID,
                        className: NormalClasses.ElementClassName
                    };
                    const LABEL = processLabel({ LabelID: TemplateJSON.LabelID, DefaultLabel: TemplateJSON.DefaultLabel })

                    generatedField = (
                        <div className={NormalClasses.ParentClassName}>
                            <label htmlFor={LabelID} >{LABEL}</label>
                            <div onClick={params.OnClickEdit_standard?.bind(this, params)} className={NormalClasses.ElementWrapperClassName}>
                                <div {...fieldProps}>{parse(LabelValueParsed.LabelContent)}</div>
                            </div>
                        </div>
                    );
                } else {
                    generatedField = (
                        <p className="label_standard__cQnZMM" id={LabelID}>{LabelValueParsed.LabelContent}</p>
                    );
                }

                break;
            }
        }

        return generatedField;
    }

    static generate_label = (params) => {

    }

    static generate_elementID = (params) => {
        const { LabelJSON } = params;
        let ID = `lms-label__${LabelJSON.LabelRID}`;

        return ID;
    }

    /*
    * End generators
    */

    get_labelsByID = (params) => {
        const callBack = (responseJSON) => {
            if (responseJSON.status === 200) {
                const { data } = responseJSON,
                    { Status } = data;

                if (Status.IsSuccess) {
                    const { Data } = data,
                        { Labels } = Data;

                    if (Labels) {
                        this.labelActionFns.insertToLabels({ Labels });
                    }
                } else {

                }
            }
        };

        LabelGenerator.moduleQuery({
            ...params,
            CallBack: callBack,
            Type: 1
        });
    }

    static moduleQuery = (params) => {
        const { Type } = params,
            processRequest = () => {
                config = genericQuery(config);
            };
        let requestjson = {},
            config = {
                Data: { requestjson },
                Method: "GET",
                ResponseSuccessCallback: (responseJSON) => {
                    switch (Type) {
                        case 1:
                            params.CallBack(responseJSON);
                            break;

                        default:
                            break;
                    }
                },
                ResponseFailCallback: (responseJSON) => {
                    console.log(responseJSON);
                    switch (Type) {
                        case 1:
                            params.CallBack(responseJSON);
                            break;

                        default:
                            break;
                    }
                },
                Url: "/single_api/"
            },
            newState = {},
            formData = new FormData();

        switch (Type) {
            case 1: {
                requestjson.Module = 'cms_labels_details';
                requestjson.Parameters = {
                    LabelIDs: params.LabelIDs
                };
                break;
            }
        }

        if (Object.keys(newState).length > 0) {
            processRequest();
        } else {
            processRequest();
        }
    }

    static getMapDispatchProps = dispatch => ({
        insertToLabels: (data) => dispatch(labelActions.insertToLabels(data)),
    });

    static getMapStateToProps = state => ({
        labelReducer: state.LabelReducer,
        userSettingsReducer: state.UserSettingsReducer
    });
}
