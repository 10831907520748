import React, { Component } from 'react';
import { hydrate, createPortal } from 'react-dom';
import { renderToString } from 'react-dom/server';
import PropTypes from "prop-types";
import { closeModal, genericQuery, iziToastFn, JqTreeInitialize, jQTreeDestroy, openModal, jQTreeGetNodeByID, jQTreeUpdateNode, jQTreeGetTree, encodeToBtoa, jQTreeOpenOrCloseTree, processLabel } from '../../../helpers/helper';
import { formGenerator_type1, getGenericAttributeListType1, getValueByKeyFromModuleTemplate } from '../../../helpers/formHelpers';
import { LoadingBeatLoader_Absolute } from '../';
require('./index.scss');

const texts = [
    'Set Location Access', // 0
    'Include', // 1
    'Exclude', // 2
    'Included', // 3
    'Excluded', // 4
    'Select locations here' // 5
], elementIDs = [
    'modal-LocationListByRID', // 0
    'tree-LocationListByRID', // 1
    'cB_inheritance-', // 2
    'cB_include-', // 3
    'cB_exclude-', // 4
    'cB_inheritanceContainer-', // 5
    'cB_includeContainer-', // 6
    'cB_excludeContainer-', // 7
    'modal-LocationListByDBOID', // 8
    'tree-LocationListByDBOID' // 9
], modules = ['generic_location_access-location'],
    _defaultCBJson = {
        className: "",
        value: false,
        containerID: "",
        ID: "",
        containerClassName: "",
        onClick: null
    };

export default class LocationListByRID extends Component {
    constructor(props) {
        super(props);

        this.defaultState = {
            showModal: false,
            formTemplate: {},
            locationList: [],
            locationAttributes: [],
            isLocationListLoading: false,
            isGloballyAvailableObjectAttributeID: null
        };

        this.onClickButtonTrigger = this.onClickButtonTrigger.bind(this);
        this.onCreateLi = this.onCreateLi.bind(this);
        this.initializeJqTreeInitializeFn = this.initializeJqTreeInitializeFn.bind(this);
        this.getGenericAttributeList = getGenericAttributeList.bind(this);
        this.getDefaultState = getDefaultState.bind(this);

        this.state = {
            ...this.getDefaultState()
        };

        texts.map(text => {
            text = processLabel({ LabelID: null, DefaultLabel: text })
            return text
        })
    }

    render() {
        const _this = this,
            { renderButtonTriggerType, renderModalContentType } = _this.props,
            { showModal } = _this.state;
        let renderButtonTrigger,
            renderModalContent;

        switch (renderButtonTriggerType) {
            case 1: {
                renderButtonTrigger = (
                    <a title={texts[0]} onClick={_this.onClickButtonTrigger} style={{ marginRight: "1rem" }}><i className="fa fa-map-marker"></i></a>
                );
                break;
            }
            case 2: {
                renderButtonTrigger = (
                    <li onClick={_this.onClickButtonTrigger}><a>{texts[0]}</a></li>
                );
                break;
            }
            case 3: {
                renderButtonTrigger = (
                    <a title={texts[0]} className="" onClick={_this.onClickButtonTrigger} style={{ marginRight: "1rem" }}><i className="fa fa-map-marker"></i></a>
                );
                break;
            }
            default: {
                renderButtonTrigger = (
                    <button className="button is-small -primary-btn-lms" title={texts[0]} style={{ marginRight: "1rem" }} onClick={_this.onClickButtonTrigger}>
                        <i className="fa fa-map-marker pr-0"></i>
                    </button>
                );
                break;
            }
        }

        if (showModal) {
            const modalID = _this.getElementID(0);
            switch (renderModalContentType) {
                case 1: {
                    // renderModalContent = this.renderModalContentType_1();
                    break;
                }
                default: {
                    renderModalContent = this.renderModalContentType_default();
                    break;
                }
            }

            renderModalContent = (createPortal(
                <div id={modalID} className="modal modal-md -lms-modal-primary locationModule__GcNfWv">
                    <div className="modal-background is-translucent"></div>
                    <div className="modal-card modal-content">
                        <div className="flex-card simple-shadow oflow-unset">
                            <header className="modal-card-head">
                                <div className="modal-card-title">
                                    {texts[0]}
                                </div>
                                <button className="delete is-medium" aria-label="close" onClick={() => {
                                    closeModal(modalID);
                                    this.setState({ ...this.getDefaultState() });
                                    jQTreeDestroy({
                                        id: _this.getElementID(1)
                                    });
                                }}></button>
                            </header>
                            <section className="modal-card-body">
                                {renderModalContent}
                            </section>
                        </div>
                    </div>
                </div>
                , document.getElementById("root"))
            );
        }


        return (
            <>
                {renderButtonTrigger}
                {renderModalContent}
            </>
        );
    }

    renderModalContentType_default() {
        const { formTemplate, isGloballyAvailableObjectAttributeID, isLocationListLoading } = this.state;
        let treeContainerID = this.getElementID(1),
            locationListContainerClassName = "is-hidden",
            isGloballyAvailable = "",
            isGloballyAvailableRW = null,
            renderLoading,
            renderFormGenerator,
            renderFormGeneratorContainerClassName = "";

        if (formTemplate[modules[0]] && isGloballyAvailableObjectAttributeID) {
            isGloballyAvailable = getValueByKeyFromModuleTemplate({
                referredAttributeID: isGloballyAvailableObjectAttributeID,
                moduleTemplateDataModule: modules[0],
                key: 'Value',
                component: this
            });

            isGloballyAvailableRW = getValueByKeyFromModuleTemplate({
                referredAttributeID: isGloballyAvailableObjectAttributeID,
                moduleTemplateDataModule: modules[0],
                key: 'RW',
                component: this
            });

            if (isGloballyAvailable == 0) {
                locationListContainerClassName = "";
            }

            if (isGloballyAvailableRW != 2) {
                renderFormGeneratorContainerClassName = 'is-disabled';
            }
        }

        if (isLocationListLoading) {
            renderLoading = (<LoadingBeatLoader_Absolute />);
        }

        renderFormGenerator = (
            <div className={renderFormGeneratorContainerClassName}>
                {formGenerator_type1({
                    FormTemplate: formTemplate[modules[0]],
                    Component: this
                })}
            </div>
        );

        return (
            <>
                {renderLoading}
                {renderFormGenerator}
                <div className={locationListContainerClassName}>
                    <div>
                        <button className="button is-small -primary-btn-lms pr-0" onClick={onClickTreeTogglerBtn.bind(this, { id: treeContainerID, isOpen: false })}><i className="fa fa-caret-up"></i></button>
                        <button className="button is-small -primary-btn-lms pr-0" onClick={onClickTreeTogglerBtn.bind(this, { id: treeContainerID, isOpen: true })}><i className="fa fa-caret-down"></i></button>
                    </div>
                    <div className="div__xzWNNp">
                        <span>{texts[1]}</span>
                        <span>{texts[2]}</span>
                    </div>
                    <div id={treeContainerID} className="treecontainer__AQwnwF"></div>
                </div>
            </>
        );
    }

    onClickButtonTrigger() {
        this.setState({
            showModal: true
        }, () => {
            openModal(this.getElementID(0));
            this.moduleQuery({ type: 0 });
        });
    }

    getElementID(index) {
        const { RIDReference, renderModalContentType } = this.props;
        let elementID;

        switch (renderModalContentType) {
            case 1:
                elementID = `${elementIDs[index]}-renderModalContentType-${renderModalContentType}`;
                break;
            default:
                elementID = `${elementIDs[index]}-${RIDReference}`;
                break;
        }

        return elementID;
    }

    moduleQuery(params) {
        const _this = this,
            { RIDReference } = _this.props,
            state = _this.state,
            { type, genericLocationUpdateParams } = params,
            { } = state;
        let Parameters = {},
            requestjson = {},
            config = {
                Data: { requestjson },
                Method: "GET",
                ResponseSuccessCallback: responseSuccessCallback,
                ResponseFailCallback: responseFailCallback,
                Url: "/single_api/"
            },
            newState = {},
            formData = new FormData();

        switch (type) {
            case 0: {
                requestjson.Module = 'generic_location_access-location';
                Parameters = {
                    RID: RIDReference
                };
                newState['isLocationListLoading'] = true;

                break;
            }
            case 1: {
                requestjson.Module = 'generic_location_update';
                Parameters = {
                    ...genericLocationUpdateParams
                };

                break;
            }
        }

        if (Object.keys(newState).length > 0) {
            _this.setState(newState, () => {
                processRequest();
            });
        } else {
            processRequest();
        }

        function processRequest() {
            requestjson.Parameters = Parameters;
            newState = {};
            config = genericQuery(config);
        }

        function responseSuccessCallback(responseJson) {
            const { state } = _this;
            let data = responseJson.data,
                status = data.Status,
                callbackType;

            if (status.IsSuccess) {
                let _data = data.Data,
                    hierarchy,
                    attributes,
                    location,
                    isGloballyAvailableObjectAttributeID;

                if (_data) {
                    hierarchy = _data.Hierarchy;
                    attributes = _data.Attributes;
                    isGloballyAvailableObjectAttributeID = _data.IsGloballyAvailableObjectAttributeID;
                    location = _data.Location || {};
                }

                switch (type) {
                    case 0: {
                        let locationList = hierarchy || [],
                            locationAttributes = attributes || [];

                        if (locationList) {
                            locationList = trimLocationTreeHasAccess(locationList);
                        }

                        callbackType = 1;
                        newState['locationList'] = locationList;
                        newState['locationAttributes'] = locationAttributes;
                        newState['isGloballyAvailableObjectAttributeID'] = isGloballyAvailableObjectAttributeID;
                        newState['isLocationListLoading'] = false;
                        break;
                    }
                    case 1: {
                        let node = jQTreeGetNodeByID({
                            id: _this.getElementID(1),
                            nodeID: location.ID
                        });

                        if (node) {
                            jQTreeUpdateNode({
                                id: _this.getElementID(1),
                                data: location,
                                node
                            });
                        }
                        break;
                    }
                }
            } else {
                iziToastFn({
                    mode: 3,
                    message: status.Message
                });

                switch (type) {
                    case 0: {
                        newState['isLocationListLoading'] = false;
                        break;
                    }
                }
            }

            _this.setState(newState, () => {
                if (callbackType) {
                    newState = {};

                    switch (callbackType) {
                        case 1: {
                            const { locationList, locationAttributes } = _this.state,
                                treeContainerID = _this.getElementID(1);

                            getGenericAttributeListType1({
                                attributeJSON: locationAttributes,
                                template: 'updateGenericLocationAccess.json',
                                callBack: _this.getGenericAttributeList
                            });

                            JqTreeInitialize({
                                id: treeContainerID,
                                data: locationList,
                                autoOpen: false,
                                onCreateLi: _this.onCreateLi,
                                onRefresh: _this.initializeJqTreeInitializeFn
                            });
                            _this.initializeJqTreeInitializeFn();

                            break;
                        }
                        default:
                            break;
                    }
                }
            });
        }

        function responseFailCallback(responseJson) {
            let callbackType;
            console.log("responseFailCallback", responseJson);

            if (responseJson.message !== "change request") {
                iziToastFn({
                    mode: 3,
                    message: responseJson.message
                });

                switch (type) {
                    case 0: {
                        break;
                    }
                }

                _this.setState(newState, () => {
                    if (callbackType) {
                        newState = {};
                    }
                });
            }
        }
    }

    initializeJqTreeInitializeFn() {
        const { locationList } = this.state,
            treeElements = jQTreeGetTree({ id: this.getElementID(1) });

        if (treeElements) {
            this.traverseLocationJSON(treeElements.children);
        }
    }

    traverseLocationJSON(children) {
        if (Array.isArray(children)) {
            const defaultCBJson = _defaultCBJson;

            children.forEach(child => {
                const childID = child.ID,
                    inheritedAccess = child.InheritedAccess;
                let cBInclude = JSON.parse(JSON.stringify(defaultCBJson)),
                    cBExclude = JSON.parse(JSON.stringify(defaultCBJson));

                cBInclude.ID = `${elementIDs[3]}${childID}`;
                cBInclude.containerID = `${elementIDs[6]}${childID}`;
                cBInclude.isInclude = true;
                cBInclude.className = "switch is-primary is-small";
                cBInclude = { ...cBInclude, item: child };

                cBExclude.ID = `${elementIDs[4]}${childID}`;
                cBExclude.containerID = `${elementIDs[7]}${childID}`;
                cBExclude.isExclude = true;
                cBExclude.className = "switch is-danger is-small";
                cBExclude = { ...cBExclude, item: child };

                if (child.IncludeLocation) {
                    cBInclude.value = true;
                } else if (child.ExcludeLocation) {
                    cBExclude.value = true;
                } else {
                    if (inheritedAccess == 1) {
                        cBInclude.value = true;
                        cBInclude.className = "switch is-semi-primary is-small";
                    } else if (inheritedAccess == 0) {
                        cBExclude.value = true;
                        cBExclude.className = "switch is-semi-danger is-small";
                    }
                }

                if (!child.HasAccess) {
                    cBExclude.containerClassName = cBInclude.containerClassName = `is-disabled`;
                }

                if (child.HasAccess) {
                    cBInclude.onClick = this.onChangeRenderBtn.bind(this, cBInclude);
                    cBExclude.onClick = this.onChangeRenderBtn.bind(this, cBExclude);

                    hydrate(renderIncludeExcludeBtn(cBInclude), document.getElementById(cBInclude.containerID));
                    hydrate(renderIncludeExcludeBtn(cBExclude), document.getElementById(cBExclude.containerID));
                }

                this.traverseLocationJSON(child.children);
            });
        }
    }

    renderIncludeExcludeBtn(params) {
        const { item } = params;

        return (
            <>
                <input id={params.ID} className={params.className} type="checkbox" defaultChecked={params.value} onClick={item && item.HasAccess ? this.onChangeRenderBtn.bind(this, params) : null} />
                <label htmlFor={params.ID} className="form-label" />
            </>
        );
    }

    onCreateLi(node, $li) {
        let $spanJqTreeTitle = $li.find('span.jqtree-title');

        if ($spanJqTreeTitle) {
            const nodeID = node.ID,
                inheritedAccess = node.InheritedAccess;
            let defaultCBJson = _defaultCBJson,
                cBInheritance = JSON.parse(JSON.stringify(defaultCBJson)),
                cBInclude = JSON.parse(JSON.stringify(defaultCBJson)),
                cBExclude = JSON.parse(JSON.stringify(defaultCBJson));

            $spanJqTreeTitle.empty();

            if (node.LocationTypeID == 1) {
                $spanJqTreeTitle.append(renderToString(<i className="fa fa-adjust i__RquyAm"></i>));
            } else if (node.LocationTypeID == 2) {
                $spanJqTreeTitle.append(renderToString(<i className="fa fa-globe i__RquyAm"></i>));
            } else {
                $spanJqTreeTitle.append(renderToString(<i className="fa fa-map-marker i__RquyAm"></i>));
            }

            $spanJqTreeTitle.append(renderToString(<span className={`span__qWTxrp${node.HasAccess ? "" : " span__AGfsTH"}`}>{node.name}</span>));

            cBInheritance.className = "switch is-small";

            cBInclude.ID = `${elementIDs[3]}${nodeID}`;
            cBInclude.className = "switch is-primary is-small";
            cBInclude.containerID = `${elementIDs[6]}${nodeID}`;

            cBExclude.ID = `${elementIDs[4]}${nodeID}`;
            cBExclude.className = "switch is-danger is-small";
            cBExclude.containerID = `${elementIDs[7]}${nodeID}`;

            if (node.InheritedAccess != null && node.IncludeLocation == null && node.ExcludeLocation == null) {
                cBInheritance.value = true;

                switch (node.InheritedAccess) {
                    case 0:
                        cBInheritance.className = `${cBInheritance.className} is-danger`;
                        break;
                    case 1:
                        cBInheritance.className = `${cBInheritance.className} is-primary`;
                        break;
                }
            }

            if (node.IncludeLocation) {
                cBInclude.value = true;
            } else if (node.ExcludeLocation) {
                cBExclude.value = true;
            } else {
                if (inheritedAccess == 1) {
                    cBInclude.value = true;
                    cBInclude.className = "switch is-semi-primary is-small";
                } else if (inheritedAccess == 0) {
                    cBExclude.value = true;
                    cBExclude.className = "switch is-semi-danger is-small";
                }
            }

            if (!node.HasAccess) {
                cBExclude.containerClassName = cBInclude.containerClassName = `is-disabled`;
            }

            $spanJqTreeTitle.append(renderToString(
                <div className="switches__BnzBvK">
                    <div id={cBInclude.containerID} className={cBInclude.containerClassName}>
                        {renderIncludeExcludeBtn(cBInclude)}
                    </div>
                    <div id={cBExclude.containerID} className={cBExclude.containerClassName}>
                        {renderIncludeExcludeBtn(cBExclude)}
                    </div>
                </div>
            ));
        }
    }

    onChangeRenderBtn(params, event) {
        const { RIDReference } = this.props,
            { item, isInclude, isExclude } = params,
            isChecked = event.target.checked;
        let genericLocationUpdateParams = {};

        genericLocationUpdateParams.RID = RIDReference;
        genericLocationUpdateParams.LocationID = item.ID;

        if (isInclude) {
            if (isChecked) {
                genericLocationUpdateParams.IncludeLocation = isChecked;
            } else {
                if (item.ExcludeLocation == null && item.IncludeLocation == null) {
                    genericLocationUpdateParams.ExcludeLocation = true;
                } else {
                    genericLocationUpdateParams.IsDeleted = true;
                }
            }
        } else if (isExclude) {
            if (isChecked) {
                genericLocationUpdateParams.ExcludeLocation = isChecked;
            } else {
                if (item.ExcludeLocation == null && item.IncludeLocation == null) {
                    genericLocationUpdateParams.IncludeLocation = true;
                } else {
                    genericLocationUpdateParams.IsDeleted = true;
                }
            }
        }

        this.moduleQuery({
            type: 1,
            genericLocationUpdateParams
        });
    }

    static propTypes = {
        courseCatalogID: PropTypes.number,
        renderButtonTriggerType: PropTypes.number,
        renderModalContentType: PropTypes.number
    }
};

class LMS_LocationList extends React.Component {
    constructor(props) {
        super(props);

        this.defaultState = {
            locationList: [],
            selected: {
                Included: [],
                Excluded: []
            },
            isLocationListLoading: false
        }

        this.onClickButtonTrigger = this.onClickButtonTrigger.bind(this);
        this.onCreateLi = this.onCreateLi.bind(this);
        this.initializeJqTreeInitializeFn = this.initializeJqTreeInitializeFn.bind(this);
        this.getDefaultState = getDefaultState.bind(this);

        this.state = { ...this.getDefaultState() };
    }

    render() {
        const _this = this,
            props = _this.props,
            { id, renderContainerType, renderModalContentType } = props,
            { selected } = _this.state,
            modalID = _this.getElementID(8);
        let renderContainer,
            renderModalContent,
            renderSelectedValue;

        if (selected.Excluded.length || selected.Included.length) {
            let selectedIncluded = [],
                selectedExcluded = [];

            selected.Included.forEach(_item => {
                selectedIncluded.push(_item.ID);
            });

            selected.Excluded.forEach(_item => {
                selectedExcluded.push(_item.ID);
            });
            renderSelectedValue = encodeToBtoa(JSON.stringify({ Included: selectedIncluded, Excluded: selectedExcluded }));
        }

        switch (renderContainerType) {
            case 1: {
                let renderIncluded = this.renderSelectedLocations(1),
                    renderExcluded = this.renderSelectedLocations(0),
                    renderContainerItems;

                if (renderIncluded || renderExcluded) {
                    renderContainerItems = (<>{renderIncluded}{renderExcluded}</>);
                } else {
                    renderContainerItems = (<p className="instruction">{texts[5]}</p>)
                }

                renderContainer = (
                    <div data-module={props['data-module']} data-ref={props['data-ref']} data-moduletemplateattribute={props['data-moduletemplateattribute']} id={id} className="locationModule__utzLkt" onClick={_this.onClickButtonTrigger} data-value={renderSelectedValue}>
                        {renderContainerItems}
                    </div>
                );
                break;
            }
        }

        switch (renderModalContentType) {
            case 1: {
                renderModalContent = this.renderModalContentType_1();
                break;
            }
        }

        renderModalContent = (createPortal(
            <div id={modalID} className="modal modal-md -lms-modal-primary locationModule__GcNfWv">
                <div className="modal-background is-translucent"></div>
                <div className="modal-card modal-content">
                    <div className="flex-card simple-shadow oflow-unset">
                        <header className="modal-card-head">
                            <div className="modal-card-title">
                                {texts[0]}
                            </div>
                            <button className="delete is-medium" aria-label="close" onClick={() => {
                                closeModal(modalID);
                                this.setState({ showModal: false });
                            }}></button>
                        </header>
                        <section className="modal-card-body">
                            {renderModalContent}
                        </section>
                    </div>
                </div>
            </div>
            , document.getElementById("root"))
        );

        return (
            <>
                {renderContainer}
                {renderModalContent}
            </>
        );
    }

    renderModalContentType_1() {
        const { isLocationListLoading } = this.state;
        let treeContainerID = this.getElementID(9),
            renderLoading;

        if (isLocationListLoading) {
            renderLoading = (<LoadingBeatLoader_Absolute />);
        }

        return (
            <>
                {renderLoading}
                <div>
                    <div>
                        <button className="button is-small -primary-btn-lms pr-0" onClick={onClickTreeTogglerBtn.bind(this, { id: treeContainerID, isOpen: false })}><i className="fa fa-caret-up"></i></button>
                        <button className="button is-small -primary-btn-lms pr-0" onClick={onClickTreeTogglerBtn.bind(this, { id: treeContainerID, isOpen: true })}><i className="fa fa-caret-down"></i></button>
                    </div>
                    <div className="div__xzWNNp">
                        <span>{texts[1]}</span>
                        <span>{texts[2]}</span>
                    </div>
                    <div id={treeContainerID} className="treecontainer__AQwnwF"></div>
                </div>
            </>
        );
    }

    renderSelectedLocations(type) {
        const { selected } = this.state;
        let selectedArray = [],
            renderTitleText = "",
            renderSelectedArray;

        switch (type) {
            case 0:
                selectedArray = selected.Excluded;
                renderTitleText = texts[4];
                break;
            case 1:
                selectedArray = selected.Included;
                renderTitleText = texts[3];
                break;
        }

        if (selectedArray.length) {
            selectedArray.sort((a, b) => {
                if (a.name > b.name)
                    return 1;
                else if (a.name < b.name)
                    return 0;


                return -1;
            });

            renderSelectedArray = (
                <div>
                    <span className="title__tqDYbD">{renderTitleText}</span>
                    {
                        selectedArray.map(_item => {
                            return (<div key={_item.id}>{_item.name}</div>)
                        })
                    }
                </div>
            )
        }

        return renderSelectedArray;
    }

    onClickButtonTrigger() {
        this.setState({
            showModal: true
        }, () => {
            const { locationList } = this.state;

            openModal(this.getElementID(8));

            if (locationList.length == 0) {
                this.moduleQuery({ type: 0 });
            }
        });
    }

    onCreateLi(node, $li) {
        let $spanJqTreeTitle = $li.find('span.jqtree-title');

        if ($spanJqTreeTitle) {
            const nodeID = node.ID,
                inheritedAccess = node.InheritedAccess;
            let defaultCBJson = _defaultCBJson,
                cBInclude = JSON.parse(JSON.stringify(defaultCBJson)),
                cBExclude = JSON.parse(JSON.stringify(defaultCBJson));

            $spanJqTreeTitle.empty();

            if (node.LocationTypeID == 1) {
                $spanJqTreeTitle.append(renderToString(<i className="fa fa-adjust i__RquyAm"></i>));
            } else if (node.LocationTypeID == 2) {
                $spanJqTreeTitle.append(renderToString(<i className="fa fa-globe i__RquyAm"></i>));
            } else {
                $spanJqTreeTitle.append(renderToString(<i className="fa fa-map-marker i__RquyAm"></i>));
            }

            $spanJqTreeTitle.append(renderToString(<span className={`span__qWTxrp${node.HasAccess ? "" : " span__AGfsTH"}`}>{node.name}</span>));

            cBInclude.ID = `${elementIDs[3]}${nodeID}`;
            cBInclude.className = "switch is-primary is-small";
            cBInclude.containerID = `${elementIDs[6]}${nodeID}`;

            cBExclude.ID = `${elementIDs[4]}${nodeID}`;
            cBExclude.className = "switch is-danger is-small";
            cBExclude.containerID = `${elementIDs[7]}${nodeID}`;

            if (node.IncludeLocation) {
                cBInclude.value = true;
            } else if (node.ExcludeLocation) {
                cBExclude.value = true;
            } else {
                if (inheritedAccess == 1) {
                    cBInclude.value = true;
                    cBInclude.className = "switch is-semi-primary is-small";
                } else if (inheritedAccess == 0) {
                    cBExclude.value = true;
                    cBExclude.className = "switch is-semi-danger is-small";
                }
            }

            if (!node.HasAccess) {
                cBExclude.containerClassName = cBInclude.containerClassName = `is-disabled`;
            }

            $spanJqTreeTitle.append(renderToString(
                <div className="switches__BnzBvK">
                    <div id={cBInclude.containerID} className={cBInclude.containerClassName}>
                        {renderIncludeExcludeBtn(cBInclude)}
                    </div>
                    <div id={cBExclude.containerID} className={cBExclude.containerClassName}>
                        {renderIncludeExcludeBtn(cBExclude)}
                    </div>
                </div>
            ));
        }
    }

    onChangeRenderBtn(params, event) {
        const { selected } = this.state,
            { isInclude, isExclude } = params,
            isChecked = event.target.checked,
            treeID = this.getElementID(9);
        let { item } = params,
            { Included, Excluded } = selected,
            genericLocationUpdateParams = {},
            nodeData,
            parentAccess = null,
            nodeIndexInArray = -1;

        if (isInclude) {
            if (isChecked) {
                item.IncludeLocation = isChecked;
                item.ExcludeLocation = !isChecked;
                parentAccess = 1;
            } else {
                if (item.ExcludeLocation == null && item.IncludeLocation == null) {
                    item.ExcludeLocation = true;
                    parentAccess = 0;
                } else {
                    item.IncludeLocation = isChecked;
                }
            }
        } else if (isExclude) {
            if (isChecked) {
                item.ExcludeLocation = isChecked;
                item.IncludeLocation = !isChecked;
                parentAccess = 0;
            } else {
                if (item.ExcludeLocation == null && item.IncludeLocation == null) {
                    item.IncludeLocation = true;
                    parentAccess = 1;
                } else {
                    item.ExcludeLocation = isChecked;
                }
            }
        }

        nodeData = item.getData(true);

        if (nodeData) {
            nodeData = nodeData[0];
        }

        nodeData.children = treeChangeInheritedAccess({
            children: nodeData.children,
            parentAccess
        });

        if (item.IncludeLocation) {
            Included.push(nodeData);

            nodeIndexInArray = Excluded.findIndex(_item => {
                return item.ID == _item.ID;
            });

            if (nodeIndexInArray != -1) {
                Excluded.splice(nodeIndexInArray, 1);
            }
        } else if (item.ExcludeLocation) {
            Excluded.push(nodeData);

            nodeIndexInArray = Included.findIndex(_item => {
                return item.ID == _item.ID;
            });

            if (nodeIndexInArray != -1) {
                Included.splice(nodeIndexInArray, 1);
            }
        } else {
            let nodeIndexInArray = -1;

            nodeIndexInArray = Included.findIndex(_item => {
                return item.ID == _item.ID;
            });

            if (nodeIndexInArray != -1) {
                Included.splice(nodeIndexInArray, 1);
            } else {
                nodeIndexInArray = Excluded.findIndex(_item => {
                    return item.ID == _item.ID;
                });

                if (nodeIndexInArray != -1) {
                    Excluded.splice(nodeIndexInArray, 1);
                }
            }
        }

        this.setState({
            selected: {
                Included,
                Excluded
            }
        }, () => {
            jQTreeUpdateNode({
                id: treeID,
                data: nodeData,
                node: item
            });
        });
    }

    initializeJqTreeInitializeFn() {
        const { locationList } = this.state,
            treeElements = jQTreeGetTree({ id: this.getElementID(9) });

        if (treeElements) {
            this.traverseLocationJSON(treeElements.children);
        }
    }

    traverseLocationJSON(children) {
        if (Array.isArray(children)) {
            const defaultCBJson = _defaultCBJson;

            children.forEach(child => {
                const childID = child.ID,
                    inheritedAccess = child.InheritedAccess;
                let cBInclude = JSON.parse(JSON.stringify(defaultCBJson)),
                    cBExclude = JSON.parse(JSON.stringify(defaultCBJson));

                cBInclude.ID = `${elementIDs[3]}${childID}`;
                cBInclude.containerID = `${elementIDs[6]}${childID}`;
                cBInclude.isInclude = true;
                cBInclude.className = "switch is-primary is-small";
                cBInclude = { ...cBInclude, item: child };

                cBExclude.ID = `${elementIDs[4]}${childID}`;
                cBExclude.containerID = `${elementIDs[7]}${childID}`;
                cBExclude.isExclude = true;
                cBExclude.className = "switch is-danger is-small";
                cBExclude = { ...cBExclude, item: child };

                if (child.IncludeLocation) {
                    cBInclude.value = true;
                } else if (child.ExcludeLocation) {
                    cBExclude.value = true;
                } else {
                    if (inheritedAccess == 1) {
                        cBInclude.value = true;
                        cBInclude.className = "switch is-semi-primary is-small";
                    } else if (inheritedAccess == 0) {
                        cBExclude.value = true;
                        cBExclude.className = "switch is-semi-danger is-small";
                    }
                }

                if (child.HasAccess) {
                    cBInclude.onClick = this.onChangeRenderBtn.bind(this, cBInclude);
                    cBExclude.onClick = this.onChangeRenderBtn.bind(this, cBExclude);

                    hydrate(renderIncludeExcludeBtn(cBInclude), document.getElementById(cBInclude.containerID));
                    hydrate(renderIncludeExcludeBtn(cBExclude), document.getElementById(cBExclude.containerID));
                }

                this.traverseLocationJSON(child.children);
            });
        }
    }

    getElementID(index) {
        const { renderModalContentType } = this.props;
        let elementID;

        switch (renderModalContentType) {
            case 1:
                elementID = `${elementIDs[index]}-renderModalContentType-${renderModalContentType}`;
                break;
            default:
                elementID = `${elementIDs[index]}-`;
                break;
        }

        return elementID;
    }

    moduleQuery(params) {
        const _this = this,
            { attribute } = _this.props,
            state = _this.state,
            { type } = params,
            { } = state;
        let Parameters = {},
            requestjson = {},
            config = {
                Data: { requestjson },
                Method: "GET",
                ResponseSuccessCallback: responseSuccessCallback,
                ResponseFailCallback: responseFailCallback,
                Url: "/single_api/"
            },
            newState = {},
            formData = new FormData();

        switch (type) {
            case 0: {
                let listValue = JSON.parse(attribute.ListValue);

                if (listValue.IsSingleAPI) {
                    requestjson.Module = listValue.Module;
                    Parameters = {
                        ObjectType: listValue.ObjectType
                    };
                    newState['isLocationListLoading'] = true;
                }
                break;
            }
        }

        if (Object.keys(newState).length > 0) {
            _this.setState(newState, () => {
                processRequest();
            });
        } else {
            processRequest();
        }

        function processRequest() {
            requestjson.Parameters = Parameters;
            newState = {};
            config = genericQuery(config);
        }

        function responseSuccessCallback(responseJson) {
            const { state } = _this;
            let data = responseJson.data,
                status = data.Status,
                callbackType;

            if (status.IsSuccess) {
                let _data = data.Data,
                    hierarchy;

                if (_data) {
                    hierarchy = _data.Hierarchy;
                }

                switch (type) {
                    case 0: {
                        let locationList = hierarchy || [];

                        if (locationList) {
                            locationList = trimLocationTreeHasAccess(locationList);
                        }

                        callbackType = 1;
                        newState['locationList'] = locationList;
                        newState['isLocationListLoading'] = false;
                        break;
                    }
                }
            } else {
                iziToastFn({
                    mode: 3,
                    message: status.Message
                });

                switch (type) {
                    case 0: {
                        newState['isLocationListLoading'] = false;
                        break;
                    }
                }
            }

            _this.setState(newState, () => {
                if (callbackType) {
                    newState = {};

                    switch (callbackType) {
                        case 1: {
                            const { locationList, locationAttributes } = _this.state,
                                treeContainerID = _this.getElementID(9);

                            JqTreeInitialize({
                                id: treeContainerID,
                                data: locationList,
                                autoOpen: false,
                                onCreateLi: _this.onCreateLi,
                                onRefresh: _this.initializeJqTreeInitializeFn
                            });
                            _this.initializeJqTreeInitializeFn();
                        }
                    }
                }
            });
        }

        function responseFailCallback(responseJson) {
            let callbackType;
            console.log("responseFailCallback", responseJson);
        }
    }
}

function treeChangeInheritedAccess(params) {
    const { children, parentAccess } = params;

    if (Array.isArray(children)) {
        for (let childrenIndex = 0; childrenIndex < children.length; childrenIndex++) {
            const child = children[childrenIndex];
            let inheritedAccess = null;

            child.InheritedAccess = parentAccess;

            if (child.IncludeLocation) {
                inheritedAccess = 1;
            } else if (child.ExcludeLocation) {
                inheritedAccess = 0;
            } else {
                inheritedAccess = parentAccess;
            }

            child.children = treeChangeInheritedAccess({
                children: child.children,
                parentAccess: inheritedAccess
            });
        }
    }


    return children || [];
}

function renderIncludeExcludeBtn(params) {
    const { item } = params;

    return (
        <>
            <input id={params.ID} className={params.className} type="checkbox" defaultChecked={params.value} onClick={params.onClick} />
            <label htmlFor={params.ID} className="form-label" />
        </>
    );
}

function trimLocationTreeHasAccess(data) {
    let x = data.length;
    while (x--) {
        let parentData = data[x];
        if (Array.isArray(parentData.children) && parentData.children.length > 0) {
            let res = trimLocationTreeHasAccess(parentData.children);
            if (res.length > 0) {
                data[x].children = res;
            } else {
                if (!parentData.HasAccess) {
                    data.splice(x, 1);
                }
            }
        } else {
            if (!parentData.HasAccess) {
                data.splice(x, 1);
            }
        }
    }

    return data;
}

function onClickTreeTogglerBtn(params, event) {
    jQTreeOpenOrCloseTree(params);
}

function getGenericAttributeList(params) {
    const _this = this,
        state = _this.state,
        moduleTemplate = params.ModuleTemplate,
        responseJson = params.responseJson,
        attributeJSON = params.attributeJSON,
        attributesFK = params.attributesFK;

    if (responseJson) {
        const data = responseJson.data;

        if (data) {
            const status = data.Status;

            if (status.IsSuccess) {
                const _data = data.Data;
                let attributes = _data.Attributes;

                processFormTemplate({
                    attributes,
                    attributesFK
                });

            } else {
                iziToastFn({
                    mode: 3,
                    title: "Error",
                    message: status.Message
                });
            }
        }
    } else if (attributeJSON) {
        processFormTemplate({
            attributes: attributeJSON,
            attributesFK
        });
    }

    function processFormTemplate(_params) {
        const dataModule = moduleTemplate.DataModule,
            attributes = _params.attributes,
            attributesFK = _params.attributesFK;
        let newState = {};
        newState['formTemplate'] = state.formTemplate;

        if (dataModule) {
            newState['formTemplate'][dataModule] = {
                ModuleTemplate: moduleTemplate,
                Attributes: attributes,
                Values: {},
                AttributesFK: attributesFK
            };
        }

        _this.setState(newState);
    }
}

function getDefaultState() {
    return JSON.parse(JSON.stringify(this.defaultState));
}

export {
    LocationListByRID,
    LMS_LocationList
};