import React, { useState } from 'react';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import CKEditor from "@ckeditor/ckeditor5-react";
import Dropzone from 'react-dropzone';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { MediaLibraryFormModule, LMS_LocationList } from '../component/imports';
import { API_URL, MEDIA_LIB_URL } from './config';
import { getToken, getEmployeeID } from './localStorage';
import {
    genericQuery, searchAttributeByDBObjectAttributeID,
    encodeToAtob, encodeToBtoa, select2Initialize, select2SetSelectedData,
    formElemValueGetter, formElemValueSetter,
    iziToastFn, select2TriggerEvent, searchAttributeByDBObjectAttributeIDAndRID, onDeleteByRID,
    formatBytes,
    GetLabelv2,
    processLabel
} from './helper';

// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../plugins/bulkit/scss/partials/customs/_lms-dropzone.scss";
import "react-datepicker/dist/react-datepicker.css";
import { createPortal } from 'react-dom';

const texts = [
    "Upload or drag 'n' drop some file(s) here", // 0
    "Please check your input", // 1
    "Select a time", // 2
    "Remove", // 3
], inputMessageType = ['is-info', 'is-success', 'is-warning', 'is-danger'],
    classNameControl = [
        'is-hidden', 'is-loading', 'is-disabled'
    ];
let toBeInitialized = {};

export const getGenericAttributeListType1 = (params) => {
    const attributeGroupID = params.attributeGroupID,
        attributesFK = params.attributesFK,
        attributeJSON = params.attributeJSON,
        template = params.template;

    if (!attributeJSON) {
        let requestjson = {
            Module: 'generic_generic_list-for-add',
            Parameters: {
                AttributeGroupID: attributeGroupID
            }
        }, config = {
            Data: { requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };

        genericQuery(config);
    } else {
        getTemplate({
            attributeJSON, attributesFK
        });
    }

    function responseSuccessCallback(responseJson) {

        if (template) {
            getTemplate({
                responseJson, attributesFK
            });
        } else {
            params.callBack({ responseJson });
        }
    }

    function responseFailCallback(responseJson) {
        params.callBack({ responseJson });
    }

    function getTemplate(_params) {
        let responseJson = _params.responseJson,
            attributeJSON = _params.attributeJSON,
            attributesFK = _params.attributesFK;

        // responseJson.data.Data.Attributes.forEach(item => {
        //     console.log(item.DefaultLabel)
        // })
        fetch(`${process.env.PUBLIC_URL}/data/${template}`)
            .then((r) => r.json())
            .then((moduleTemplate) => {
                if (moduleTemplate.IsGroup && moduleTemplate.IsNotYeTInitialized && moduleTemplate.Groups != null) {
                    let initializedGroup = initializeAttributesBasedOnGroup({
                        attributes: attributeJSON,
                        moduleTemplateGroups: moduleTemplate.Groups
                    });

                    if (!moduleTemplate.Attributes) {
                        moduleTemplate.Attributes = [];
                    }

                    moduleTemplate.Attributes.push(...initializedGroup)
                }

                params.callBack({
                    responseJson,
                    attributeJSON,
                    attributesFK,
                    ModuleTemplate: moduleTemplate
                });
            })
            .catch((error) => {
                params.callBack({ responseJson, attributeJSON, error });
            });
    }
}

export const formGenerator_type1 = (params) => {
    const renderButton = params.SubmitButton,
        renderAppendElementAfter = params.AppendElementAfter;
    let renderForm,
        formTemplate = params.FormTemplate,
        fns = params.Fns,
        settings = params.Settings;

    fns = fns ? fns : {};
    settings = settings ? settings : {};

    if (formTemplate) {
        let attributes = formTemplate.Attributes,
            moduleTemplate = formTemplate.ModuleTemplate,
            formClassName = formTemplate.ClassName,
            attributesFK = formTemplate.AttributesFK;

        if (attributes && moduleTemplate) {
            let moduleTemplateAttributes = moduleTemplate.Attributes,
                moduleTemplateDataModule = moduleTemplate.DataModule,
                moduleTemplateIsUpdate = moduleTemplate.IsUpdate,
                moduleTemplateGroups = moduleTemplate.Groups,
                rendermoduleTemplateAttribute = [],
                _params = {
                    attributes,
                    moduleTemplateDataModule,
                    moduleTemplateIsUpdate,
                    moduleFns: fns[moduleTemplateDataModule],
                    moduleSettings: settings[moduleTemplateDataModule],
                    fns,
                    component: params.Component
                };

            if (moduleTemplateAttributes) {
                moduleTemplateAttributes.forEach(_moduleTemplateAttribute => {
                    if (!_moduleTemplateAttribute.GroupID) {
                        let otherTypes = _moduleTemplateAttribute.OtherTypes,
                            moduleAttribute = searchAttributeByDBObjectAttributeID({
                                attributes,
                                id: _moduleTemplateAttribute.ID
                            }),
                            moduleAttributeFK;

                        if (moduleAttribute) {
                            _params['moduleTemplateAttribute'] = _moduleTemplateAttribute;
                            _params['attribute'] = moduleAttribute;
                            _params['key'] = `${moduleTemplateDataModule}-${moduleAttribute.DBObjectAttributeID}`;

                            if (moduleAttribute.IsForeignKey && attributesFK) {
                                moduleAttributeFK = attributesFK[moduleAttribute.DBObjectAttributeID];
                            }
                            _params['moduleAttributeFK'] = moduleAttributeFK;

                            rendermoduleTemplateAttribute.push(processFormElement({
                                ..._params
                            }));

                            if (otherTypes) {
                                otherTypes.forEach((_otherType, index) => {
                                    rendermoduleTemplateAttribute.push(processFormElementOtherType({
                                        ..._params,
                                        otherType: _otherType,
                                        index
                                    }));
                                });
                            }
                        }
                    } else {
                        delete _params['moduleTemplateAttribute'];
                        delete _params['attribute'];
                        delete _params['key'];
                        rendermoduleTemplateAttribute.push(processFormGroup({
                            ..._params,
                            moduleTemplateGroup: _moduleTemplateAttribute
                        }));
                    }

                    if (renderAppendElementAfter) {
                        renderAppendElementAfter.forEach((_renderAppendElementAfter, index) => {
                            if (_moduleTemplateAttribute.ID === _renderAppendElementAfter.AfterID) {
                                rendermoduleTemplateAttribute.push(_renderAppendElementAfter.Item);
                            }
                        });
                    }
                });

                renderForm = (
                    <div id={`form-${moduleTemplateDataModule}`} style={{ width: "100%" }} className={formClassName ? formClassName : ''}>
                        {rendermoduleTemplateAttribute}
                        {renderButton}
                    </div>
                )
            }
        }
    }

    return renderForm;
}

const processFormElement = (params) => {
    let attribute = params.attribute,
        moduleTemplateAttribute = params.moduleTemplateAttribute,
        parentClassName = moduleTemplateAttribute.ParentClassName,
        moduleTemplateIsUpdate = params.moduleTemplateIsUpdate,
        isUpdate = params.moduleTemplateIsUpdate,
        generatedField,
        key = params.key,
        shouldGenerateRandom = true;

    if (attribute.IsSelectList) {
        generatedField = generate_selectListField(params);
        shouldGenerateRandom = false;
    } else if (attribute.IsHierarchyList) {
        generatedField = generate_hierarchyList(params);
    } else {
        switch (attribute.AttribTypeID) {
            case 102: // String
            case 202: // BigInt
            case 203: // Double
            case 201: // Int
            case 204: // Money
            case 205: // Real
                generatedField = generate_inputField(params);
                break;
            case 200: // Bit
                generatedField = generate_switchField(params);
                break;
            case 302: // Date
                generatedField = generate_dateField(params);
                break;
            case 301: // DateTime
                generatedField = generate_dateTimeField(params);
                break;
            case 402: // Email
                break;
            case 401: // Image
                generatedField = generate_imageField(params);
                break;
            case 303: // Time
                generatedField = generate_timeField(params);
                break;
            case 403: // URL
                break;
            case 405: // Video
                generatedField = generate_videoField(params);
                break;
            default:
                break;
        }
    }

    if (shouldGenerateRandom) {
        if (moduleTemplateIsUpdate) {
            key = `${key}-${Math.random() * 10 + 1}`;
        }
    }

    if (!isUpdate && attribute.RW != 2) {
        generatedField = null;
    }

    if (generatedField) {
        return (
            <div className={parentClassName} key={`${key}`}>
                {generatedField}
            </div>
        )
    }
}

const processFormGroup = (params) => {
    const moduleTemplateGroup = params.moduleTemplateGroup,
        attributes = params.attributes,
        children = moduleTemplateGroup.Children;
    let childLocation = {},
        renderGroup,
        renderRemoveBtn;

    if (moduleTemplateGroup.IsRemovable) {
        renderRemoveBtn = (<div className="color-decline">
            <i className="fa fa-times-circle -cursor-pointer rotate-hover-90" title={texts[3]}
                onClick={onClickRemoveGroupAttributeFromModuleAttributes.bind(this, params)}></i>
        </div>);
    }

    if (children) {
        children.forEach(_child => {
            let moduleAttribute;

            if (_child.RID) {
                moduleAttribute = searchAttributeByDBObjectAttributeIDAndRID({
                    attributes,
                    id: _child.ID,
                    RID: _child.RID
                });
            } else {
                moduleAttribute = searchAttributeByDBObjectAttributeID({
                    attributes,
                    id: _child.ID
                });
            }

            params['moduleTemplateAttribute'] = _child;
            params['attribute'] = moduleAttribute;
            params['key'] = `${params.moduleTemplateDataModule}-${moduleAttribute.DBObjectAttributeID}-${moduleTemplateGroup.GroupID}`;

            childLocation[`${_child.GroupLocation}`] = processFormElement({
                ...params
            });
        });
    }

    switch (moduleTemplateGroup.GroupCode) {
        case 1: {
            renderGroup = (
                <div className={moduleTemplateGroup.ClassName} key={params['key']}>
                    <div>
                        {childLocation["1"]}
                    </div>
                    <div className="d-flex jc-sb">
                        {childLocation["2"]}
                        {childLocation["3"]}
                        {renderRemoveBtn}
                    </div>
                </div>
            );
            break;
        }
        case 2: {
            renderGroup = (
                <div className={moduleTemplateGroup.ClassName} key={params['key']}>
                    {childLocation["1"]}
                    {childLocation["2"]}
                </div>
            );
            break;
        }
        case 3: {
            renderGroup = (
                <div className={moduleTemplateGroup.ClassName} key={params['key']}>
                    {childLocation["1"]}
                    {childLocation["2"]}
                    {childLocation["3"]}
                </div>
            );
        }
        default:
            break;
    }

    return renderGroup;
}

export const generate_selectListField = (params) => {
    let attribute = params.attribute,
        moduleAttributeFK = params.moduleAttributeFK,
        moduleTemplateAttribute = params.moduleTemplateAttribute,
        moduleTemplateGroup = params.moduleTemplateGroup,
        generatedField,
        defaultLabel = attribute.DefaultLabel,
        labelID = attribute.LabelID,
        module = params.moduleTemplateDataModule,
        isUpdate = params.moduleTemplateIsUpdate,
        id = `${module}-${attribute.DBObjectAttributeID}`,
        labelClassName = "field-label form-label",
        renderBlankOption,
        renderPreselected,
        LABEL = processLabel({ LabelID: labelID, DefaultLabel: defaultLabel });

    if (attribute.IsMandatory) {
        labelClassName = `${labelClassName} lms-is-mandatory`;
    }

    if (moduleTemplateAttribute.IsSelect2Nullable) {
        renderBlankOption = (<option></option>);
    }

    if (moduleTemplateAttribute.IsFetchOnDemand && attribute.RW === 2 && isUpdate) {
        if (moduleAttributeFK) {
            renderPreselected = (
                <option value={attribute.Value}>{moduleAttributeFK}</option>
            )
        }
    }

    if (moduleTemplateAttribute.IsSelect2) {
        generatedField = (
            <>
                <label htmlFor={id} className={labelClassName}>{LABEL}</label>
                <div className={moduleTemplateAttribute.ElementWrapperClassName}>
                    <select id={id}
                        className={moduleTemplateAttribute.ElementClassName}
                        data-module={params.moduleTemplateDataModule}
                        data-ref={
                            encodeToBtoa(
                                JSON.stringify(attribute)
                            )
                        }
                        data-moduletemplateattribute={
                            encodeToBtoa(
                                JSON.stringify(moduleTemplateAttribute)
                            )
                        }
                        data-moduletemplategroup={
                            encodeToBtoa(
                                JSON.stringify(moduleTemplateGroup)
                            )
                        }
                        style={{ width: "100%" }}
                        placeholder={defaultLabel}
                        defaultValue={attribute.Value}
                    >
                        {renderBlankOption}
                        {renderPreselected}
                    </select>
                </div>
            </>
        )

        insertToToBeInitializedFields({
            module: module,
            field: {
                IsSelect2: true,
                ID: id,
                moduleTemplateAttribute,
                attribute
            }
        });
    }

    return generatedField;
}

export const generate_hierarchyList = (params) => {
    let attribute = params.attribute,
        moduleAttributeFK = params.moduleAttributeFK,
        moduleTemplateAttribute = params.moduleTemplateAttribute,
        moduleTemplateGroup = params.moduleTemplateGroup,
        generatedField,
        labelID = attribute.LabelID,
        defaultLabel = attribute.DefaultLabel,
        module = params.moduleTemplateDataModule,
        id = `${module}-${attribute.DBObjectAttributeID}`,
        labelClassName = "field-label form-label",
        Label = processLabel({ LabelID: labelID, DefaultLabel: defaultLabel });;

    switch (moduleTemplateAttribute.HierarchyListType) {
        case 1: {
            generatedField = (
                <>
                    <label htmlFor={id} className={labelClassName}>{Label}</label>
                    <div className={moduleTemplateAttribute.ElementWrapperClassName}>
                        <LMS_LocationList
                            id={id}
                            className={moduleTemplateAttribute.ElementClassName}
                            data-module={params.moduleTemplateDataModule}
                            attribute={attribute}
                            data-ref={
                                encodeToBtoa(
                                    JSON.stringify(attribute)
                                )
                            }
                            data-moduletemplateattribute={
                                encodeToBtoa(
                                    JSON.stringify(moduleTemplateAttribute)
                                )
                            }
                            data-moduletemplategroup={
                                encodeToBtoa(
                                    JSON.stringify(moduleTemplateGroup)
                                )
                            }
                            placeholder={defaultLabel}
                            defaultValue={attribute.Value}
                            renderContainerType={moduleTemplateAttribute.RenderContainerType}
                            renderModalContentType={moduleTemplateAttribute.RenderModalContentType}
                        />
                    </div>
                </>
            )
            break;
        }
    }

    return generatedField;
}

export const generate_inputField = (params) => {
    let attribute = params.attribute,
        moduleTemplateAttribute = params.moduleTemplateAttribute,
        moduleTemplateGroup = params.moduleTemplateGroup,
        generatedField,
        renderInputMessage,
        defaultLabel = attribute.DefaultLabel,
        labelID = attribute.LabelID,
        module = params.moduleTemplateDataModule,
        isUpdate = params.moduleTemplateIsUpdate,
        id = `${module}-${attribute.DBObjectAttributeID}`,
        labelClassName = "field-label form-label",
        inputMessage = moduleTemplateAttribute.InputMessage,
        _onBlur,
        _onFocus,
        LABEL = processLabel({ LabelID: labelID, DefaultLabel: defaultLabel });
    // _onFocus = onFocus.bind(this, params);

    if (attribute.IsMandatory) {
        labelClassName = `${labelClassName} lms-is-mandatory`;
    }

    if (inputMessage) {
        renderInputMessage = generate_inputMessage({
            message: inputMessage,
            type: moduleTemplateAttribute.InputMessageType
        });
    }

    if (isUpdate) {
        _onBlur = onUpdateByRID.bind(this, params);
    }

    if (moduleTemplateAttribute.IsTextArea) {
        generatedField = (
            <>
                <label htmlFor={id} className={labelClassName}>{LABEL}</label>
                <div className={moduleTemplateAttribute.ElementWrapperClassName}>
                    <textarea id={id}
                        className={moduleTemplateAttribute.ElementClassName}
                        data-module={module}
                        data-ref={
                            encodeToBtoa(
                                JSON.stringify(attribute)
                            )
                        }
                        data-moduletemplateattribute={
                            encodeToBtoa(
                                JSON.stringify(moduleTemplateAttribute)
                            )
                        }
                        data-moduletemplategroup={
                            encodeToBtoa(
                                JSON.stringify(moduleTemplateGroup)
                            )
                        }
                        rows={moduleTemplateAttribute.Rows}
                        placeholder={defaultLabel}
                        defaultValue={attribute.Value}
                        onBlur={_onBlur}
                        onFocus={_onFocus}
                    />
                    {renderInputMessage}
                </div>
            </>
        )
    } else if (moduleTemplateAttribute.IsWYSIWYG) {
        let value = attribute.Value;

        if (value) {
            value = decodeURI(value);
        }

        generatedField = (
            <>
                <label htmlFor={id} className={labelClassName}>{LABEL}</label>
                <div className={moduleTemplateAttribute.ElementWrapperClassName}>
                    <input id={id}
                        style={{ display: "none" }}
                        className={moduleTemplateAttribute.ElementClassName}
                        data-module={module}
                        data-ref={
                            encodeToBtoa(
                                JSON.stringify(attribute)
                            )
                        }
                        data-moduletemplateattribute={
                            encodeToBtoa(
                                JSON.stringify(moduleTemplateAttribute)
                            )
                        }
                        data-moduletemplategroup={
                            encodeToBtoa(
                                JSON.stringify(moduleTemplateGroup)
                            )
                        } />
                    <CKEditor
                        editor={ClassicEditor}
                        data={value}
                        onChange={(event, editor) => {
                            onChangeCKEditorChange(params, event, editor);
                        }}
                        config={{
                            ckfinder: {
                                uploadUrl: `${MEDIA_LIB_URL}/news`,
                                headers: {
                                    'X-CSRF-TOKEN': 'CSFR-Token',
                                    Authorization: 'Bearer <JSON Web Token>'
                                }
                            }
                        }}
                    />
                    {renderInputMessage}
                </div>
            </>
        );
    } else {
        generatedField = (
            <>
                <label htmlFor={id} className={labelClassName}>{LABEL}</label>
                <div className={moduleTemplateAttribute.ElementWrapperClassName}>
                    <input id={id} className={moduleTemplateAttribute.ElementClassName}
                        data-module={params.moduleTemplateDataModule}
                        data-ref={
                            encodeToBtoa(
                                JSON.stringify(attribute)
                            )
                        }
                        data-moduletemplateattribute={
                            encodeToBtoa(
                                JSON.stringify(moduleTemplateAttribute)
                            )
                        }
                        data-moduletemplategroup={
                            encodeToBtoa(
                                JSON.stringify(moduleTemplateGroup)
                            )
                        }
                        type="text"
                        placeholder={defaultLabel}
                        defaultValue={attribute.Value}
                        onBlur={_onBlur}
                        onFocus={_onFocus}
                    />
                    {renderInputMessage}
                </div>
            </>
        )
    }

    return generatedField;
}

export const generate_switchField = (params) => {
    let attribute = params.attribute,
        moduleTemplateAttribute = params.moduleTemplateAttribute,
        moduleTemplateGroup = params.moduleTemplateGroup,
        moduleFns = params.moduleFns,
        generatedField,
        defaultLabel = attribute.DefaultLabel,
        labelID = attribute.LabelID,
        module = params.moduleTemplateDataModule,
        isUpdate = params.moduleTemplateIsUpdate,
        key = params.key,
        id = `${module}-${attribute.DBObjectAttributeID}`,
        dBObjectAttributeID = attribute.DBObjectAttributeID,
        labelClassName = "form-label",
        _onChange,
        renderLabelField,
        value,
        Label = processLabel({ LabelID: labelID, DefaultLabel: defaultLabel });

    if (key) {
        id = key;
    }

    if (attribute.IsMandatory) {
        labelClassName = `${labelClassName} lms-is-mandatory`;
    }

    if (moduleTemplateAttribute.IsHorizontal) {
        renderLabelField = (<div className="field-label"></div>)
    }

    if (isUpdate) {
        _onChange = onUpdateByRID.bind(this, params);
        value = attribute.Value === 1 || attribute.Value === "1";
    } else if (moduleTemplateAttribute.Rule) {
        _onChange = onChange.bind(this, params);
        value = attribute.DefaultValue === 1 || attribute.DefaultValue === "1";
    } else {
        value = attribute.DefaultValue === 1 || attribute.DefaultValue === "1";
    }

    generatedField = (
        <>
            {renderLabelField}
            <div className={moduleTemplateAttribute.ElementWrapperClassName}>
                <input id={id}
                    className={moduleTemplateAttribute.ElementClassName}
                    type="checkbox"
                    defaultChecked={value}
                    data-module={module}
                    data-ref={
                        encodeToBtoa(
                            JSON.stringify(attribute)
                        )
                    }
                    data-moduletemplateattribute={
                        encodeToBtoa(
                            JSON.stringify(moduleTemplateAttribute)
                        )
                    }
                    data-moduletemplategroup={
                        encodeToBtoa(
                            JSON.stringify(moduleTemplateGroup)
                        )
                    }
                    onChange={_onChange}
                />
                <label className={labelClassName} htmlFor={id}>{Label}</label>
            </div>
        </>
    )

    if (moduleTemplateAttribute.Rule) {
        insertToToBeInitializedFields({
            module: module,
            field: {
                ID: id,
                moduleTemplateAttribute,
                attribute
            }
        });
    }

    return generatedField;
}

export const generate_dateField = (params) => {
    let attribute = params.attribute,
        moduleTemplateAttribute = params.moduleTemplateAttribute,
        moduleTemplateGroup = params.moduleTemplateGroup,
        component = params.component,
        generatedField,
        renderInputMessage,
        defaultLabel = attribute.DefaultLabel,
        labelID = attribute.LabelID,
        module = params.moduleTemplateDataModule,
        isUpdate = params.moduleTemplateIsUpdate,
        id = `${module}-${attribute.DBObjectAttributeID}`,
        labelClassName = "form-label",
        inputMessage = moduleTemplateAttribute.InputMessage,
        value = moduleTemplateAttribute.Value,
        _onChange,
        inputFields = moduleTemplateAttribute.InputFields;
    let isDateRange,
        dateRangeReferredValue,
        dateRangeStart,
        dateRangeEnd,
        dateRangeMin,
        LABEL = processLabel({ LabelID: labelID, DefaultLabel: defaultLabel });

    inputFields = inputFields || {};

    if (isUpdate) {
        _onChange = onChangeDatePicker.bind(this, params);
    } else {
        _onChange = onChange.bind(this, params);
    }

    if (value) {
        try {
            if (!(value instanceof Date)) {
                value = new Date(value);
            }
        } catch (Exception) {
            value = null;
            console.log(Exception);
        }
    }

    if ((inputFields.SelectsStart || inputFields.SelectsEnd) && component) {
        const formTemplate = component.state.formTemplate;
        let referredID;
        isDateRange = true;

        if (inputFields.SelectsStart) {
            referredID = inputFields.SelectsEndID;
        } else if (inputFields.SelectsEnd) {
            referredID = inputFields.SelectsStartID;
        }

        if (referredID) {
            let vals = getValuesFromModuleTemplateGroup({
                moduleAttribute: formTemplate[module].ModuleTemplate.Attributes,
                attributeID: referredID,
                groupCode: 2,
                dataModule: module
            });

            if (vals) {
                dateRangeReferredValue = vals[0];
            }
        }

        if (inputFields.SelectsStart) {
            dateRangeStart = value;
            dateRangeEnd = dateRangeReferredValue;
        } else if (inputFields.SelectsEnd) {
            dateRangeStart = dateRangeReferredValue;
            dateRangeEnd = value;
            dateRangeMin = value;
        }
    }

    if (attribute.IsMandatory) {
        labelClassName = `${labelClassName} lms-is-mandatory`;
    }

    if (inputMessage) {
        renderInputMessage = generate_inputMessage({
            message: inputMessage,
            type: moduleTemplateAttribute.InputMessageType
        });
    }

    if (moduleTemplateGroup) {
        id = `${id}-${moduleTemplateGroup.GroupID}`;
    }

    generatedField = (
        <>
            <label htmlFor={id} className={labelClassName}>{LABEL}</label>
            <div className={moduleTemplateAttribute.ElementWrapperClassName}>
                <DatePicker
                    className={moduleTemplateAttribute.ElementClassName}
                    popperContainer={({ children }) => {
                        return (createPortal(children, document.getElementById("root")));
                    }}
                    customInput={
                        <input
                            type="text"
                            data-moduletemplateattribute={
                                encodeToBtoa(
                                    JSON.stringify(moduleTemplateAttribute)
                                )
                            }
                            data-moduletemplategroup={
                                encodeToBtoa(
                                    JSON.stringify(moduleTemplateGroup)
                                )
                            }
                            data-module={module}
                            data-ref={
                                encodeToBtoa(
                                    JSON.stringify(attribute)
                                )
                            }
                        />}
                    id={id}
                    selected={value}
                    dateFormat="dd/MM/YYY"
                    onChange={_onChange}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    selectsStart={inputFields.SelectsStart}
                    startDate={isDateRange ? dateRangeStart : null}
                    selectsEnd={inputFields.SelectsEnd}
                    endDate={isDateRange ? dateRangeEnd : null}
                    minDate={isDateRange ? dateRangeMin : null}
                    isClearable={!attribute.IsMandatory}
                />
                {renderInputMessage}
            </div>
        </>
    );

    return generatedField;
}

export const generate_dateTimeField = (params) => {
    let attribute = params.attribute,
        moduleTemplateAttribute = params.moduleTemplateAttribute,
        moduleTemplateGroup = params.moduleTemplateGroup,
        generatedField,
        renderInputMessage,
        defaultLabel = attribute.DefaultLabel,
        labelID = attribute.LabelID,
        module = params.moduleTemplateDataModule,
        isUpdate = params.moduleTemplateIsUpdate,
        id = `${module}-${attribute.DBObjectAttributeID}`,
        labelClassName = "form-label",
        inputMessage = moduleTemplateAttribute.InputMessage,
        value = moduleTemplateAttribute.Value,
        _onChange,
        showTime = true,
        Label = processLabel({ LabelID: labelID, DefaultLabel: defaultLabel });
    if (isUpdate) {
        _onChange = onChangeDatePicker.bind(this, params);
    } else {
        _onChange = onChange.bind(this, params);
    }

    if (!value) {
        if (attribute.Value) {
            value = attribute.Value;
        }
    }

    if (value) {
        value = moment(value);
        value = value._d;
    }

    if (attribute.IsMandatory) {
        labelClassName = `${labelClassName} lms-is-mandatory`;
    }

    if (inputMessage) {
        renderInputMessage = generate_inputMessage({
            message: inputMessage,
            type: moduleTemplateAttribute.InputMessageType
        });
    }

    if (moduleTemplateGroup) {
        id = `${id}-${moduleTemplateGroup.GroupID}`;
    }

    showTime = !(moduleTemplateAttribute.ShowTime === false);

    generatedField = (
        <>
            <label htmlFor={id} className={labelClassName}>{Label}</label>
            <div className={moduleTemplateAttribute.ElementWrapperClassName}>
                <DatePicker
                    className={moduleTemplateAttribute.ElementClassName}
                    popperContainer={({ children }) => {
                        return (createPortal(children, document.getElementById("root")));
                    }}
                    customInput={
                        <input
                            type="text"
                            data-moduletemplateattribute={
                                encodeToBtoa(
                                    JSON.stringify(moduleTemplateAttribute)
                                )
                            }
                            data-moduletemplategroup={
                                encodeToBtoa(
                                    JSON.stringify(moduleTemplateGroup)
                                )
                            }
                            data-module={module}
                            data-ref={
                                encodeToBtoa(
                                    JSON.stringify(attribute)
                                )
                            }
                        />}
                    id={id}
                    selected={value}
                    showTimeInput={showTime}
                    timeIntervals={15}
                    dateFormat="dd/MM/YYY HH:mm"
                    onChange={_onChange}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                />
                {renderInputMessage}
            </div>
        </>
    );

    return generatedField;
}

export const generate_timeField = (params) => {
    let attribute = params.attribute,
        moduleTemplateAttribute = params.moduleTemplateAttribute,
        moduleTemplateGroup = params.moduleTemplateGroup,
        generatedField,
        renderInputMessage,
        defaultLabel = attribute.DefaultLabel,
        labelID = attribute.LabelID,
        module = params.moduleTemplateDataModule,
        isUpdate = params.moduleTemplateIsUpdate,
        id = `${module}-${attribute.DBObjectAttributeID}`,
        labelClassName = "form-label",
        inputMessage = moduleTemplateAttribute.InputMessage,
        value = moduleTemplateAttribute.Value,
        _onChange,
        Label = processLabel({ LabelID: labelID, DefaultLabel: defaultLabel });

    if (isUpdate) {
        _onChange = onChangeDatePicker.bind(this, params);
    } else {
        _onChange = onChange.bind(this, params);
    }

    if (value) {
        try {
            if (typeof value === 'string') {
                let tempValue = new Date(value);

                if (tempValue instanceof Date) {
                    if (isNaN(tempValue.getTime())) {
                        tempValue = moment(value, 'HH:mm');
                        value = tempValue.toDate();
                    } else {
                        value = tempValue;
                    }
                }
            }
        } catch (Exception) {
            value = null;
        }
    }

    if (attribute.IsMandatory) {
        labelClassName = `${labelClassName} lms-is-mandatory`;
    }

    if (inputMessage) {
        renderInputMessage = generate_inputMessage({
            message: inputMessage,
            type: moduleTemplateAttribute.InputMessageType
        });
    }

    if (moduleTemplateGroup) {
        id = `${id}-${moduleTemplateGroup.GroupID}`;
    }

    generatedField = (
        <>
            <label htmlFor={id} className={labelClassName}>{Label}</label>
            <div className={moduleTemplateAttribute.ElementWrapperClassName}>
                <DatePicker
                    className={moduleTemplateAttribute.ElementClassName}
                    popperContainer={({ children }) => {
                        return (createPortal(children, document.getElementById("root")));
                    }}
                    customInput={
                        <input
                            type="text"
                            data-moduletemplateattribute={
                                encodeToBtoa(
                                    JSON.stringify(moduleTemplateAttribute)
                                )
                            }
                            data-module={module}
                            data-ref={
                                encodeToBtoa(
                                    JSON.stringify(attribute)
                                )
                            }
                            data-moduletemplategroup={
                                encodeToBtoa(
                                    JSON.stringify(moduleTemplateGroup)
                                )
                            }
                        />}
                    id={id}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    timeFormat="HH:mm"
                    dateFormat="HH:mm"
                    selected={value}
                    onChange={_onChange}
                    placeholderText={texts[2]}
                />
                {renderInputMessage}
            </div>
        </>
    );

    return generatedField;
}

export const generate_imageField = (params) => {
    let attribute = params.attribute,
        moduleTemplateAttribute = params.moduleTemplateAttribute,
        moduleTemplateGroup = params.moduleTemplateGroup,
        moduleAttributeFK = params.moduleAttributeFK,
        generatedField,
        renderInputMessage,
        renderThumbnail,
        defaultLabel = attribute.DefaultLabel,
        labelID = attribute.LabelID,
        module = params.moduleTemplateDataModule,
        isUpdate = params.moduleTemplateIsUpdate,
        id = `${module}-${attribute.DBObjectAttributeID}`,
        labelClassName = "form-label",
        inputMessage = moduleTemplateAttribute.InputMessage,
        value = moduleTemplateAttribute.Value,
        _onDrop,
        _onChange,
        Label = processLabel({ LabelID: labelID, DefaultLabel: defaultLabel });

    if (isUpdate) {
        if (typeof moduleAttributeFK == "string") {
            moduleAttributeFK = JSON.parse(moduleAttributeFK);
        }

        if (attribute.ForeignKey) {
            moduleAttributeFK = attribute.ForeignKey;
        }
        _onChange = onChangeGeneratedFieldMediaLibraryFormModule.bind(this, params);
    } else {
        _onDrop = onDropDropZone.bind(this, params);
    }

    if (value) {
        renderThumbnail = (
            <aside className="thumbnail-container">
                <div className="thumbnail">
                    <div className="thumbnail-inner">
                        <div className="thumbnail-detail">
                            <span className="thumbnail-title">{value.name}</span>
                            <span className="thumbnail-size">{formatBytes(value.size)}</span>
                        </div>
                        <span className="thumbnail-remove">
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
            </aside>
        );
    }

    if (moduleTemplateAttribute.IsMediaFile) {
        let attributeExtension = attribute.AttributeExtension;

        if (typeof attributeExtension === "string") {
            attributeExtension = JSON.parse(attributeExtension);
        }

        if (attributeExtension == null) {
            attributeExtension = {};
        }

        generatedField = (
            <>
                <label htmlFor={id} className={labelClassName}>{Label}</label>
                <MediaLibraryFormModule
                    id={id}
                    maxSelection={moduleTemplateAttribute.Max}
                    module={attributeExtension.Module}
                    parameters={attributeExtension.Parameters}
                    data-module={module}
                    data-ref={
                        encodeToBtoa(
                            JSON.stringify(attribute)
                        )
                    }
                    data-moduletemplateattribute={
                        encodeToBtoa(
                            JSON.stringify(moduleTemplateAttribute)
                        )
                    }
                    onChange={_onChange}
                    value={moduleAttributeFK}
                />
            </>
        )
    } else {
        generatedField = (
            <Dropzone onDrop={_onDrop}>
                {({ getRootProps, getInputProps }) => {

                    return (
                        <section>
                            <label htmlFor={id} className="form-label">{Label}</label>
                            <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps({
                                    multiple: false,
                                    accept: 'image/*'
                                })}
                                    data-module={module}
                                    data-ref={
                                        encodeToBtoa(
                                            JSON.stringify(attribute)
                                        )
                                    }
                                    data-moduletemplateattribute={
                                        encodeToBtoa(
                                            JSON.stringify(moduleTemplateAttribute)
                                        )
                                    } />
                                <p className="instruction">{texts[0]}</p>
                                {renderThumbnail}
                            </div>
                        </section>
                    )
                }}
            </Dropzone>
        );
    }

    return generatedField;
}

export const generate_videoField = (params) => {
    let attribute = params.attribute,
        moduleTemplateAttribute = params.moduleTemplateAttribute,
        moduleTemplateGroup = params.moduleTemplateGroup,
        moduleAttributeFK = params.moduleAttributeFK,
        generatedField,
        renderInputMessage,
        renderThumbnail,
        defaultLabel = attribute.DefaultLabel,
        labelID = attribute.LabelID,
        module = params.moduleTemplateDataModule,
        isUpdate = params.moduleTemplateIsUpdate,
        id = `${module}-${attribute.DBObjectAttributeID}`,
        labelClassName = "form-label",
        inputMessage = moduleTemplateAttribute.InputMessage,
        value = moduleTemplateAttribute.Value,
        _onDrop,
        _onChange,
        Label = processLabel({ LabelID: labelID, DefaultLabel: defaultLabel })

    if (isUpdate) {
        if (typeof moduleAttributeFK == "string") {
            moduleAttributeFK = JSON.parse(moduleAttributeFK);
        }

        if (attribute.ForeignKey) {
            moduleAttributeFK = attribute.ForeignKey;
        }
        _onChange = onChangeGeneratedFieldMediaLibraryFormModule.bind(this, params);
    } else {
        _onDrop = onDropDropZone.bind(this, params);
    }

    if (value) {
        renderThumbnail = (
            <aside className="thumbnail-container">
                <div className="thumbnail">
                    <div className="thumbnail-inner">
                        <div className="thumbnail-detail">
                            <span className="thumbnail-title">{value.name}</span>
                            <span className="thumbnail-size">{formatBytes(value.size)}</span>
                        </div>
                        <span className="thumbnail-remove">
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
            </aside>
        );
    }

    if (moduleTemplateAttribute.IsMediaFile) {
        let attributeExtension = attribute.AttributeExtension;

        if (typeof attributeExtension === "string") {
            attributeExtension = JSON.parse(attributeExtension);
        }

        if (attributeExtension == null) {
            attributeExtension = {};
        }

        generatedField = (
            <>
                <label htmlFor={id} className={labelClassName}>{Label}</label>
                <MediaLibraryFormModule
                    id={id}
                    maxSelection={moduleTemplateAttribute.Max}
                    module={attributeExtension.Module}
                    parameters={attributeExtension.Parameters}
                    data-module={module}
                    data-ref={
                        encodeToBtoa(
                            JSON.stringify(attribute)
                        )
                    }
                    data-moduletemplateattribute={
                        encodeToBtoa(
                            JSON.stringify(moduleTemplateAttribute)
                        )
                    }
                    onChange={_onChange}
                    value={moduleAttributeFK}
                />
            </>
        )
    }

    return generatedField;
}

const generate_inputMessage = (params) => {
    const message = params.message,
        type = params.type;

    return (
        <p className={`help ${inputMessageType[type]}`}>{message}</p>
    )
}

const insertToToBeInitializedFields = (params) => {
    let module = params.module,
        field = params.field;

    if (toBeInitialized[module] == null) {
        toBeInitialized[module] = [];
    }

    let toBeInitializedModule = toBeInitialized[module],
        isExisting = toBeInitializedModule.find(item => {
            return item.ID == field.ID;
        });

    if (!isExisting) {
        toBeInitialized[module].push(field);
    }
}

export const initializeGeneratedFields = (params) => {
    const component = params.component;
    let fns = params.fns,
        toBeInitializedKeys = Object.keys(toBeInitialized);

    if (toBeInitializedKeys.length > 0) {
        toBeInitializedKeys.forEach(key => {
            let module = toBeInitialized[key],
                moduleFns = fns[key];

            if (module) {
                toBeInitialized[key] = module.map(item => {
                    if (!item['IsInitialized']) {
                        processInitializeGeneratedFields({
                            moduleTemplateDataModule: key,
                            ...item,
                            moduleFns,
                            component
                        });
                        item['IsInitialized'] = true;
                    }

                    return item;
                });
            }
        });
    }
}

export const unInitializeGeneratedFields = (params) => {
    let modules = params.modules;

    if (modules) {
        modules.forEach(_modules => {
            delete toBeInitialized[_modules];
        });
    }
}

const processInitializeGeneratedFields = (params) => {
    let { ID, attribute, moduleTemplateAttribute } = params;

    if (params.IsSelect2) {
        initializeSelect2(params);
    } else {
        switch (attribute.AttribTypeID) {
            case 102: // String
            case 202: // BigInt
            case 203: // Double
            case 201: // Int
            case 204: // Money
            case 205: // Real
                if (moduleTemplateAttribute.IsWYSIWYG) {
                    // req
                    // CKEditor.replace(ID);
                }
                break;
            case 200: // Bit
                break;
            case 302: // Date
                break;
            case 301: // DateTime
                break;
            case 402: // Email
                break;
            case 401: // Image
                break;
            case 303: // Time
                break;
            case 403: // URL
                break;
            case 405: // Video
                break;
            default:
                break;
        }
    }

    if (params.moduleTemplateAttribute.Rule) {
        let targetElementJSON = formElemValueGetter({ elem: document.getElementById(params.ID) });

        processRule({
            ...params,
            value: targetElementJSON.value
        });
    }


}

const initializeSelect2 = (params) => {
    let attribute = params.attribute,
        moduleTemplateAttribute = params.moduleTemplateAttribute,
        isUpdate = params.moduleTemplateIsUpdate,
        listValue = attribute.ListValue,
        moduleFns = params.moduleFns,
        moduleFn;

    if (listValue) {
        listValue = JSON.parse(listValue);
    }

    if (moduleFns) {
        moduleFn = moduleFns[`${attribute.DBObjectAttributeID}`];
    }

    if (!moduleTemplateAttribute.IsFetchOnDemand) {
        getGenericList({ ...params, module: listValue.Module, moduleFn });
    } else {
        let onSelect;

        if (attribute.RW === 2 && isUpdate) {
            onSelect = onChangeSelect2;
        }

        select2Initialize({
            id: params.ID,
            containerCssClass: 'input is-medium',
            delay: 250,
            minimumInputLength: 0,
            allowClear: moduleTemplateAttribute.IsSelect2Nullable,
            ajax: {
                url: `${API_URL}/single_api/`,
                headers: {
                    token: getToken()
                },
                method: 'GET',
                data: function (params) {
                    const term = params.term;
                    let parameter = {};

                    parameter = JSON.stringify({
                        Module: listValue.Module,
                        Parameters: {
                            EmployeeID: getEmployeeID(),
                            SearchValue: term,
                            ObjectType: listValue.ObjectType
                        }
                    });

                    let query = {
                        requestjson: parameter
                    };

                    return query;
                },
                processResults: function (data, params) {
                    let _data = data.Data;

                    return {
                        results: _data.List
                    };
                }
            },
            onSelect2Select: onSelect,
            extraData: params
        });
    }
}

const processRule = (params) => {
    const moduleTemplateAttribute = params.moduleTemplateAttribute,
        component = params.component,
        value = params.value;
    let rule = moduleTemplateAttribute.Rule;

    if (component && rule) {
        let ruleType = moduleTemplateAttribute.RuleType,
            ruleValue = rule[`${value}`];

        if (ruleValue) {
            let formTemplate = component.state.formTemplate[params.moduleTemplateDataModule];

            if (formTemplate) {
                let moduleTemplateAttributes = formTemplate.ModuleTemplate.Attributes;

                Object.keys(ruleValue).forEach(_key => {
                    const _ruleValue = ruleValue[_key];

                    switch (ruleType) {
                        case 1: {
                            Object.keys(_ruleValue).forEach(_ruleValueKey => {
                                moduleTemplateAttributes = moduleTemplateAttributes.map(_moduleTemplateAttribute => {
                                    if (_moduleTemplateAttribute.ID == _ruleValueKey) {
                                        _moduleTemplateAttribute = {
                                            ..._moduleTemplateAttribute,
                                            ..._ruleValue[_ruleValueKey]
                                        };
                                    }

                                    return _moduleTemplateAttribute;
                                });
                            });
                            break;
                        }
                        default: {
                            moduleTemplateAttributes = moduleTemplateAttributes.map(_moduleTemplateAttribute => {
                                if (_ruleValue.find(__ruleValue => {
                                    return __ruleValue == _moduleTemplateAttribute.ID;
                                })) {
                                    switch (_key) {
                                        case "1":
                                            if (_moduleTemplateAttribute.ParentClassName.includes(classNameControl[0])) {
                                                _moduleTemplateAttribute.ParentClassName = _moduleTemplateAttribute.ParentClassName.replace(classNameControl[0], '');
                                            }
                                            break;
                                        case "2":
                                            if (!_moduleTemplateAttribute.ParentClassName.includes(classNameControl[0])) {
                                                _moduleTemplateAttribute.ParentClassName = `${_moduleTemplateAttribute.ParentClassName} ${classNameControl[0]}`;
                                            }
                                            break;
                                        default:
                                            break;
                                    }
                                    _moduleTemplateAttribute.ParentClassName = _moduleTemplateAttribute.ParentClassName.trim();
                                }

                                return _moduleTemplateAttribute;
                            });
                            break;
                        }
                    }
                });

                modifyModuleTemplateAttribute({
                    component,
                    moduleTemplateAttributeArray: moduleTemplateAttributes,
                    moduleTemplateDataModule: params.moduleTemplateDataModule
                });
            }
        }
    }
}

const getGenericList = (params) => {
    let attribute = params.attribute,
        moduleTemplateAttribute = params.moduleTemplateAttribute,
        module = params.module,
        moduleFn = params.moduleFn,
        isUpdate = params.moduleTemplateIsUpdate,
        requestjson = {
            Module: "generic_generic_list",
            Parameters: {
                ObjectType: ""
            }
        },
        config = {
            Data: { requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        },
        value;

    if (module) {
        requestjson.Parameters.ObjectType = module;
        genericQuery(config);
    }

    function responseSuccessCallback(responseJson) {
        let data = responseJson.data,
            status = data.Status;

        if (status.IsSuccess) {
            if (data) {
                let _data = data.Data,
                    defaultValue = moduleTemplateAttribute.DefaultValue,
                    onSelect = getFnForModule({
                        module: moduleFn,
                        fn: 'onSelect'
                    });

                if (!onSelect && attribute.RW === 2 && isUpdate) {
                    onSelect = onChangeSelect2;
                }

                select2Initialize({
                    id: params.ID,
                    allowClear: moduleTemplateAttribute.IsSelect2Nullable,
                    data: _data,
                    containerCssClass: 'input is-medium',
                    onSelect2Select: onSelect,
                    extraData: params
                });

                if (attribute.Value) {
                    value = attribute.Value;
                } else if (defaultValue) {
                    value = defaultValue;
                }

                if (value) {
                    select2SetSelectedData({
                        id: params.ID,
                        value: parseInt(value)
                    });

                    select2TriggerEvent({
                        id: params.ID,
                        type: 'select2:select'
                    });
                }
            }
        }
    }

    function responseFailCallback(responseJson) {
        if (responseJson.Cancel && responseJson.Cancel.message != undefined) {
            iziToastFn({
                mode: 3,
                title: "Error",
                message: responseJson.message
            });
        }
    }
}

const getFnForModule = (params) => {
    let module = params.module,
        fn = params.fn;

    if (module) {
        return module[fn];
    }

    return null;
}

const getFnForModuleOtherType = (params) => {
    let module = params.module,
        index = params.index,
        fn = params.fn;

    if (module) {
        if (module['OtherType'][index]) {
            return module['OtherType'][index][fn];
        }
    }

    return null;
}

const getSettingForModuleOtherType = (params) => {
    let module = params.module,
        index = params.index,
        setting = params.setting;

    if (module) {
        if (module['OtherType'][index]) {
            return module['OtherType'][index][setting];
        }
    }

    return null;
}

const processFormElementOtherType = (params) => {
    let attribute = params.attribute,
        moduleTemplateAttribute = params.moduleTemplateAttribute,
        generatedField,
        key = params.key,
        index = params.index,
        otherType = params.otherType,
        moduleFn = params.moduleFns[`${attribute.DBObjectAttributeID}`],
        moduleSetting = params.moduleSettings[`${attribute.DBObjectAttributeID}`],
        parentClassName = otherType.ParentClassName;

    if (otherType.IsFileUpload) {
        generatedField = generate_fileUploadField({
            ...params,
            moduleFn,
            moduleSetting
        });
    }

    if (generatedField) {
        return (
            <div className={parentClassName} key={`${key}-${index}`}>
                {generatedField}
            </div>
        )
    }
}

const generate_fileUploadField = (params) => {
    let attribute = params.attribute,
        moduleTemplateAttribute = params.moduleTemplateAttribute,
        generatedField,
        otherType = params.otherType,
        index = params.index,
        defaultLabel = otherType.DefaultLabel,
        labelID = attribute.LabelID,
        module = params.moduleTemplateDataModule,
        id = `${module}-${attribute.DBObjectAttributeID}-${index}`,
        Label = processLabel({ LabelID: labelID, DefaultLabel: defaultLabel });

    generatedField = (
        <Dropzone onDrop={getFnForModuleOtherType({
            module: params.moduleFn,
            index,
            fn: 'OnDrop'
        })}>
            {({ getRootProps, getInputProps }) => {

                return (
                    <section>
                        <label htmlFor={id} className="form-label">{Label}</label>
                        <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps({
                                multiple: false,
                                accept: otherType.AcceptedFile
                            })}
                                data-module={module}
                                data-ref={
                                    encodeToBtoa(
                                        JSON.stringify(attribute)
                                    )
                                }
                                data-othertype={
                                    encodeToBtoa(
                                        JSON.stringify(otherType)
                                    )
                                }
                                data-moduletemplateattribute={
                                    encodeToBtoa(
                                        JSON.stringify(moduleTemplateAttribute)
                                    )
                                } />
                            <p className="instruction">{texts[0]}</p>
                            {
                                getSettingForModuleOtherType({
                                    module: params.moduleSetting,
                                    index,
                                    setting: 'Thumbnail'
                                })
                            }
                        </div>
                    </section>
                )
            }}
        </Dropzone>
    )

    return generatedField;
}

const onFocus = (params, event) => {
    let moduleTemplateAttribute = params.moduleTemplateAttribute,
        elementClassName = moduleTemplateAttribute.ElementClassName,
        shouldUpdate = false;

    if (moduleTemplateAttribute.InputMessage) {
        delete moduleTemplateAttribute.InputMessage;
        delete moduleTemplateAttribute.InputMessageType;

        shouldUpdate = true;
    }

    if (elementClassName) {
        let shouldUpdateElementClassName = false;

        if (elementClassName.includes(inputMessageType[1])) {
            elementClassName = elementClassName.replace(inputMessageType[1], '');

            shouldUpdateElementClassName = true;
        } else if (elementClassName.includes(inputMessageType[3])) {
            elementClassName = elementClassName.replace(inputMessageType[3], '');

            shouldUpdateElementClassName = true;
        }

        if (shouldUpdateElementClassName) {
            elementClassName = elementClassName.trim();
            moduleTemplateAttribute.ElementClassName = elementClassName;
            shouldUpdate = true;
        }
    }

    if (shouldUpdate) {
        modifyModuleTemplateAttribute({
            component: params.component,
            moduleTemplateAttribute,
            moduleTemplateDataModule: params.moduleTemplateDataModule
        });
    }
}

const onChange = (params, event) => {
    let attribute = params.attribute,
        moduleTemplateAttribute = params.moduleTemplateAttribute,
        proceedUpdate = false;

    if (moduleTemplateAttribute.Rule) {
        let targetElementJSON = formElemValueGetter({ elem: document.getElementById(params.key) });

        processRule({
            ...params,
            value: targetElementJSON.value
        });
    }

    if (attribute.IsSelectList) {

    } else {
        switch (attribute.AttribTypeID) {
            case 102: // String
            case 202: // BigInt
            case 203: // Double
            case 201: // Int
            case 204: // Money
            case 205: // Real
                break;
            case 200: // Bit
                break;
            case 302: // Date
                moduleTemplateAttribute.Value = event;
                proceedUpdate = true;
                break;
            case 301: // DateTime
                moduleTemplateAttribute.Value = event;
                proceedUpdate = true;
                break;
            case 402: // Email
                break;
            case 401: // Image
                break;
            case 303: // Time
                moduleTemplateAttribute.Value = event;
                proceedUpdate = true;
                break;
            case 403: // URL
                break;
            default:
                break;
        }
    }

    if (proceedUpdate) {
        modifyModuleTemplateAttribute({
            component: params.component,
            moduleTemplateDataModule: params.moduleTemplateDataModule,
            moduleTemplateAttribute
        });
    }
}

const onChangeSelect2 = (event, params) => {
    let data = event.params.data;

    if (data.selected) {
        onUpdateByRID(params, event);
    }
}

const onChangeDatePicker = (params, date, event) => {
    let moduleTemplateAttribute = params.moduleTemplateAttribute;

    moduleTemplateAttribute.Value = date;

    modifyModuleTemplateAttribute({
        component: params.component,
        moduleTemplateDataModule: params.moduleTemplateDataModule,
        moduleTemplateAttribute: params.moduleTemplateAttribute,
        setStateCallBack: () => {
            onUpdateByRID(params, { target: document.getElementById(params.key) });
        }
    });
}

const onChangeGeneratedFieldMediaLibraryFormModule = (params, currentSelectedMediaFiles) => {
    let { attribute, moduleTemplateAttribute } = params,
        max = moduleTemplateAttribute.Max || 0,
        values = [],
        valuesFK;

    for (let x = 0; x < currentSelectedMediaFiles.length; x++) {
        if (values.length < max) {
            values.push(currentSelectedMediaFiles[x].MediaFileID);
            valuesFK = currentSelectedMediaFiles[x];
            break;
        }
    }

    updateByRID({
        OldValue: attribute.Value,
        NewValue: values,
        RID: `${attribute.RID}.${attribute.AttributeName}`,
        ref: attribute,
        ...params,
        valuesFK
    });
}

const onClickRemoveGroupAttributeFromModuleAttributes = (params, event) => {
    const moduleTemplateGroupRID = params.moduleTemplateGroup.RID;

    if (moduleTemplateGroupRID) {
        onDeleteByRID({
            ...params,
            RID: moduleTemplateGroupRID
        });
    } else {
        removeGroupAttributeFromModuleAttributes(params);
    }
}

const onChangeCKEditorChange = (params, event, editor) => {
    let data = editor.getData(),
        { moduleTemplateAttribute } = params;

    moduleTemplateAttribute.Value = data;

    modifyModuleTemplateAttribute({
        component: params.component,
        moduleTemplateAttribute,
        moduleTemplateDataModule: params.moduleTemplateDataModule
    });
}

const onDropDropZone = (params, acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
        let moduleTemplateAttribute = params.moduleTemplateAttribute;

        moduleTemplateAttribute.Value = acceptedFiles[0];

        modifyModuleTemplateAttribute({
            component: params.component,
            moduleTemplateAttribute,
            moduleTemplateDataModule: params.moduleTemplateDataModule
        });
    }
}

const onUpdateByRID = (params, event) => {
    try {
        let targetElement = event.target,
            targetElementJSON = formElemValueGetter({ elem: targetElement }),
            ref,
            targetValue,
            refValue,
            moduleTemplateAttribute,
            shouldProceed = true;

        if (targetElementJSON) {
            ref = targetElementJSON.ref;
            targetValue = targetElementJSON.value;
            moduleTemplateAttribute = targetElementJSON.moduleTemplateAttribute;
            refValue = ref.Value;

            if (refValue != targetValue) {
                if (!targetElementJSON.shouldProceed) {
                    shouldProceed = false;

                    insertInputMessageToModuleTemplate({
                        component: params.component,
                        moduleTemplateAttribute,
                        moduleTemplateDataModule: params.moduleTemplateDataModule,
                        message: targetElementJSON.shouldProceedReason
                    });
                }
            } else {
                shouldProceed = false;
            }
        } else {
            shouldProceed = false;
        }

        if (shouldProceed) {
            updateByRID({
                OldValue: refValue,
                NewValue: targetValue,
                RID: `${ref.RID}.${ref.AttributeName}`,
                ref,
                targetElement,
                ...params
            });
        }
    } catch (Exception) {
        console.log(Exception);
    }
}

export const updateByRID = (params) => {
    const moduleTemplateDataModule = params.moduleTemplateDataModule;
    let fns = params.fns,
        componentGetter,
        ref = params.ref,
        targetElement = params.targetElement,
        valuesFK = params.valuesFK,
        formData = new FormData(),
        config = {
            ExtraData: params,
            Data: {},
            Method: "PUT",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/update_by_rid/"
        };

    fns = fns ? fns : {};
    componentGetter = fns.componentGetter;

    formData.append('NewValue', params.NewValue);
    formData.append('OldValue', params.OldValue);
    formData.append('RID', params.RID);
    formData.append('userID', getEmployeeID());

    config.Data.requestjson = formData;

    isUpdateByRIDProcessing({
        isProcessing: true,
        ...params
    });

    genericQuery(config);

    function responseSuccessCallback(responseJson) {
        console.log("responseSuccessCallback", responseJson);

        isUpdateByRIDProcessing({
            isProcessing: false,
            isSuccess: true,
            ...params
        });

        try {
            let data = responseJson.data,
                message = data.Message,
                status = data.Status;

            if (status.IsSuccess) {
                ref.Value = params.NewValue;

                if (valuesFK) {
                    ref['ForeignKey'] = valuesFK;
                }
            } else {
                iziToastFn({
                    mode: 3,
                    title: message[0],
                    message: message[1]
                });
            }

            modifyModuleTemplateAttribute({
                moduleAttribute: ref,
                moduleTemplateDataModule,
                component: params.component
            });

            processRule({
                ...params,
                value: params.NewValue
            });
        } catch (Exception) {
            iziToastFn({
                mode: 3,
                title: 'Error',
                message: Exception.message
            });
        }
    }

    function responseFailCallback(responseJson) {
        iziToastFn({
            mode: 3,
            message: responseJson.message
        });

        isUpdateByRIDProcessing({
            isProcessing: false,
            isFail: true,
            ...params
        });

        try {
            // formElemValueSetter({
            //     elem: targetElement,
            //     value: ref.Value
            // });
        } catch (Exception) {
            console.log(Exception);
        }
    }
}

const isUpdateByRIDProcessing = (params) => {
    const isProcessing = params.isProcessing,
        loadingClassName = 'is-loading',
        disabledClassName = 'is-disabled';
    let fns = params.fns,
        componentGetter,
        moduleTemplateAttribute = params.moduleTemplateAttribute,
        elementWrapperClassName = moduleTemplateAttribute.ElementWrapperClassName,
        elementClassName = moduleTemplateAttribute.ElementClassName,
        shouldProceed = true;

    fns = fns ? fns : {};

    if (!params.IsSelect2) {
        if (elementWrapperClassName) {
            componentGetter = fns.componentGetter;
            if (isProcessing) {
                if (!elementWrapperClassName.includes(loadingClassName)) {
                    elementWrapperClassName = `${elementWrapperClassName} ${loadingClassName}`;
                }

                if (!elementWrapperClassName.includes(disabledClassName)) {
                    elementWrapperClassName = `${elementWrapperClassName} ${disabledClassName}`;
                }
            } else {
                if (elementWrapperClassName.includes(loadingClassName)) {
                    elementWrapperClassName = elementWrapperClassName.replace(loadingClassName, '');
                }

                if (elementWrapperClassName.includes(disabledClassName)) {
                    elementWrapperClassName = elementWrapperClassName.replace(disabledClassName, '');
                }

                elementWrapperClassName = elementWrapperClassName.trim();
            }

            moduleTemplateAttribute.ElementWrapperClassName = elementWrapperClassName;
        }

        if (elementClassName) {
            if (isProcessing) {

            } else {
                if (params.isSuccess) {
                    if (!elementClassName.includes(inputMessageType[1])) {
                        elementClassName = `${elementClassName} ${inputMessageType[1]}`;
                    }
                } else if (params.isFail) {
                    if (!elementClassName.includes(inputMessageType[3])) {
                        elementClassName = `${elementClassName} ${inputMessageType[3]}`;
                    }
                }
            }

            moduleTemplateAttribute.ElementClassName = elementClassName;
        }

        if (shouldProceed) {
            if (componentGetter) {
                modifyModuleTemplateAttribute({
                    moduleTemplateAttribute,
                    moduleTemplateDataModule: params.moduleTemplateDataModule,
                    componentGetter
                });
            }
        }
    }
}

export const insertInputMessageToModuleTemplate = (params) => {
    const component = params.component,
        message = params.message,
        moduleTemplateAttribute = params.moduleTemplateAttribute,
        moduleTemplateDataModule = params.moduleTemplateDataModule;

    moduleTemplateAttribute['InputMessage'] = message;
    moduleTemplateAttribute['InputMessageType'] = 3;

    modifyModuleTemplateAttribute({
        component,
        moduleTemplateAttribute,
        moduleTemplateDataModule
    });
}

export let insertGroupAttributeIntoModuleAttributes = (params) => {
    const component = params.component,
        groupCode = params.groupCode,
        values = params.values,
        returnData = params.returnData;
    let formTemplate = JSON.parse(JSON.stringify(params.formTemplate));

    if (component) {
        let moduleTemplate = formTemplate.ModuleTemplate,
            moduleTemplateAttributes = moduleTemplate.Attributes,
            moduleTemplateGroups = moduleTemplate.Groups,
            moduleTemplateGroup,
            moduleTemplateAttributesGroup,
            maxGroupID = 0,
            newItems = [],
            newItem = {},
            proceedUpdate = false;

        moduleTemplateGroup = moduleTemplateGroups.find(_moduleTemplateGroup => {
            return _moduleTemplateGroup.GroupCode === groupCode;
        });

        moduleTemplateAttributesGroup = moduleTemplateAttributes.filter(_moduleTemplateAttribute => {
            if (_moduleTemplateAttribute.GroupCode === groupCode) {
                if (_moduleTemplateAttribute.GroupID > maxGroupID) {
                    maxGroupID = _moduleTemplateAttribute.GroupID;
                }

                return true;
            }
        });

        // newItem = {
        //     ...moduleTemplateGroup,
        //     GroupID: ++maxGroupID
        // };

        if (values) {
            values.forEach(_value => {
                let _newItem = {};
                _newItem = {
                    ...moduleTemplateGroup,
                    GroupID: ++maxGroupID
                };
                _newItem = JSON.parse(JSON.stringify(_newItem));

                _newItem.Children = _newItem.Children.map(_child => {
                    if (_value[`${_child.ID}`]) {
                        _child['Value'] = _value[`${_child.ID}`];
                        _child['RandomID'] = maxGroupID;
                    }

                    return _child;
                });

                newItems.push(_newItem);
            });
        }

        for (let x = 0; x < moduleTemplateAttributes.length; x++) {
            let _moduleTemplateAttribute = moduleTemplateAttributes[x];

            if (moduleTemplateAttributesGroup.length === 0) {
                let moduleTemplateGroupAfter = moduleTemplateGroup.After;

                if (moduleTemplateGroupAfter.ID && _moduleTemplateAttribute.ID === moduleTemplateGroupAfter.ID) {
                    moduleTemplateAttributes.splice(moduleTemplateAttributes.length + 1, 0, ...newItems);
                    proceedUpdate = true;
                    break;
                }
            } else if (_moduleTemplateAttribute.GroupCode === groupCode) {
                if (moduleTemplateAttributes[x + 1]) {
                    if (moduleTemplateAttributes[x + 1].GroupCode !== groupCode) {
                        moduleTemplateAttributes.splice(x + 1, 0, ...newItems);
                        proceedUpdate = true;
                        break;
                    }
                } else {
                    moduleTemplateAttributes.splice(x + 1, 0, ...newItems);
                    proceedUpdate = true;
                    break;
                }
            }
        }

        if (proceedUpdate) {
            let _params = {
                component,
                moduleTemplateAttributeArray: moduleTemplateAttributes,
                moduleTemplateDataModule: formTemplate.ModuleTemplate.DataModule
            };

            if (returnData) {
                return _params;
            } else {
                modifyModuleTemplateAttribute(_params);
            }
        }
    }
}

const initializeAttributesBasedOnGroup = (params) => {
    const attributes = params.attributes,
        moduleTemplateGroups = params.moduleTemplateGroups;

    let attributesWithGroups = attributes.filter(_attribute => {
        let extraData = _attribute.ExtraData;

        if (extraData) {
            return extraData.GroupCode;
        }

        return false;
    }), moduleTemplateAttributes = [];

    moduleTemplateGroups.forEach(_moduleTemplateGroup => {
        const groupCode = _moduleTemplateGroup.GroupCode,
            attributesBasedOnGroup = attributesWithGroups.filter(_attribute => {
                return _attribute.ExtraData.GroupCode === groupCode;
            }),
            attributesBasedOnGroupAndParentID = {};
        let newItems = []

        attributesBasedOnGroup.forEach(_attributesBasedOnGroup => {
            let extraData = _attributesBasedOnGroup.ExtraData;

            if (attributesBasedOnGroupAndParentID[`${extraData.ParentID}`] == null) {
                attributesBasedOnGroupAndParentID[`${extraData.ParentID}`] = {};
            }

            attributesBasedOnGroupAndParentID[`${extraData.ParentID}`][_attributesBasedOnGroup.DBObjectAttributeID] = _attributesBasedOnGroup;
        });

        Object.keys(attributesBasedOnGroupAndParentID).forEach(parentID => {
            let _attributesBasedOnGroupAndParentID = attributesBasedOnGroupAndParentID[parentID],
                _newItem = {
                    ..._moduleTemplateGroup,
                    GroupID: parentID
                };
            _newItem = JSON.parse(JSON.stringify(_newItem));

            _newItem.Children = _newItem.Children.map(_child => {
                const parentRID = _attributesBasedOnGroupAndParentID[_child.ID].ExtraData.ParentRID;

                _child['Value'] = _attributesBasedOnGroupAndParentID[_child.ID].Value;
                _child['RandomID'] = parentID;
                _child['RID'] = _attributesBasedOnGroupAndParentID[_child.ID].RID;

                if (parentRID && _newItem['RID'] == null) {
                    _newItem['RID'] = parentRID;
                }

                return _child;
            })

            newItems.push(_newItem);
        });

        moduleTemplateAttributes.push(...newItems);
    });

    return moduleTemplateAttributes;
}

export const removeGroupAttributeFromModuleAttributes = (params) => {
    const component = params.component,
        moduleTemplateGroup = params.moduleTemplateGroup,
        moduleTemplateDataModule = params.moduleTemplateDataModule,
        removeAllByGroup = params.removeAllByGroup,
        children = moduleTemplateGroup.Children;
    let proceedUpdate = false;

    if (component) {
        let formTemplate = JSON.parse(JSON.stringify(component.state.formTemplate)),
            moduleTemplate = formTemplate[moduleTemplateDataModule].ModuleTemplate,
            moduleTemplateAttributes = moduleTemplate.Attributes;

        for (let x = 0; x < moduleTemplateAttributes.length; x++) {
            let _moduleTemplateAttribute = moduleTemplateAttributes[x];

            if (removeAllByGroup) {
                if (moduleTemplateGroup.GroupCode === _moduleTemplateAttribute.GroupCode) {
                    moduleTemplateAttributes.splice(x, 1);
                    proceedUpdate = true;
                    --x;
                }
            } else {
                if (moduleTemplateGroup.GroupCode === _moduleTemplateAttribute.GroupCode && moduleTemplateGroup.GroupID === _moduleTemplateAttribute.GroupID) {
                    moduleTemplateAttributes.splice(x, 1);
                    proceedUpdate = true;
                    break;
                }
            }
        }

        if (proceedUpdate) {
            modifyModuleTemplateAttribute({
                component,
                moduleTemplateAttributeArray: moduleTemplateAttributes,
                moduleTemplateDataModule: moduleTemplateDataModule
            });
        }
    }
}

export const getValuesFromModuleTemplateGroup = (params) => {
    const moduleAttribute = params.moduleAttribute,
        attributeID = params.attributeID,
        groupCode = params.groupCode,
        dataModule = params.dataModule;
    let values = [];

    if (moduleAttribute) {
        moduleAttribute.forEach(_moduleAttribute => {
            if (_moduleAttribute.GroupCode === groupCode) {
                let children = _moduleAttribute.Children;

                children.forEach(_child => {
                    if (attributeID === _child.ID) {
                        values.push(_child.Value);
                    }
                });
            }
        });
    }

    return values;
}

export const getValueByKeyFromModuleTemplate = (params) => {
    const component = params.component,
        referredAttributeID = params.referredAttributeID,
        moduleTemplateDataModule = params.moduleTemplateDataModule,
        key = params.key;
    let formTemplate = params.formTemplate,
        moduleFormTemplate = params.moduleFormTemplate,
        val;

    if (component && referredAttributeID && key && key.length > 0) {
        if (!formTemplate) {
            formTemplate = component.state.formTemplate;
        }
        if (!moduleFormTemplate) {
            moduleFormTemplate = formTemplate[moduleTemplateDataModule];
        }

        if (moduleFormTemplate) {
            let attributes = moduleFormTemplate.Attributes;

            if (attributes) {
                for (let attributesindex = 0; attributesindex < attributes.length; attributesindex++) {
                    const attribute = attributes[attributesindex];

                    if (attribute.DBObjectAttributeID == referredAttributeID) {
                        val = attribute[key];
                        break;
                    }
                }
            }
        }

        return val;
    }
};

const modifyModuleTemplateAttribute = (params) => {
    const moduleAttribute = params.moduleAttribute,
        moduleTemplateAttributeArray = params.moduleTemplateAttributeArray,
        moduleTemplateAttribute = params.moduleTemplateAttribute,
        moduleTemplateDataModule = params.moduleTemplateDataModule,
        moduleTemplateIsNotYeTInitialized = params.moduleTemplateIsNotYeTInitialized,
        componentGetter = params.componentGetter,
        setStateCallBack = params.setStateCallBack;
    let component = params.component,
        componentGetterResult,
        state,
        formTemplate,
        moduleFormTemplate,
        newState = {};

    if (componentGetter || component) {
        if (component) {
            state = component.state;
        } else {
            componentGetterResult = componentGetter();
            component = componentGetterResult.component;
            state = componentGetterResult.state;
        }

        formTemplate = state.formTemplate;

        if (formTemplate) {
            moduleFormTemplate = formTemplate[moduleTemplateDataModule];

            if (moduleTemplateAttribute) {
                let moduleTemplateAttributes = moduleFormTemplate.ModuleTemplate.Attributes;

                if (moduleTemplateAttributes) {
                    moduleTemplateAttributes = moduleTemplateAttributes.map(_moduleTemplateAttribute => {
                        if (_moduleTemplateAttribute.ID === moduleTemplateAttribute.ID) {
                            _moduleTemplateAttribute = moduleTemplateAttribute;
                        }

                        return _moduleTemplateAttribute;
                    });

                    moduleFormTemplate.ModuleTemplate.Attributes = moduleTemplateAttributes;
                    formTemplate[moduleTemplateDataModule] = moduleFormTemplate;
                    newState['formTemplate'] = formTemplate;
                }
            }

            if (moduleAttribute) {
                let moduleAttributes = moduleFormTemplate.Attributes;

                if (moduleAttributes) {
                    moduleAttributes = moduleAttributes.map(_moduleAttribute => {
                        if (_moduleAttribute.RID) {
                            if (_moduleAttribute.DBObjectAttributeID === moduleAttribute.DBObjectAttributeID &&
                                _moduleAttribute.RID === moduleAttribute.RID) {
                                _moduleAttribute = moduleAttribute;
                            }
                        } else {
                            if (_moduleAttribute.DBObjectAttributeID === moduleAttribute.DBObjectAttributeID) {
                                _moduleAttribute = moduleAttribute;
                            }
                        }

                        return _moduleAttribute;
                    });

                    moduleFormTemplate.Attributes = moduleAttributes;
                    formTemplate[moduleTemplateDataModule] = moduleFormTemplate;
                    newState['formTemplate'] = formTemplate;
                }
            }

            if (moduleTemplateAttributeArray) {
                moduleFormTemplate.ModuleTemplate.Attributes = moduleTemplateAttributeArray;
                formTemplate[moduleTemplateDataModule] = moduleFormTemplate;
                newState['formTemplate'] = formTemplate;
            }

            if (moduleTemplateIsNotYeTInitialized != null) {
                if (newState['formTemplate']) {
                    formTemplate = newState['formTemplate'];
                }

                formTemplate[moduleTemplateDataModule].ModuleTemplate.moduleTemplateIsNotYeTInitialized = moduleTemplateIsNotYeTInitialized;
                newState['formTemplate'] = formTemplate;
            }

            component.setState(newState, () => {
                if (setStateCallBack) {
                    setStateCallBack();
                }
            });
        }
    }
}

export const alterModuleAttribute = (params) => {
    const targetID = params.targetID,
        otherTypeTargetID = params.otherTypeTargetID,
        moduleJSON = params.moduleJSON;
    let moduleTemplateAttribute = params.moduleTemplateAttribute,
        affectedTargetIDs = params.affectedTargetIDs,
        moduleJSONNot = params.moduleJSONNot;

    affectedTargetIDs = affectedTargetIDs ? affectedTargetIDs : [];
    moduleJSONNot = moduleJSONNot ? moduleJSONNot : {};

    moduleTemplateAttribute = moduleTemplateAttribute.map(_attribute => {
        let otherTypes = _attribute.OtherTypes;

        if (_attribute.ID === targetID) {
            if (otherTypeTargetID) {
                otherTypes = alterOtherType({
                    otherTypes: otherTypes,
                    otherTypeTargetID: otherTypeTargetID,
                    otherTypeJSON: moduleJSON,
                    otherTypeJSONNot: moduleJSONNot
                });
                _attribute = {
                    ..._attribute,
                    ...moduleJSONNot
                };
            } else {
                _attribute = {
                    ..._attribute,
                    ...moduleJSON
                };
                otherTypes = alterOtherType({
                    otherTypes: otherTypes,
                    otherTypeJSONNot: moduleJSONNot
                });
            }
        } else if (affectedTargetIDs.includes(_attribute.ID)) {
            _attribute = {
                ..._attribute,
                ...moduleJSONNot
            };
            otherTypes = alterOtherType({
                otherTypes: otherTypes,
                otherTypeJSONNot: moduleJSONNot
            });
        }

        _attribute.OtherTypes = otherTypes;

        return _attribute;
    });

    return moduleTemplateAttribute;
}

export const alterOtherType = (params) => {
    const otherTypeTargetID = params.otherTypeTargetID,
        otherTypeJSON = params.otherTypeJSON;
    let otherTypeJSONNot = params.otherTypeJSONNot,
        otherTypes = params.otherTypes;

    otherTypeJSONNot = otherTypeJSONNot ? otherTypeJSONNot : {};

    if (otherTypes) {
        otherTypes = otherTypes.map(_otherType => {
            if (otherTypeTargetID) {
                if (otherTypeTargetID === _otherType.ID) {
                    _otherType = {
                        ..._otherType,
                        ...otherTypeJSON
                    };
                } else {
                    _otherType = {
                        ..._otherType,
                        ...otherTypeJSONNot
                    };
                }
            } else {
                _otherType = {
                    ..._otherType,
                    ...otherTypeJSONNot
                };
            }

            return _otherType;
        });
    }

    return otherTypes;
}

/* ==========================================================================
   CMS' Form Generator
========================================================================== */

export const getGenericLabelListType1 = (params) => {
    const { template, component } = params,
        getTemplate = (_params) => {
            fetch(`${process.env.PUBLIC_URL}/data/cms/${template}`)
                .then((r) => r.json())
                .then((moduleTemplate) => {
                    if (moduleTemplate.LabelIDs) {
                        const { LabelIDs } = moduleTemplate;
                        let requestjson = {
                            Module: 'cms_labels_details-list',
                            Parameters: {
                                LabelIDs
                            }
                        }, config = {
                            Data: { requestjson },
                            Method: "GET",
                            ResponseSuccessCallback: responseSuccessCallback,
                            ResponseFailCallback: responseFailCallback,
                            Url: "/single_api/",
                            ExtraData: {
                                moduleTemplate
                            }
                        };

                        genericQuery(config);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }, responseSuccessCallback = (responseJson, config) => {
            const { data } = responseJson,
                { ExtraData } = config;

            if (data) {
                const { Status } = data;

                if (Status.IsSuccess) {
                    const { Data } = data,
                        { StaticLabels } = Data,
                        { state } = component,
                        { moduleTemplate } = ExtraData;
                    let newState = {};
                    newState['formTemplate'] = state.formTemplate || {};

                    if (moduleTemplate) {
                        newState['formTemplate'][moduleTemplate.Module] = {
                            ModuleTemplate: moduleTemplate,
                            Labels: StaticLabels,
                            Values: {}
                        };

                        component.setState(newState);
                    }
                } else {
                    iziToastFn({
                        mode: 3,
                        message: Status.Message
                    });
                }
            }
        }, responseFailCallback = (responseJson) => {
            // console.log(responseJson)
        }, processFormTemplate = (_params) => {

        }

    getTemplate();
}, formGenerator_type2 = (params) => {
    const renderButton = params.SubmitButton,
        renderAppendElementAfter = params.AppendElementAfter;

    let renderForm,
        formTemplate = params.FormTemplate,
        fns = params.Fns,
        settings = params.Settings;

    fns = fns ? fns : {};
    settings = settings ? settings : {};

    if (formTemplate) {
        // renderForm = (
        //     <div id={`form-${moduleTemplateDataModule}`} style={{ width: "100%" }} className={formClassName ? formClassName : ''}>
        //         {rendermoduleTemplateAttribute}
        //         {renderButton}
        //     </div>
        // )
    }

    return renderForm;
};

/* ==========================================================================
   Rule Values
========================================================================== */
/*
    1 = Show,
    2 = Hide
*/