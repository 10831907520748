import WOW from 'wow.js/dist/wow';

var THEMEIM = THEMEIM || {};

THEMEIM.initialize = {
    init: function () {
        THEMEIM.initialize.general();
    },
    general: function () {
        new WOW().init();
    }
};
THEMEIM.documentOnReady = {
    init: function () {
        THEMEIM.initialize.init();

    }
};

THEMEIM.documentOnReady.init();