const userSettingsDefaultState = {
    UserLanguage: null
},
    UserSettingsReducer = (state = userSettingsDefaultState, action) => {
        switch (action.type) {
            case 'MODIFY_USERSETTINGS_ITEM': {
                const { NewProps } = action.payload;
                
                return {
                    ...state,
                    ...NewProps
                }
            }
            default:
                return state;
        }
    }

const modifyUserSettingsItem = (data) => dispatch => {
    dispatch({
        type: 'MODIFY_USERSETTINGS_ITEM',
        payload: data
    });
};

export {
    UserSettingsReducer,
    modifyUserSettingsItem
};