import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from "prop-types";
import { openModal, closeModal, select2Initialize, genericQuery, iziToastFn, select2SetData, encodeToBtoa, formElemValueGetter, getEmployeeID, select2Destroy, processLabel } from '../../../helpers/helper';
import { LoadingBeatLoader, LMS_Pagination } from '../';
require('./index.scss');


const texts = [
    'Translation', // 0
    'Select a language/catalog', // 1
], elementIDs = [
    'modal-translationInstance', // 0
    'select-languageList' // 1
];

export default class LMS_TranslatorModule extends Component {
    constructor(props) {
        super(props);

        this.defaultState = {
            courseCatalogListParams: {
                pageNumber: 1,
                pageSize: 10,
                sortDirection: "0"
            },
            catalogList: {
                list: [],
                recordCount: 0,
                isLoading: false
            },
            selectedCatalog: {
                catalogID: null,
                catalogRID: null
            },
            defaultValueAttributes: [],
            translatedValueAttributes: [],
            isTranslationValueLoading: false
        };
        this.state = {
            selectedLanguageID: null,
            translationJSON: [],
            ...this.getDefaultState()
        };

        this.onSelect2Selecting = this.onSelect2Selecting.bind(this);
        this.onChangePagination = this.onChangePagination.bind(this);
        let LabelIDs = [-1240, -1241]
        texts.map((text, index) => {
            text = processLabel({ LabelID: LabelIDs[index], DefaultLabel: text })
            return text
        })
    }

    render() {
        const _this = this,
            { renderType, renderModalContentType } = this.props,
            modalID = _this.getElementID(0),
            openModalFn = () => {
                this.moduleQuery({
                    type: 1
                });
                this.getTranslationJSON();

                switch (renderModalContentType) {
                    case 1: {
                        this.moduleQuery({
                            type: 4
                        });
                        break;
                    }
                }
                openModal(modalID);
            };
        let renderItem,
            renderModalContent;

        switch (renderType) {
            case 1: {
                renderItem = (
                    <a title={texts[0]} onClick={openModalFn}><i className="fa fa-language"></i>
                    </a>
                );
                break;
            }
            case 2: {
                renderItem = (
                    <button className="button -primary-btn-lms btn-medium" title={texts[0]} onClick={openModalFn}><i className="fa fa-language pr-0"></i>
                    </button>
                )
                break;
            }
            default: {
                renderItem = (
                    <button className="button is-small -primary-btn-lms" title={texts[0]} onClick={openModalFn}>
                        <i className="fa fa-language pr-0"></i>
                    </button>
                );
                break;
            }
        }

        switch (renderModalContentType) {
            case 1: {
                renderModalContent = this.renderModalContentType_1();
                break;
            }
            default: {
                renderModalContent = this.renderModalContentType_default();
                break;
            }
        }

        return (
            <>
                {renderItem}
                {
                    createPortal(
                        <div id={modalID} className="modal modal-md -lms-modal-primary translatormodule__GcNfWv">
                            <div className="modal-background is-translucent"></div>
                            <div className="modal-card modal-content">
                                <div className="flex-card simple-shadow oflow-unset">
                                    <header className="modal-card-head">
                                        <div className="modal-card-title">
                                            {texts[0]}
                                        </div>
                                        <button className="delete is-medium" aria-label="close" onClick={() => {
                                            closeModal(modalID);
                                            this.setState({ ...this.getDefaultState() });
                                        }}></button>
                                    </header>
                                    <section className="modal-card-body">
                                        {renderModalContent}
                                    </section>
                                </div>
                            </div>
                        </div>
                        , document.getElementById("root"))
                }
            </>
        );
    }

    renderSearchElement() {
        const languageListID = this.getElementID(1);

        return (
            <div className="field">
                <div className="control">
                    <select id={languageListID} className="input is-medium" style={{ minWidth: "300px" }}>
                        <option></option>
                    </select>
                </div>
            </div>
        );
    }

    renderModalContentType_1() {
        const _this = this,
            { state } = _this,
            { catalogList, courseCatalogListParams } = state;
        let { selectedCatalog } = state,
            LMSPaginationParams,
            renderCatalogList,
            renderPagination;

        if (catalogList.isLoading) {
            renderCatalogList = (<LoadingBeatLoader />);
        } else {
            renderCatalogList = catalogList.list.map(item => {
                let itemClassName = 'flex-card light-bordered hover-inset';

                if (selectedCatalog.catalogRID == item.CourseCatalogRID) {
                    itemClassName = `${itemClassName} is-selected`;
                }
                return (
                    <div className={itemClassName} key={`translationlist-item__${item.CourseCatalogRID}`} onClick={() => {
                        selectedCatalog.catalogID = item.CourseCatalogID;
                        selectedCatalog.catalogRID = item.CourseCatalogRID;

                        _this.setState(selectedCatalog, () => {
                            if (this.isSelectedLanguageIDAndCatalogRIDSet()) {
                                this.moduleQuery({
                                    type: 0
                                });
                            }
                        });
                    }}>
                        <div className="card-body">
                            <div className="content">
                                <p>{item.Title}</p>
                            </div>
                        </div>
                    </div>
                );
            });

            if (catalogList.recordCount) {
                LMSPaginationParams = {
                    activePage: courseCatalogListParams.pageNumber,
                    itemsCountPerPage: courseCatalogListParams.pageSize,
                    totalItemsCount: catalogList.recordCount,
                    pageRangeDisplayed: 5,
                    onChange: this.onChangePagination
                };

                renderPagination = (
                    <LMS_Pagination {...LMSPaginationParams} />
                );
            }
        }

        return (
            <div className="translatormodule__CBuwYE columns">
                <div className="translatormodule__GmjGWc column is-4">
                    <div>{renderCatalogList}</div>
                    <div>{renderPagination}</div>
                </div>
                <div className="translatormodule__Sygttq column is-8">{this.renderTranslationForm()}</div>
            </div>
        );
    }

    renderModalContentType_default() {
        return (
            <div>
                {this.renderTranslationForm()}
            </div>
        );
    }

    renderTranslationCatalogList() {

    }

    renderTranslationForm() {
        const _this = this,
            { state } = _this,
            { defaultValueAttributes, translatedValueAttributes, translationJSON, selectedLanguageID, isTranslationValueLoading } = state;
        let renderReturn;

        if (isTranslationValueLoading) {
            renderReturn = (<LoadingBeatLoader />);
        } else {
            if (this.isSelectedLanguageIDAndCatalogRIDSet()) {
                if (defaultValueAttributes.length && translatedValueAttributes.length && translationJSON.length) {
                    renderReturn = [];

                    translationJSON.forEach((_translationJSON, index) => {
                        let defaultAttribute = defaultValueAttributes.find((item) => {
                            return item.DBObjectAttributeID == _translationJSON.ID;
                        }), translatedValueAttribute = translatedValueAttributes.find((item) => {
                            return item.DBObjectAttributeID == _translationJSON.ID;
                        });

                        if (defaultAttribute && translatedValueAttribute) {
                            let renderDefaultAttribute = this.processAttribute({ attribute: defaultAttribute, isReadOnly: true, translationJSON: _translationJSON }),
                                rendertranslatedValueAttribute = this.processAttribute({ attribute: translatedValueAttribute, translationJSON: _translationJSON });

                            if (renderDefaultAttribute) {
                                renderDefaultAttribute = (<div className="column">{renderDefaultAttribute}</div>);
                            }

                            if (rendertranslatedValueAttribute) {
                                rendertranslatedValueAttribute = (<div className="column">{rendertranslatedValueAttribute}</div>);
                            }

                            renderReturn.push(<div className="columns" key={`dboid-${_translationJSON.ID}-${Math.random() * 10 + 1}`}>
                                {renderDefaultAttribute}
                                {rendertranslatedValueAttribute}
                            </div>);
                        }
                    });

                    if (renderReturn.length > 0) {
                        renderReturn = (<div>{renderReturn}</div>);
                    }
                }
            } else {
                renderReturn = (<div>{texts[1]}</div>);
            }
        }

        renderReturn = (
            <>
                <div>{this.renderSearchElement()}</div>
                {renderReturn}
            </>
        )

        return renderReturn;
    }

    processAttribute(params) {
        const { attribute, isReadOnly, translationJSON } = params,
            { DefaultLabel } = attribute;
        let id,
            { ElementWrapperClassName, ElementClassName } = translationJSON,
            onBlur,
            renderAttribute,
            renderLabel,
            renderGeneratedField;

        switch (attribute.AttribTypeID) {
            case 102:
            case 202:
            case 203:
            case 201:
            case 204:
            case 205: {
                let dataRef = encodeToBtoa(JSON.stringify(attribute));

                if (isReadOnly) {
                    ElementClassName = `${ElementClassName} is-readonly`;
                } else {
                    id = `${attribute.DBObjectAttributeID}`;

                    if (attribute.RW == 2) {
                        onBlur = this.onBlur.bind(this, params);
                    } else if (attribute.RW == 1) {
                        ElementWrapperClassName = `${ElementWrapperClassName} is-disabled`;
                    }
                }


                if (translationJSON.IsTextArea) {
                    renderAttribute = (<div className={ElementWrapperClassName}>
                        <textarea
                            id={id}
                            data-ref={dataRef}
                            className={ElementClassName}
                            placeholder={DefaultLabel}
                            rows={translationJSON.Rows}
                            defaultValue={attribute.Value}
                            onBlur={onBlur}>
                        </textarea>
                    </div>);
                } else {
                    renderAttribute = (<div className={ElementWrapperClassName}>
                        <input
                            id={id}
                            data-ref={dataRef}
                            className={ElementClassName}
                            placeholder={DefaultLabel}
                            defaultValue={attribute.Value}
                            onBlur={onBlur}>
                        </input>
                    </div>);
                }

                break;
            }
        }

        renderLabel = (<label htmlFor={id} className={translationJSON.LabelClassName}>{DefaultLabel}</label>);

        renderGeneratedField = (<div className={translationJSON.ParentClassName}>
            {renderLabel}
            {renderAttribute}
        </div>);
        return renderGeneratedField;
    }

    onBlur(params, event) {
        const { attribute, translationJSON } = params,
            targetElement = event.target,
            targetElementJSON = formElemValueGetter({ elem: targetElement });

        if (targetElementJSON) {
            let value = targetElementJSON.value,
                { Value } = attribute;

            if (value != Value && value != "") {
                if (attribute.RID) {
                    this.moduleQuery({
                        type: 3,
                        attribute,
                        translationJSON,
                        RID: `${attribute.RID}.${attribute.AttributeName}`,
                        OldValue: Value,
                        NewValue: value
                    });
                } else {
                    this.moduleQuery({
                        type: 2,
                        attribute,
                        translationJSON,
                        LanguageID: this.state.selectedLanguageID,
                        RIDReference: attribute.RIDReference,
                        ColumnName: attribute.AttributeName,
                        Content: value
                    });
                }
            }
        }
    }

    modifyTranslationJSON(params) {
        const disabledStr = 'is-disabled',
            loadingStr = 'is-loading';
        let { translationJSON, isProcessing } = params,
            stateTranslationJSON = this.state.translationJSON;

        if (isProcessing) {
            if (!translationJSON.ParentClassName.includes(disabledStr)) {
                translationJSON.ParentClassName = `${translationJSON.ParentClassName} ${disabledStr}`;
            }
            if (!translationJSON.ElementWrapperClassName.includes(loadingStr)) {
                translationJSON.ElementWrapperClassName = `${translationJSON.ElementWrapperClassName} ${loadingStr}`;
            }
        } else {
            if (translationJSON.ParentClassName.includes(disabledStr)) {
                translationJSON.ParentClassName = translationJSON.ParentClassName.replace(disabledStr).trim();
            }
            if (translationJSON.ElementWrapperClassName.includes(loadingStr)) {
                translationJSON.ElementWrapperClassName = translationJSON.ElementWrapperClassName.replace(loadingStr).trim();
            }
        }

        stateTranslationJSON = stateTranslationJSON.map(_stateTranslationJSON => {
            if (_stateTranslationJSON.ID == translationJSON.ID) {
                _stateTranslationJSON = translationJSON;
            }

            return _stateTranslationJSON;
        });

        return stateTranslationJSON;
    }

    onSelect2Selecting(e) {
        const targetID = e.target.id,
            data = e.params.args.data;

        if (data) {
            const languageID = data.id;

            if (languageID) {
                this.setState({
                    selectedLanguageID: languageID
                }, () => {
                    if (this.isSelectedLanguageIDAndCatalogRIDSet()) {
                        this.moduleQuery({
                            type: 0
                        });
                    }
                });
            }
        }
    }

    onChangePagination(pageNumber) {
        let { courseCatalogListParams } = this.state,
            newState = {};

        courseCatalogListParams['pageNumber'] = pageNumber;
        newState['courseCatalogListParams'] = courseCatalogListParams;

        if (Object.keys(newState).length > 0) {
            this.setState(newState, () => {
                this.moduleQuery({
                    type: 4
                });
            });
        }
    }

    moduleQuery(params) {
        const _this = this,
            { courseCatalogRID } = _this.props,
            state = _this.state,
            { type, LanguageID, RIDReference, ColumnName, Content, RID, NewValue, OldValue, attribute, translationJSON } = params,
            { selectedLanguageID } = state;
        let Parameters = {},
            requestjson = {},
            config = {
                Data: { requestjson },
                Method: "GET",
                ResponseSuccessCallback: responseSuccessCallback,
                ResponseFailCallback: responseFailCallback,
                Url: "/single_api/"
            },
            newState = {},
            formData = new FormData();

        switch (type) {
            case 0:
                requestjson.Module = 'contenttranslation_contenttranslation_details';
                Parameters = {
                    RIDReference: courseCatalogRID ? courseCatalogRID : state.selectedCatalog.catalogRID,
                    LanguageID: selectedLanguageID
                };

                newState['isTranslationValueLoading'] = true;
                break;
            case 1: {
                requestjson.Module = 'generic_generic_list-with-search';
                Parameters = {
                    ObjectType: 'LMSLanguageSearch'
                };
                break;
            }
            case 2: {
                requestjson.Module = 'contenttranslation_contenttranslation_add';
                Parameters = {
                    LanguageID,
                    RIDReference,
                    ColumnName,
                    Content
                };
                newState['translationJSON'] = _this.modifyTranslationJSON({ translationJSON, isProcessing: true });
                break;
            }
            case 3: {
                formData.append('NewValue', NewValue);
                formData.append('OldValue', OldValue);
                formData.append('RID', RID);
                formData.append('userID', getEmployeeID());

                config.Data.requestjson = formData;
                config.Method = 'PUT';
                config.Url = '/update_by_rid/';

                newState['translationJSON'] = _this.modifyTranslationJSON({ translationJSON, isProcessing: true });
                break;
            }
            case 4: {
                const { courseCatalogListParams, catalogList } = _this.state,
                    { propsCourseCatalogListParams } = _this.props;
                let typeID,
                    { courseMatchDataRIDs, courseCatalogTypeID, categoryTypeID, moduleTypeID, canBeStandAloneModule } = propsCourseCatalogListParams;

                switch (courseCatalogTypeID) {
                    case 1:
                        typeID = moduleTypeID;
                        break;
                    default:
                        categoryTypeID = categoryTypeID;
                        break;
                }

                requestjson.Module = 'contenttranslation_catalog_list';
                Parameters = {
                    EmployeeID: getEmployeeID(),
                    PageNumber: courseCatalogListParams.pageNumber,
                    PageSize: courseCatalogListParams.pageSize,
                    SortDirection: courseCatalogListParams.sortDirection,
                    SearchValue: propsCourseCatalogListParams.searchValue,
                    CourseCatalogTypeID: courseCatalogTypeID ? courseCatalogTypeID : null,
                    RIDs: courseMatchDataRIDs.join(),
                    TypeID: categoryTypeID,
                    CanBeStandAloneModule: canBeStandAloneModule
                };

                catalogList.isLoading = true;
                newState['catalogList'] = catalogList;
                break;
            }
        }

        if (Object.keys(newState).length > 0) {
            _this.setState(newState, () => {
                processRequest();
            });
        } else {
            processRequest();
        }

        function processRequest() {
            requestjson.Parameters = Parameters;
            newState = {};
            config = genericQuery(config);
        }

        function responseSuccessCallback(responseJson) {
            const { state } = _this;
            let data = responseJson.data,
                status = data.Status,
                callbackType;

            if (status.IsSuccess) {
                let _data = data.Data,
                    languageList,
                    defaultValueAttributes,
                    translatedValueAttributes,
                    contentTranslation,
                    catalogList;

                if (_data) {
                    languageList = _data.List;
                    defaultValueAttributes = _data.DefaultValueAttributes;
                    translatedValueAttributes = _data.TranslatedValueAttributes;
                    contentTranslation = _data.ContentTranslation;
                    catalogList = _data.CatalogList;
                }

                switch (type) {
                    case 0: {
                        defaultValueAttributes = defaultValueAttributes.Attributes || [];
                        translatedValueAttributes = translatedValueAttributes.Attributes || [];

                        translatedValueAttributes = translatedValueAttributes.map(translatedValueAttribute => {
                            if (!translatedValueAttribute.RID) {
                                let defaultValueAttribute = defaultValueAttributes.find(_defaultValueAttribute => {
                                    return _defaultValueAttribute.DBObjectAttributeID == translatedValueAttribute.DBObjectAttributeID;
                                });

                                if (defaultValueAttribute) {
                                    translatedValueAttribute.RIDReference = defaultValueAttribute.RID;
                                }
                            }
                            return translatedValueAttribute;
                        });

                        newState['defaultValueAttributes'] = defaultValueAttributes;
                        newState['translatedValueAttributes'] = translatedValueAttributes;
                        newState['isTranslationValueLoading'] = false;
                        break;
                    }
                    case 1: {
                        const languageListID = _this.getElementID(1);

                        languageList = languageList || [];
                        select2SetData({
                            id: languageListID,
                            data: languageList,
                            isRefresh: true,
                            hasEmptyValue: true
                        });
                        break;
                    }
                    case 2: {
                        if (contentTranslation && contentTranslation.length > 0) {
                            const contentTranslated = contentTranslation[0];
                            let { translatedValueAttributes } = state,
                                hasChanges = false;

                            if (contentTranslated) {
                                translatedValueAttributes = translatedValueAttributes.map(translatedValueAttribute => {
                                    let proceedChangeValueAndRID = false;

                                    if (translatedValueAttribute.RIDReference == contentTranslated.RIDReference) {
                                        proceedChangeValueAndRID = true;

                                        if (contentTranslated.ColumnName) {
                                            proceedChangeValueAndRID = translatedValueAttribute.AttributeName == contentTranslated.ColumnName;
                                        }
                                    }

                                    if (proceedChangeValueAndRID) {
                                        translatedValueAttribute.RID = contentTranslated.RID;
                                        translatedValueAttribute.Value = contentTranslated.Content;
                                        hasChanges = true;
                                    }

                                    return translatedValueAttribute;
                                });

                                if (hasChanges) {
                                    newState['translatedValueAttributes'] = translatedValueAttributes;
                                }

                                newState['translationJSON'] = _this.modifyTranslationJSON({ translationJSON });
                            }
                        }
                        break;
                    }
                    case 3: {
                        let { translatedValueAttributes } = state,
                            hasChanges = false;

                        translatedValueAttributes = translatedValueAttributes.map(translatedValueAttribute => {
                            if (translatedValueAttribute.RID == attribute.RID) {
                                translatedValueAttribute.Value = NewValue;
                                hasChanges = true;
                            }

                            return translatedValueAttribute;
                        });

                        if (hasChanges) {
                            newState['translatedValueAttributes'] = translatedValueAttributes;
                        }

                        newState['translationJSON'] = _this.modifyTranslationJSON({ translationJSON });
                    }
                    case 4: {
                        let stateCatalogList = _this.state.catalogList;

                        if (catalogList) {
                            stateCatalogList.list = catalogList.CatalogList;
                            stateCatalogList.recordCount = catalogList.RecordCount;
                        }

                        stateCatalogList.isLoading = false;

                        newState['catalogList'] = stateCatalogList;
                        break;
                    }
                }
            } else {
                iziToastFn({
                    mode: 3,
                    message: status.Message
                });

                switch (type) {
                    case 0: {
                        newState['isTranslationValueLoading'] = false;
                        break;
                    }
                    case 2: {
                        newState['translationJSON'] = _this.modifyTranslationJSON({ translationJSON });
                        break;
                    }
                    case 3: {
                        newState['translationJSON'] = _this.modifyTranslationJSON({ translationJSON });
                        break;
                    }
                    case 4: {
                        let stateCatalogList = _this.state.catalogList;

                        stateCatalogList.isLoading = false;
                        newState['catalogList'] = stateCatalogList;
                    }
                }
            }

            _this.setState(newState, () => {
                if (callbackType) {
                    newState = {};

                }
            });
        }

        function responseFailCallback(responseJson) {
            let callbackType;
            console.log("responseFailCallback", responseJson);

            if (responseJson.message !== "change request") {
                iziToastFn({
                    mode: 3,
                    message: responseJson.message
                });

                switch (type) {
                    case 0: {
                        newState['isTranslationValueLoading'] = false;
                        break;
                    }
                    case 2: {
                        newState['translationJSON'] = _this.modifyTranslationJSON({ translationJSON });
                        break;
                    }
                    case 3: {
                        newState['translationJSON'] = _this.modifyTranslationJSON({ translationJSON });
                        break;
                    }
                    case 4: {
                        let stateCatalogList = _this.state.catalogList;

                        stateCatalogList.isLoading = false;
                        newState['catalogList'] = stateCatalogList;
                    }
                }

                _this.setState(newState, () => {
                    if (callbackType) {
                        newState = {};
                    }
                });
            }
        }
    }

    getTranslationJSON() {
        fetch(`${process.env.PUBLIC_URL}/data/catalogTranslation.json`)
            .then((r) => r.json())
            .then((translationJSON) => {
                if (translationJSON) {
                    this.setState({
                        translationJSON
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    componentDidMount() {
        const languageListID = this.getElementID(1),
            { renderModalContentType } = this.props;

        select2Initialize({
            id: languageListID,
            containerCssClass: 'input is-medium',
            onSelect2Selecting: this.onSelect2Selecting,
            minimumInputLength: 0
        });
    }

    componentWillUnmount() {
        const languageListID = this.getElementID(1);

        select2Destroy({ id: languageListID });
    }

    getElementID(index) {
        const { courseCatalogRID, renderModalContentType } = this.props;
        let elementID;

        switch (renderModalContentType) {
            case 1:
                elementID = `${elementIDs[index]}-renderModalContentType-${renderModalContentType}`;
                break;
            default:
                elementID = `${elementIDs[index]}-${courseCatalogRID}`;
                break;
        }

        return elementID;
    }

    getDefaultState() {
        return JSON.parse(JSON.stringify(this.defaultState));
    }

    isSelectedLanguageIDAndCatalogRIDSet() {
        const { selectedLanguageID, selectedCatalog } = this.state;

        switch (this.props.renderModalContentType) {
            case 1:
                return selectedLanguageID && selectedCatalog.catalogRID;
            default:
                return selectedLanguageID;
        }
    }

    static propTypes = {
        courseCatalogID: PropTypes.number,
        renderType: PropTypes.number
    }
}