import { LMSLabelIDs } from "./helper";

const TOKEN_STR = 'lms-token',
    EMPLOYEEID_STR = 'lms-employeeID',
    MENU = 'lms-menu',
    TAR_STR = 'lms-TAR',
    EMP_NAME = 'lms-employeeName',
    EMP_LANGUAGE = 'lms-employeeLanguage',
    LMS_LABELS = 'lms-labels';

export const setToken = (token) => {
    localStorage.setItem(TOKEN_STR, token);
}, getToken = () => {
    return localStorage.getItem(TOKEN_STR);
}, removeToken = () => {
    localStorage.removeItem(TOKEN_STR);
};

export const setEmployeeID = (employeeID) => {
    localStorage.setItem(EMPLOYEEID_STR, employeeID);
}, getEmployeeID = () => {
    return localStorage.getItem(EMPLOYEEID_STR);
}, removeEmployeeID = () => {
    localStorage.removeItem(EMPLOYEEID_STR);
};

export const setMenu = (menu) => {
    localStorage.setItem(MENU, JSON.stringify(menu));
}, getMenu = () => {
    return localStorage.getItem(MENU);
}, removeMenu = () => {
    localStorage.removeItem(MENU);
};

export const setTAR = (item) => {
    localStorage.setItem(TAR_STR, item);
}, getTARForUser = () => {
    return localStorage.getItem(TAR_STR);
}, removeTAR = () => {
    localStorage.removeItem(TAR_STR);
}

export const setEmpName = (item) => {
    localStorage.setItem(EMP_NAME, item);
}, getEmpName = () => {
    return localStorage.getItem(EMP_NAME);
}, removeEmpName = () => {
    localStorage.removeItem(EMP_NAME);
}

export const clearLocalStorage = () => {
    removeEmpName()
    removeMenu()
    removeTAR()
    removeEmployeeID()
    removeToken()
    removeEmployeeID()
}

export const setEmployeeLanguage = (employeeLanguage) => {
    employeeLanguage = JSON.stringify(employeeLanguage)

    localStorage.setItem(EMP_LANGUAGE, employeeLanguage)
}, removeEmployeeLanguage = () => {
    localStorage.removeItem(EMP_LANGUAGE)
}, getEmployeeLanguage = () => {
    return localStorage.getItem(EMP_LANGUAGE)
}

export const setLMSLabels = (LMSLabels) => {
    LMSLabels = JSON.stringify(LMSLabels)
    localStorage.setItem(LMS_LABELS, LMSLabels)
}, removeLMSLabels = () => {
    localStorage.removeItem(LMS_LABELS)
}, getLMSLabels = () => {
    return localStorage.getItem(LMS_LABELS)
}