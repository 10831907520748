import React from 'react';
import { BeatLoader, BounceLoader } from "react-spinners";
require('./index.scss');

export default class LoadingBeatLoader extends React.Component {
    render() {
        return (
            <div className="loading__eVGsXN sweet-loading">
                <BeatLoader
                    loading={true}
                />
            </div>
        );
    }
}

class LoadingBounceLoader extends React.Component {
    render() {
        return (
            <div className="loading__eVGsXN sweet-loading">
                <BounceLoader
                    loading={true}
                />
            </div>
        );
    }
}

class LoadingBounceLoader_Absolute extends React.Component {
    render() {
        return (
            <div className="loading__eVGsXN loading__DCbpSN sweet-loading">
                <BounceLoader
                    loading={true}
                />
            </div>
        );
    }
}
class LoadingBeatLoader_Absolute extends React.Component {
    render() {
        return (
            <div className="loading__eVGsXN loading__DCbpSN sweet-loading">
                <BeatLoader
                    loading={true}
                />
            </div>
        );
    }
}

export {
    LoadingBeatLoader,
    LoadingBounceLoader_Absolute,
    LoadingBeatLoader_Absolute,
    LoadingBounceLoader
};