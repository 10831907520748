import React from 'react';
import { getToken } from '../../helpers/localStorage';
import { Route, Redirect } from 'react-router-dom';
import { BulkitNeutral } from '../../component/layouts/bulkit/index';

export const BulkitNeutralRoute = ({ component: Component, ...rest }) => {
    let pathName = window.location.pathname,
        returnData;

    if ((pathName === "/logout" && !getToken()) || (pathName === "/login" && getToken())) {
        returnData = (
            <Redirect to="/home" />
        );
    } else {
        returnData = (
            <Route
                {...rest}
                render={props =>
                    <BulkitNeutral
                        Component={Component}
                        {...props}
                    />
                }
            />
        )
    }

    return returnData;
}

export default BulkitNeutralRoute;