import { combineReducers } from 'redux';
import { FormTemplateReducer, LabelReducer } from '../../component/imports/reducer.js';
import { UserSettingsReducer } from '../mixin/UserSettings';
import { SystemSettingsReducer } from '../mixin/SystemSettings';

/* Start Notifications */

const notificationsConnect = (operations = null, action) => {
    if (action.type == 'NOTIFICATIONS_CONNECT') {
        return action.payload;
    } else {
        return operations;
    }
}

/* End Notifications */


const defaultGlobalState = {
    Labels: [],
    SelectedLabel: {},
    isLabelEditMode: false
}

const globalVariables = (state = defaultGlobalState, action) => {
    switch (action.type) {
        case "GLOBAL-SET-LABELS":
            return {
                ...state,
                Labels: action.payload
            }
        case 'GLOBAL_LABEL-SET_SELECTED': {
            return {
                ...state,
                SelectedLabel: action.payload
            }
        }
        case 'GLOBAL_LABEL-SET_EDIT_MODE':
            return {
                ...state,
                isLabelEditMode: action.payload
            }
        default:
            return state
    }
}

let catalogDefaultState = {
    Settings: {
        List: {
            Loading: true,
            RecordCount: 0,
            Page: 1,
        },
        SelectedCatalog: {
            Title: null,
            CourseCatalogID: null,
            AssignedSurvey: []
        },
        isAgressiveFilter: true,
        IsCourseMatchDataShowing: false,
        IsRecommendToUserShowing: false,
        IsSurveyShowing: false,
        isFilterLoading: false,
        isFilterShowing: false,
        IsCourseLocationShowing: false,
        OnboardingSettings: {
            IsLoading: true,
            IsShowing: false
        }, OnboardingDefault: {
            IsActive: 1,
            IsForAllEmployees: 1,
            IsForLocation: 0,
            IsMandatory: 1,
        }

    },
    List: {
        Data: [],
        Page: 0,
        RecordCount: 0,
        PageSize: 12,
    },
    Detail: {
        SurveyDetail: []
    }
}

const Catalog = (state = catalogDefaultState, action) => {
    switch (action.type) {
        case "CATALOG_LIST-SET_DATA":

            return {
                ...state,
                List: {
                    ...state.List,
                    Data: action.payload
                }
            }
        case 'CATALOG_LIST-SET_SETTINGS':
            return {
                ...state, Settings: action.payload
            }
        case "CATALOG_LIST-SET_SELECTED_CATALOG":
            return {
                ...state, Settings: {
                    ...state.Settings,
                    SelectedCatalog: action.payload
                }
            }
        case "CATALOG_OVERVIEW_DETAILS-SET-SURVEY-DETAIL":
            return {
                ...state, Detail: {
                    ...state.Detail,
                    SurveyDetail: action.payload
                }
            }
        default:
            return state
    }
}



let courseMatchDataDefaultState = {
    Settings: {
        IsCourseMatchDataShowing: false,
        Loading: true,
    },
    Data: {
        FunctionalDiscipline: [],
        Location: [],
        OrganisationalUnit: [],
        Position: [],
        PositionClass: [],
        PositionType: [],
    }

}
const CourseMatchData = (state = courseMatchDataDefaultState, action) => {
    switch (action.type) {
        case "SET_COURSE_MATCH_DATA-SETTINGS":
            return {
                ...state,
                Settings: action.payload
            }
        case "SET_COURSE_MATCH_DATA-DATA":
            return {
                ...state,
                Data: action.payload
            }
        default:
            return state
    }

}


let defaultOnboardingState = {
    Settings: {
        Loading: true,
        Showing: false,
        LocationList: {
            Page: 1,
            PageSize: 12,
            RecordCount: 1
        }
    },
    Details: {
        IsActive: 1,
        IsForAllEmployees: 1,
        IsMandatory: 1,
        TargetDaysCompletionAfterStart: 10,
        OnboardingCourseLocations: []
    },

}


const Onboarding = (state = defaultOnboardingState, action) => {
    switch (action.type) {
        case "ONBOARDING-SET_ONBOARDING_COURSE_LOCATIONS":
            return {
                ...state, Details: {
                    ...state.Details, OnboardingCourseLocations: action.payload
                }
            }
        case "ONBOARDING-SET_SETTINGS":
            return {
                ...state, Settings: action.payload
            }
        case "ONBOARDING-SET_DETAILS":
            let { IsActive, IsMandatory, TargetDaysCompletionAfterStart } = action.payload
            return {
                ...state, Details: {
                    ...state.Details,
                    IsActive,
                    TargetDaysCompletionAfterStart,
                    IsMandatory
                }
            }
        default:
            return state
    }
}


const defaultSurveyResults = {
    Charts: {
        Settings: {
            ViewMode: 0,
            IsComparison: false,
        },
        Question: "1-5, How would you rate the trainer's knowledge about the course? 1 being the lowest and 5 being the highest.",
        Bar: {
            Data: {
                labels: ['Pre Survey', 'Post Survey'],
                datasets: [
                    {
                        label: 'Very Bad',
                        backgroundColor: 'rgba(232, 17, 45)',
                        borderColor: 'rgba(36, 41, 46)',
                        borderWidth: 1,
                        data: [0, 67]
                    },
                    {
                        label: 'Bad',
                        backgroundColor: 'rgba(232, 17, 45)',
                        borderColor: 'rgba(36, 41, 46)',
                        borderWidth: 1,
                        data: [0, 0]
                    },
                    {
                        label: 'Its okay',
                        backgroundColor: 'rgba(232, 17, 45)',
                        borderColor: 'rgba(36, 41, 46)',
                        borderWidth: 1,
                        data: [67, 33]
                    },
                    {
                        label: 'Good',
                        backgroundColor: 'rgba(232, 17, 45)',
                        borderColor: 'rgba(36, 41, 46)',
                        borderWidth: 1,
                        data: [33, 0]
                    },
                    {
                        label: 'Excellent',
                        backgroundColor: 'rgba(232, 17, 45)',
                        borderColor: 'rgba(36, 41, 46)',
                        borderWidth: 1,
                        data: [0, 0]
                    },

                ]
            }, Options: {
                legend: {
                    display: true,
                    position: 'right'
                }
            }
        },
        Doughnut: {
            Data: {
                labels: ['Very Bad', 'Bad', 'Its Okay',
                    'Good', 'Excellent'],
                datasets: [
                    {
                        label: 'Pre Survey',
                        backgroundColor: [
                            '#B21F00',
                            '#C9DE00',
                            '#2FDE00',
                            '#00A6B4',
                            '#6800B4'
                        ],
                        hoverBackgroundColor: [
                            '#501800',
                            '#4B5000',
                            '#175000',
                            '#003350',
                            '#35014F'
                        ],
                        data: [0, 0, 67, 33, 0]
                    }, {
                        label: 'Post Survey',
                        backgroundColor: [
                            '#B21F00',
                            '#C9DE00',
                            '#2FDE00',
                            '#00A6B4',
                            '#6800B4'
                        ],
                        hoverBackgroundColor: [
                            '#501800',
                            '#4B5000',
                            '#175000',
                            '#003350',
                            '#35014F'
                        ],
                        data: [67, 0, 33, 0, 0]
                    }
                ]
            }, Options: {
                legend: {
                    display: true,
                    position: 'right'
                }
            }
        }, Pie: {
            Data: {
                labels: ['Very Bad', 'Bad', 'Its Okay',
                    'Good', 'Excellent'],
                datasets: [
                    {
                        label: 'Pre Survey',
                        backgroundColor: [
                            '#B21F00',
                            '#C9DE00',
                            '#2FDE00',
                            '#00A6B4',
                            '#6800B4'
                        ],
                        hoverBackgroundColor: [
                            '#501800',
                            '#4B5000',
                            '#175000',
                            '#003350',
                            '#35014F'
                        ],
                        data: [0, 0, 67, 33, 0]
                    }, {
                        label: 'Post Survey',
                        backgroundColor: [
                            '#B21F00',
                            '#C9DE00',
                            '#2FDE00',
                            '#00A6B4',
                            '#6800B4'
                        ],
                        hoverBackgroundColor: [
                            '#501800',
                            '#4B5000',
                            '#175000',
                            '#003350',
                            '#35014F'
                        ],
                        data: [67, 0, 33, 0, 0]
                    }
                ]
            }, Options: {
                legend: {
                    display: true,
                    position: 'right'
                }
            }
        }
    }

}
const Survey_Results = (state = defaultSurveyResults, action) => {
    switch (action.type) {
        case "SURVEY_RESULTS-SET_RESULTS":
            return {

            }
        case "SURVEY_RESULTS-CHANGE_MODE":
            return {
                ...state, Charts: { ...state.Charts, Settings: { ...state.Charts.Settings, ViewMode: action.payload } }
            }
        case "SURVEY_RESULTS-ISCOMPARISON":
            return {
                ...state, Charts: { ...state.Charts, Settings: { ...state.Charts.Settings, IsComparison: action.payload } }
            }
        case "SURVEY_RESULTS-SET_DOUGHNUT_CHART":
            return {
                ...state, Charts: { ...state.Charts, Doughnut: action.payload }
            }
        case "SURVEY_RESULTS-SET_BAR_CHART":
            return {
                ...state, Charts: { ...state.Charts, Bar: action.payload }
            }
        case "SURVEY_RESULTS-SET_PIE_CHART":
            return {
                ...state, Charts: { ...state.Charts, Pie: action.payload }
            }
        case "SURVEY_RESULTS-SET_QUESTION":
            return {
                ...state, Charts: { ...state.Charts, Question: action.payload }
            }
        default:
            return state
    }
}



export default combineReducers({
    globalVariables,
    Catalog,
    CourseMatchData,
    Onboarding,
    Survey_Results,
    FormTemplateReducer,
    LabelReducer,
    UserSettingsReducer,
    SystemSettingsReducer
});