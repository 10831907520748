const formTemplateReducerDefaultState = { formTemplate: {} },
    labelReducerDefaultState = { labels: {} },
    fnModifyModuleTemplateByKey = (params) => {
        const { KeyValue, ModuleTemplateLabels, TemplateJSON } = params;

        if (ModuleTemplateLabels) {
            const labelJSONIndex = ModuleTemplateLabels?.findIndex(item => {
                return item.ID == TemplateJSON.ID;
            });

            if (labelJSONIndex >= 0) {
                const labelJSON = ModuleTemplateLabels[labelJSONIndex];

                if (Array.isArray(KeyValue)) {
                    KeyValue.forEach(item => {
                        labelJSON[item.Key] = item.Value;
                    });
                } else {
                    labelJSON[KeyValue.Key] = KeyValue.Value;
                }

                ModuleTemplateLabels[labelJSONIndex] = labelJSON;
            }

            return {
                ModuleTemplateLabels
            };
        }


    },
    FormTemplateReducer = (state = formTemplateReducerDefaultState, action) => {
        switch (action.type) {
            case 'INSERT_FORMTEMPLATE': {
                return {
                    ...state,
                    formTemplate: {
                        ...state.formTemplate,
                        ...action.payload
                    }
                };
            }
            case 'MODIFY_FORMTEMPLATE_LABEL': {
                const { NewLabelJSON, ModuleTemplate, ModuleTemplateLabelsKeyValue, TemplateJSON } = action.payload,
                    stateModuleFormTemplate = state.formTemplate[ModuleTemplate.Module];

                if (ModuleTemplateLabelsKeyValue) {
                    const moduleTemplateLabels = fnModifyModuleTemplateByKey({
                        KeyValue: ModuleTemplateLabelsKeyValue,
                        ModuleTemplateLabels: stateModuleFormTemplate.ModuleTemplate.Labels,
                        TemplateJSON
                    });

                    if (moduleTemplateLabels.ModuleTemplateLabels) {
                        stateModuleFormTemplate.ModuleTemplate.Labels = moduleTemplateLabels.ModuleTemplateLabels;
                    }
                }

                if (NewLabelJSON) {
                    stateModuleFormTemplate.Labels = stateModuleFormTemplate.Labels?.map(item => {
                        if (item.LabelID === NewLabelJSON.LabelID)
                            item = NewLabelJSON;

                        return item;
                    });
                }

                return {
                    ...state,
                    formTemplate: {
                        ...state.formTemplate,
                        [ModuleTemplate.Module]: {
                            ...stateModuleFormTemplate
                        }
                    }
                };
            }
            case 'MODIFY_FORMTEMPLATE_MODULETEMPLATE_LABEL_BY_KEY': {
                const { KeyValue, ModuleTemplate, TemplateJSON } = action.payload,
                    stateModuleFormTemplate = state.formTemplate[ModuleTemplate.Module],
                    moduleTemplateLabels = fnModifyModuleTemplateByKey({
                        KeyValue,
                        ModuleTemplateLabels: stateModuleFormTemplate.ModuleTemplate.Labels,
                        TemplateJSON
                    });

                if (moduleTemplateLabels.ModuleTemplateLabels) {
                    stateModuleFormTemplate.ModuleTemplate.Labels = moduleTemplateLabels.ModuleTemplateLabels;
                }

                return {
                    ...state,
                    formTemplate: {
                        ...state.formTemplate,
                        [ModuleTemplate.Module]: {
                            ...stateModuleFormTemplate
                        }
                    }
                }
            }
            case 'REMOVE_FORMTEMPLATE_OBJECT': {
                const { Keys } = action.payload,
                    stateFormTemplate = state.formTemplate;

                Keys.forEach(key => {
                    delete stateFormTemplate[key];
                });

                return {
                    ...state,
                    ...stateFormTemplate
                };
            }
            case 'MODIFY_FORMTEMPLATE_LIST': {
                const { Module, KeyValue, ListKey } = action.payload,
                    stateFormTemplate = state.formTemplate[Module];

                if (stateFormTemplate) {
                    if (!stateFormTemplate.List) {
                        stateFormTemplate.List = {};
                    }

                    if (!stateFormTemplate.List[ListKey]) {
                        stateFormTemplate.List[ListKey] = {};
                    }

                    if (Array.isArray(KeyValue)) {
                        KeyValue.forEach(item => {
                            stateFormTemplate.List[ListKey][item.Key] = item.Value;
                        });
                    } else {
                        stateFormTemplate.List[ListKey][KeyValue.Key] = KeyValue.Value;
                    }

                    return {
                        ...state,
                        formTemplate: {
                            ...state.formTemplate,
                            [Module]: {
                                ...stateFormTemplate
                            }
                        }
                    };
                }

                return state;
            }
            case 'MODIFY_FORMTEMPLATE_ITEM': {
                const { ObjectHolder, Module, KeyValueSpecific } = action.payload,
                    stateFormTemplate = state.formTemplate[Module];
                let shouldProceed = true;

                if (KeyValueSpecific) {
                    shouldProceed = false;
                    Object.keys(KeyValueSpecific).forEach(KeyValueSpecificKey => {
                        const { Props, PrimaryKey } = KeyValueSpecific[KeyValueSpecificKey]
                        let keys = KeyValueSpecificKey.split("."),
                            objHolder = stateFormTemplate;

                        keys.forEach((_key, index) => {
                            if (index + 1 === keys.length) {
                                let objHolderValue = objHolder[_key];

                                if (Array.isArray(objHolderValue)) {
                                    let KeyValueSpecificKeyItem = objHolderValue.find(objHolderValueItem => {
                                        let foundItem;
                                        switch (typeof objHolderValueItem) {
                                            case 'object': {
                                                if (Array.isArray(objHolderValueItem)) {

                                                } else {
                                                    let objHolderValueItemInstance = objHolderValueItem[PrimaryKey?.Key];

                                                    if (objHolderValueItemInstance == PrimaryKey?.Value) {
                                                        let propsFoundCount = 0;

                                                        Props.forEach(PropsItem => {
                                                            if (objHolderValueItem[PropsItem.Key] == PropsItem.Value) {
                                                                ++propsFoundCount;
                                                            }
                                                        });

                                                        if (propsFoundCount == Props.length) {
                                                            foundItem = objHolderValueItem;
                                                        }
                                                    }
                                                }
                                                break;
                                            }
                                        }

                                        return foundItem;
                                    });

                                    if (KeyValueSpecificKeyItem) {
                                        shouldProceed = true;
                                    }
                                }
                            } else {
                                objHolder = objHolder[_key];
                            }
                        });
                    });
                }
                // console.log(action.payload, { ...state });

                if (shouldProceed && ObjectHolder) {
                    Object.keys(ObjectHolder).forEach(ObjectHolderKey => {
                        const { NewProps, PrimaryKey, DeleteProps } = ObjectHolder[ObjectHolderKey];
                        let keys = ObjectHolderKey.split("."),
                            objHolder = stateFormTemplate;

                        keys.forEach((_key, index) => {
                            if (index + 1 === keys.length) {
                                let objHolderValue = objHolder[_key];

                                if (Array.isArray(objHolderValue)) {
                                    objHolder[_key] = objHolderValue.map(item => {
                                        if (item[PrimaryKey?.Key]) {
                                            if (item[PrimaryKey?.Key] == PrimaryKey?.Value) {
                                                switch (typeof item) {
                                                    case 'object': {
                                                        if (Array.isArray(item)) {

                                                        } else {
                                                            if (NewProps) {
                                                                item = {
                                                                    ...item,
                                                                    ...NewProps
                                                                };
                                                            }

                                                            if (DeleteProps) {
                                                                DeleteProps.forEach(deleteProp => {
                                                                    delete item[deleteProp];
                                                                });
                                                            }
                                                        }
                                                        break;
                                                    }
                                                }
                                            }
                                        }

                                        return item;
                                    });
                                }
                            } else {
                                objHolder = objHolder[_key];
                            }
                        });
                    });
                }

                return {
                    ...state,
                    formTemplate: {
                        ...state.formTemplate,
                        [Module]: {
                            ...stateFormTemplate
                        }
                    }
                };
            }
            default:
                return state;
        }
    },
    LabelReducer = (state = labelReducerDefaultState, action) => {
        switch (action.type) {
            case 'INSERT_LABELS': {
                const { Labels } = action.payload;
                let labels = state.labels,
                    newLabels = {};

                Labels.forEach(label => {
                    newLabels[label.LabelID] = {
                        ...label,
                        Languages: label.Languages.map(language => {
                            return {
                                ...language,
                                LabelID: label.LabelID,
                                LabelTypeID: label.LabelTypeID
                            };
                        })
                    };
                });

                return {
                    ...state,
                    labels: {
                        ...labels,
                        ...newLabels
                    }
                };
            }
            case 'LABELS-SET': {
                const { Labels } = action.payload;
                let labels = state.labels,
                    newLabels = Labels

                return {
                    ...state,
                    labels: newLabels
                }
            }
            case 'LABEL_MODIFY': {
                const { LabelID, Data } = action.payload;
                let labels = state.labels
                labels[LabelID] = Data
                return {
                    ...state,
                    labels
                }
            }
            default:
                return state;
        }
    }

export { FormTemplateReducer, LabelReducer };